<template>
  <template v-if="loading">
    <v-progress-linear indeterminate :height="12" v-if="loading"></v-progress-linear>
  </template>

  <template v-else>

    <v-breadcrumbs color="" bg-color="primary" density="compact" v-if="modality">

      <template v-if="event">
        <v-breadcrumbs-item :to="{ name: 'AdminEvent', params: { event_id: event.slug }}"
                            class="d-inline-block text-truncate">
          {{ event.name_short || event.name }}
        </v-breadcrumbs-item>
      </template>

      <v-breadcrumbs-divider></v-breadcrumbs-divider>

      <v-breadcrumbs-item v-if="modality && event && event.modalities.length > 1">
        <v-btn v-if="modality" variant="outlined" class="text-body-2 pa-1" size="">
          {{ modality.short_name || modality.name }}
          <v-icon class="ml-2" size="small" icon="fa-solid fa-chevron-down"></v-icon>
          <v-menu activator="parent">
            <v-list density="compact">
              <template v-for="m in event.modalities">
                <v-list-item
                    @click="this.modality_id = m._id.$oid ; this.$router.push({name: 'AdminReports', params: {modality_id: m._id.$oid}}).then(() => { this.$router.go() })"
                    :disabled="m._id.$oid == modality_id">
                  <v-list-item-title>{{ m.short_name || m.name }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </v-btn>
      </v-breadcrumbs-item>

      <v-breadcrumbs-divider></v-breadcrumbs-divider>

      <v-breadcrumbs-item>Informes</v-breadcrumbs-item>

    </v-breadcrumbs>

    <v-container>

      <v-card class="pa-2 mb-2">

        <v-card-title>
          <v-row>
            <v-col>Filtrar clasificación</v-col>
            <v-col class="text-right" cols="4">
              <v-text-field variant="outlined" density="compact" label="Importar config"
                            v-model="import_config_text" @input="import_report_config"></v-text-field>
            </v-col>
            <v-col class="text-right" cols="auto">
              <v-btn @click="copy_report_config">
                <v-icon icon="fa-solid fa-copy"></v-icon>
                <v-tooltip activator="parent" location="bottom">Copiar configuración</v-tooltip>
              </v-btn>
            </v-col>
            <v-col class="text-right" cols="auto">

              <v-dialog>
                <template v-slot:activator="{ props }">
                  <v-btn color="success" v-bind="props">
                    <v-icon icon="fa-solid fa-save"></v-icon>
                    <v-tooltip activator="parent" location="bottom">Guardar configuración informe</v-tooltip>
                  </v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                  <v-card>

                    <v-toolbar color="primary">
                      <v-toolbar-title>
                        Guardar configuración de informe actual
                      </v-toolbar-title>
                      <v-toolbar-items>
                        <v-btn @click="isActive.value = false" icon>
                          <v-icon icon="fa-solid fa-times"></v-icon>
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>

                    <v-card-text>
                      <v-text-field variant="outlined" label="Nombre del informe"
                                    v-model="new_report_name" hide-details
                                    density="compact"></v-text-field>
                      <v-alert v-if="new_report_name.length <= 3">Escribe un nombre para el reporte (mín. 3
                        caracteres)
                      </v-alert>
                      <v-alert v-if="reports.hasOwnProperty(new_report_name.toLowerCase())">Este nombre de reporte ya existe. Use otro
                        o borre el existente.
                      </v-alert>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn block variant="elevated" color="primary" @click="save_report(); isActive.value = false"
                             :disabled="new_report_name.length <= 3 || reports.hasOwnProperty(new_report_name.toLowerCase())">Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>

              </v-dialog>


            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-row align-content="center">
            <v-col cols="4" sm="2" v-if="modality.event.is_circuit">
              <v-select density="compact" label="Tipo clasificación" hide-details
                        variant="outlined" @update:modelValue="changed_value"
                        :items="options.registration_type" v-model="config.registration_type">
              </v-select>
            </v-col>
            <v-col cols="4" sm="2" v-if="!modality.event.is_circuit">
              <v-select density="compact" multiple label="Estado" hide-details
                        variant="outlined" @update:modelValue="changed_value"
                        :items="options.status" v-model="config.status" clearable>
              </v-select>
            </v-col>
            <v-col cols="4" sm="2">
              <v-select density="compact" label="Agrupar" variant="outlined" @update:modelValue="changed_value"
                        hide-details
                        :items="options.group_by" v-model="config.group_by" clearable>
              </v-select>
            </v-col>
            <v-col cols="4" sm="2">
              <v-select density="compact" label="Mostrar" variant="outlined" @update:modelValue="changed_value"
                        hide-details
                        :items="options.number_shown" v-model="config.number_shown" clearable>
              </v-select>
            </v-col>
            <v-col cols="4" sm="2" v-if="!config.only_locals && !modality.event.is_circuit">
              <v-select density="compact" label="Mostrar locales" variant="outlined" clearable hide-details
                        :items="options.local" v-model="config.local">
              </v-select>
            </v-col>
            <v-col cols="4" sm="2" v-if="!modality.event.is_circuit">
              <v-switch color="primary" density="compact" label="Sólo locales" hide-details
                        @update:modelValue="changed_value" v-model="config.only_locals"></v-switch>
            </v-col>
            <v-col cols="4" sm="2" v-if="!modality.event.is_circuit">
              <v-switch color="primary" density="compact" label="Mostrar dorsal"
                        hide-details v-model="config.show_bib"></v-switch>
            </v-col>
            <v-col cols="4" sm="2" v-if="!modality.event.is_circuit">
              <v-switch color="primary" density="compact" label="Incluir no clasifica" hide-details disabled
                        v-model="config.include_no_classify"></v-switch>
            </v-col>
            <v-col cols="4" sm="2" v-if="config.group_by != 'category'">
              <v-switch color="primary" density="compact" label="Mostrar categoría" hide-details
                        v-model="config.show_category"></v-switch>
            </v-col>
            <v-col cols="4" sm="2" v-if="modality.event.is_circuit">
              <v-switch color="primary" density="compact" label="Mostrar carreras" hide-details
                        v-model="config.show_circuit_races"></v-switch>
            </v-col>
            <v-col cols="4" sm="2">
              <v-switch color="primary" density="compact" label="Mostrar equipos" hide-details
                        v-model="config.show_teams"></v-switch>
            </v-col>
          </v-row>
        </v-card-text>

      </v-card>

      <template v-for="(report, key, idx) in reports">
        <template v-if="report.is_circuit == modality.event.is_circuit">
          <v-btn-group density="compact" class="mr-3">
            <v-btn :color="JSON.stringify(config) == JSON.stringify(report.config) ? 'primary' : ''" variant="elevated"
                   @click="Object.assign(config, report.config); fetch_registrations()">{{ key }}
            </v-btn>
            <v-btn @click="delete_report_confirm = idx" v-if="delete_report_confirm != idx" size="x-small"><v-icon color="error" icon="fa-solid fa-trash"></v-icon><v-tooltip activator="parent" location="bottom">Eliminar informe</v-tooltip></v-btn>
            <v-btn color="error" v-if="delete_report_confirm == idx" @click="delete_report(key); delete_report_confirm = -1">eliminar</v-btn>
          </v-btn-group>
        </template>
      </template>

      <v-row align-content="center" justify="end" class="mb-2 mt-4">
        <v-col cols="4" sm="2" v-if="config.group_by != null" >
          <v-switch color="primary" density="compact" label="Salto página" hide-details
                    v-model="config.page_break"></v-switch>
        </v-col>
        <v-col cols="4" sm="2">
          <v-btn v-print="'#print_table'" variant="elevated" color="primary" block>
            <v-icon start icon="fa-solid fa-print"></v-icon>
            Imprimir
          </v-btn>
        </v-col>
      </v-row>

      <v-progress-linear indeterminate :height="12" v-if="loading_registrations">Cargando clasificación
      </v-progress-linear>

      <div class="bg-white" id="print_table" v-if="!loading_registrations">

        <template v-for="(g, idx) in groups">

          <div class="pagebreak" v-if="idx > 0 && config.page_break"></div>

          <v-table density="compact">
            <thead class="text-center">
            <tr>
              <td colspan="100%" class="text-uppercase">
                <h3>{{ modality.event.name }} - {{ modality.name }}
                  <span v-if="modality.distance">({{ $root.parse_distance(modality.distance, 1, 1) }})</span></h3>

                <h3 v-if="config.group_by == null">CLASIFICACIÓN {{ config.only_locals ? 'LOCAL' : '' }} GENERAL</h3>
                <h3 v-else>CLASIFICACIÓN {{ config.only_locals ? 'LOCAL' : '' }} POR {{ config.group_by }}</h3>

                <h2 v-if="config.group_by != null">{{ g }}</h2>
              </td>
            </tr>
            <tr class="bg-primary text-caption">
              <th class="text-center text-white">Pos</th>
              <th class="text-center text-white" v-if="config.show_bib"></th>
              <th class="text-left text-white">
                Nombre
                <br>
                (y club)
              </th>

              <th class="text-center text-white" width="1%" v-if="config.show_circuit_races" v-for="(r, k, idx) in races_modalities">{{idx + 1}}</th>

              <th class="text-center text-white">
                <span v-if="modality.event.is_circuit">Puntos</span>
                <span v-else>Tiempo</span>
              </th>

              <th class="text-center text-white" v-if="config.show_category && config.group_by != 'category'">
                Categoría
              </th>
              <th class="text-center text-white" v-if="config.show_category && config.group_by != 'category'">Pos.<br>Cat
              </th>
              <th class="text-white" v-if="config.show_teams">Equipo</th>
              <th class="text-center text-white" v-if="config.local != null">Local<span
                  v-if="config.local == 'category'"><br>Cat.</span></th>
              <th class="text-center text-white" v-if="modality.event.is_circuit">Part.</th>
            </tr>
            </thead>
            <tbody class="">
            <template v-for="(r, idx) in config.group_by == null ? registrations : registrations[g]">

              <tr class="text-center">
                <td>
                  <b>
                    <template v-if="config.only_locals">
                      <span v-if="config.group_by == 'category'">{{ r.result.pos_local_category }}</span>
                      <span v-else>{{ r.result.pos_local }}</span>
                    </template>
                    <template v-else>
                      <span v-if="config.group_by == 'category'">{{ r.result.pos_category }}</span>
                      <span v-else>{{ r.result.pos_gender }}</span>
                    </template>
                  </b>
                </td>
                <td v-if="config.show_bib" class="text-caption" width="1%">{{ r.bib_number }}</td>
                <td class="text-uppercase text-left pt-1">
                  <span>
                    {{ r.name }} <b>{{ r.surname }}</b>
                    <span v-if="!modality.event.is_circuit">
                      <br>
                      <span class="text-caption text-no-wrap overflow-x-hidden" v-if="r.club">{{ r.club }}</span>
                      <span class="text-caption" v-else>---</span>
                    </span>
                    <span v-if="!modality.event.is_circuit && r.result.penalizations.length > 0" class="text-red font-weight-bold text-caption pl-4">
                      <span v-for="pen in r.result.penalizations">
                        <br>
                        <v-icon class="ml-2" size="x-small" icon="fa-solid fa-triangle-exclamation"></v-icon> {{pen.reason}}: +{{pen.time}}s
                      </span>
                    </span>
                  </span>
                </td>
                <td class="text-center text-caption" v-if="config.show_circuit_races" v-for="(race, k, idx) in races_modalities" width="1%">
                  <template v-if="r.race_results && r.race_results[k]">
                    <span v-if="config.group_by == 'category'">{{ r.race_results[k].score_cat }}</span>
                    <span v-else>{{ r.race_results[k].score }}</span>
                  </template>
                </td>
                <td>
                  <span v-if="!modality.event.is_circuit">
                    <b>{{ $root.parse_time_from_seconds(r.result.total_time) }}</b>
                    <span v-if="modality.distance" class="text-caption">
                      <br>
                      {{ $root.get_speed_and_units(modality.sport, modality.distance, r.result.total_time) }}
                    </span>
                  </span>
                  <span v-else>
                    <span v-if="config.group_by == 'category'">{{ r.result.best_score_cat }}</span>
                    <span v-else>{{ r.result.best_score }}</span>
                  </span>
                </td>
                <td v-if="config.show_category && config.group_by != 'category'"><span
                    class="text-caption">{{ r.category }}</span></td>
                <td v-if="config.show_category && config.group_by != 'category'">
                  <span v-if="config.only_locals">{{ r.result.pos_local_category }}</span>
                  <span v-else>{{ r.result.pos_category }}</span>
                </td>
                <td class="text-left text-caption" v-if="config.show_teams">{{ r.team }}</td>
                <td class="text-center" v-if="config.local != null">
                  <span v-if="config.local == 'gender'">{{ r.result.pos_local_gender }}</span>
                  <span v-if="config.local == 'category'">{{ r.result.pos_local_category }}</span>
                </td>
                <td v-if="modality.event.is_circuit">{{ r.total_races }}</td>
              </tr>
            </template>
            </tbody>
            <tfoot>
            <tr>
              <td colspan="100%" class="text-center">
                mychip.es - {{ moment().format('DD/MM HH:mm') }}
              </td>
            </tr>
            </tfoot>
          </v-table>
        </template>

      </div>


    </v-container>
  </template>
</template>

<style>
@media print {
  .pagebreak {
    page-break-before: always;
  }
}
</style>

<script>
import {defineComponent} from 'vue';
import {ref, nextTick} from 'vue'

export default defineComponent({
  name: 'AdminReports',
  data: () => ({
    loading: true,
    loading_registrations: false,
    now: null,
    modality_id: null,
    modality: null,
    event: null,
    races: {},
    races_modalities: {},
    registrations: null,
    registrations_stats: null,
    groups: ["all"],
    config: {
      group_by: null,
      status: ["finished"],
      number_shown: null,
      local: null,
      page_break: true,
      show_bib: false,
      include_no_classify: true,
      registration_type: "individual",
      show_intermediates: false,
      only_locals: false,
      show_category: true,
      show_circuit_races: true,
      show_teams: false,
    },
    options: {
      group_by: ["gender", "category"],
      status: ["finished", "retired", "disqualified"],
      number_shown: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      local: ['gender', 'category'],
      registration_type: ['individual', 'teams'],
    },
    import_config_text: "",
    new_report_name: "",
    delete_report_confirm: -1,
  }),
  created() {
    this.modality_id = this.$route.params.modality_id
    this.fetch_modality()
  },
  methods: {
    fetch_modality() {
      this.loading = true
      let url = `${process.env.VUE_APP_ROOT_API}/modalities`
      this.axios.post(url, {id: this.modality_id}).then((response) => {
        this.modality = response.data[0]
        this.loading = false
        this.fetch_registrations()
        this.fetch_event()
      }).catch((error) => {
        console.error(error)
      })
    },
    fetch_event() {
      let url = `${process.env.VUE_APP_ROOT_API}/events`
      if (this.modality.event.is_circuit) {
        url = `${process.env.VUE_APP_ROOT_API}/circuits`
        this.get_circuit_events()
      }
      this.axios.post(url, {id: this.modality.event._id.$oid}).then((response) => {
        this.event = response.data[0]
      }).catch((error) => {
        console.error(error)
      })
    },
    get_circuit_events() {
      this.loading_events = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/events`,
          {
            circuit: this.modality.event._id.$oid,
            limit: 999
          }
      ).then((response) => {
        this.events = response.data
        this.loading_events = false

        this.races = {}
        this.races_modalities = {}
        this.events.forEach(e => {
          e.modalities.forEach(m => {
            if ( m.circuit_modality ) {
              if ( m.circuit_modality.$oid == this.modality_id ) {
                this.races[m._id.$oid] = e
                this.races_modalities[m._id.$oid] = m
              }
            }
          })
        })

      }).catch((error) => {
        console.error(error)
      })
    },
    fetch_registrations() {
      this.loading_registrations = true
      let url = `${process.env.VUE_APP_ROOT_API}/registrations`
      this.axios.post(url, this.parse_filters()).then((response) => {
        if (this.config.group_by == null) {
          this.registrations = response.data
          this.groups = ["all"]
        } else {
          this.registrations = response.data
          this.groups = Object.keys(this.registrations)
        }
        // this.fetch_registrations_stats()
        this.loading_registrations = false
      }).catch((error) => {
        console.error(error)
      })
    },
    fetch_registrations_stats() {
      let url = `${process.env.VUE_APP_ROOT_API}/registrations/stats`
      this.axios.post(url, this.parse_filters()).then((response) => {
        this.registrations_stats = response.data
        this.loading_registrations = false
      }).catch((error) => {
        console.error(error)
      })
    },
    parse_filters() {

      // console.log("parsing filters")
      // console.log(this.config)
      // console.log(this.config.number_shown)
      // console.log(JSON.stringify(this.config))

      let filters = {
        "modality": this.modality_id,
        "include_modality": false,
      }

      if (this.config.registration_type == 'teams') {
        filters["team_registrations"] = true
      }

      if (this.config.number_shown != null) {
        filters["limit"] = this.config.number_shown
      } else {
        filters["limit"] = 9999
      }

      filters["only_locals"] = this.config.only_locals

      if (this.modality.event.is_circuit) {
        filters["circuit_registrations"] = true
      } else {
        if (this.config.status != null && this.config.status.length > 0) {
          filters["status"] = this.config.status
        }
      }


      if (this.config.group_by != null) {
        filters["group_by"] = this.config.group_by
      }

      filters["order"] = []

      if (this.modality.event.is_circuit) {
        if ( this.config.group_by == 'gender' ) {
          filters["order"].push("result__pos_gender")
        } else if ( this.config.group_by == 'category' ) {
          filters["order"].push("result__pos_category")
        } else {
          filters["order"].push("result__shown_order")
        }
      } else {
        filters["order"].push("result__total_time", "result__shown_order")
      }

      return filters
    },
    changed_value(e) {
      nextTick(() => {
        this.fetch_registrations()
      })
    },
    copy_report_config() {
      navigator.clipboard.writeText(btoa(JSON.stringify(this.config)))
    },
    import_report_config() {
      let x = JSON.parse(atob(this.import_config_text))
      Object.assign(this.config, x)
      this.import_config_text = ""
      this.fetch_registrations()
    },
    save_report() {
      let x = {}
      x[this.new_report_name.toLowerCase()] = {
        is_circuit: this.modality.event.is_circuit,
        config: this.config,
      }
      Object.assign(this.reports, x)
      this.$store.commit('changeReports', {new_reports: this.reports})
      this.new_report_name = ""
    },
    delete_report(key) {
      delete this.reports[key]
      this.$store.commit('changeReports', {new_reports: this.reports})
    }
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    },
    models: {
      get: function () {
        return this.$root.$data.models
      }
    },
    constants: {
      get: function () {
        return this.$root.$data.constants
      }
    },
    fields_from_rts: {
      get: function () {
        return this.$root.$data.fields_from_rts
      }
    },
    invariants: {
      get: function () {
        return this.$root.$data.invariants
      }
    },
    reports() {
      return this.$store.getters.reports
    },
  }
});
</script>
