import posthog from "posthog-js";

export default {
    install(app) {
        app.config.globalProperties.$posthog = posthog.init(
            "phc_kmm4mPb1e4hyOpiEHb5F7d6z3txk2QZh5WgFgUisfXf",
            {
                api_host: "https://eu.posthog.com",
            }
        );
    },
};