<template>

  <v-container style="max-width: 960px;">

    <v-card>

      <v-toolbar color="primary">
        <v-toolbar-title>
          <span v-if="create">Crear lector</span>
          <span v-else>Editar lector</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn size="small" variant="text" stacked @click="save_reader">
            <v-icon size="small" icon="fa-solid fa-save"></v-icon>
            <span class="hidden-xs mt-1">Guardar</span>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-row>

          <v-col cols="12" sm="5">
            <v-text-field hide-details density="compact" label="Nombre" variant="outlined" v-model="reader.name">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="5">
            <v-text-field hide-details type="number" density="compact" label="Id" variant="outlined" v-model="reader.numeric_id">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="2">
            {{ void(live = reader.heartbeat && $root.is_reader_alive(reader.heartbeat.$date, 2)) }}
            {{ void(less = reader.heartbeat && $root.is_reader_alive(reader.heartbeat.$date, 7)) }}
            <v-icon icon="fa-solid fa-circle fa-beat" color="success" v-if="live"></v-icon>
            <v-icon icon="fa-solid fa-circle" color="warning" v-else-if="less"></v-icon>
            <v-icon icon="fa-solid fa-circle" color="error" v-else></v-icon>
            <v-chip label class="ml-1" v-if="live">En directo</v-chip>
            <v-chip label class="ml-1" v-else-if="less">Hace rato</v-chip>
            <v-chip label class="ml-1" v-else>Apagado</v-chip>
          </v-col>

        </v-row>
      </v-card-text>

    </v-card>

    <template v-if="!create && reader_configurations != null && reader_configurations.length > 0">
      <h4 class="mt-4">Últimas configuraciones del lector</h4>
      <v-table density="compact">
        <tbody>
        <tr v-for="rc in reader_configurations">
          {{ void(is_active = rc.datetime_start && rc.datetime_end && $root.is_reader_active(rc.datetime_start.$date, rc.datetime_end.$date)) }}
          <td>
            <span v-if="rc.modality" class="text-caption">
              <router-link :to="{name: 'AdminEvent', params: { event_id: rc.modality.event.slug }}" class="text-uppercase">
                {{ rc.modality.event.name_short || rc.modality.event.name }}
              </router-link>
            </span>
          </td>
          <td>
            <span v-if="rc.modality" class="text-caption">
              <router-link :to="{name: 'AdminEvent', params: { event_id: rc.modality.event.slug, modality_selected: rc.modality._id.$oid }}" class="text-uppercase">
                {{ rc.modality.short_name || rc.modality.name }}
              </router-link>
            </span>
          </td>
          <td>
            <span v-if="rc.intermediate.location && rc.intermediate.location.custom_name">
              {{rc.intermediate.location.custom_name}}
            </span>
            <span v-else class="font-italic">
              Intermedio eliminado
            </span>
          </td>
          <td>
            <span v-if="rc.datetime_start && rc.datetime_end">
              {{ moment.utc(rc.datetime_start.$date).format('DD/MM  HH:mm') }} - {{ moment.utc(rc.datetime_end.$date).format('DD/MM  HH:mm') }}
              <v-icon icon="fa-solid fa-lock-open" size="x-small" class="ml-1" color="success" v-if="is_active"></v-icon>
              <v-icon icon="fa-solid fa-lock" size="x-small" class="ml-1" color="error" v-else></v-icon>
            </span>
          </td>
          <td>
            <v-btn v-if="rc.intermediate._id" size="small" variant="flat" :to="{name: 'AdminReaderConfiguration', params: { intermediate_id: rc.intermediate._id.$oid }}">
              <v-icon icon="fa-solid fa-cog"></v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-table>
    </template>
    <template v-else>
      <span class="font-italic text-caption">No se han encontrado configuraciones para este lector</span>
    </template>

    {{modalities}}

  </v-container>

</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'AdminReader',
  components: {
  },
  data: () => ({
    now: null,
    reader_id: null,
    reader: {
    },
    create: true,
    reader_configurations: null,
    modalities: null,
  }),
  created() {
    this.reader_id = this.$route.params.reader_id
    this.now = this.moment()
    document.title = "Crear lector"
    if ( this.reader_id != null && this.reader_id  != '0' ) {
      document.title = "Editar lector"
      this.create = false
      this.get_reader()
    }
  },
  methods: {
    get_reader() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/readers?t=${this.moment.now()}`,
          {
            "id": this.reader_id,
          }
      ).then((response) => {
        this.reader = response.data[0]
        this.get_reader_configurations()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_reader_configurations() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/reader-configurations?t=${this.moment.now()}`,
          {
            "reader": [this.reader_id],
            "include_intermediates": true,
          }
      ).then((response) => {
        this.reader_configurations = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    save_reader() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/reader`,
          this.reader
      ).then((response) => {
        if (response.data) {
          this.$router.go()
        }
      }).catch((error) => {
        console.error(error)
      })
    },
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    },

    constants: {
      get: function () {
        return this.$root.$data.constants
      }
    },
  },
  watch: {
    page: {
      handler() {
        this.get_readers()
      }
    },
  }
});
</script>
