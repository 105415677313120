<template>

  <v-container style="max-width: 960px;">

    <v-row>
      <v-col><h1>Organismos</h1></v-col>
      <v-col class="text-right"><v-btn :to="{name: 'AdminOrganism', params: { organism_id: 0 }}" class="mb-2" color="primary">Nuevo</v-btn></v-col>
    </v-row>

    <v-text-field v-model="filter_name" density="compact" clearable append-inner-icon="fa-solid fa-magnifying-glass"
                  @click:append-inner="get_organisms" @click:clear="get_organisms" @keydown.enter="get_organisms"
    ></v-text-field>

    <v-progress-linear v-if="loading_organisms" class="mt-4" indeterminate :height="12">Organismos</v-progress-linear>

    <v-row class="mb-0" no-gutters>
      <v-col class="mb-0 pb-0">
        <v-switch class="ma-0 pa-0" density="compact" v-model="filter_federations" label="Solo federaciones"
                  color="primary"></v-switch>
      </v-col>
    </v-row>

    <template v-if="!loading_organisms">
      <template v-if="organisms">
        <v-table density="compact">
          <thead>
          <tr>
            <th>Nombre</th>
            <th>Tipo</th>
            <th>Imagen</th>
            <th>Web</th>
            <th>Contacto</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="o in organisms">
            <td>
              <router-link :to="{name: 'AdminOrganism', params: { organism_id: o._id.$oid }}" class="text-uppercase">{{ o.name }}</router-link>
            </td>
            <td>
              <v-chip label size="x-small">{{ o.type }}</v-chip>
            </td>
            <td>
              <v-img :src="o.logo" v-if="o.logo" height="25px"></v-img>
            </td>
            <td>
              <span v-if="o.website">
                <v-btn size="small" :href="o.website" target="_blank">Web</v-btn>
              </span>
            </td>
            <td>
              <span v-if="o.contact">
                <v-chip size="x-small" label v-if="o.contact.email">{{ o.contact.email }}</v-chip>
                <v-chip size="x-small" label v-if="o.contact.phone">{{ o.contact.phone }}</v-chip>
              </span>
            </td>
          </tr>
          </tbody>
        </v-table>
        <v-pagination
            v-if="stats"
            v-model="page"
            :length="1 + parseInt(stats.total/page_size)"
            total-visible="4"
        ></v-pagination>
      </template>
      <v-alert v-else type="info">No organisms were found</v-alert>
    </template>

  </v-container>

</template>

<script>
import {defineComponent} from 'vue';
import Calendar from '../components/Calendar.vue'
import EventsTable from "@/components/EventsTable.vue";

export default defineComponent({
  name: 'AdminOrganismsList',
  components: {
  },
  data: () => ({
    now: null,
    loading_organisms: true,
    organisms: null,
    stats: null,
    page: 1,
    page_size: 8,
    filter_name: null,
    filter_federations: false,
  }),
  created() {
    this.now = this.moment()
    document.title = "Organisms"
    this.get_organisms()
  },
  methods: {
    parse_filters() {
      let filters = {
        "offset": this.page_size * (this.page - 1),
        "limit": this.page_size,
      }
      if (this.filter_federations) {
        filters["type"] = ['federation']
      }
      if (this.filter_name !== null && this.filter_name.length > 0) {
        filters["name"] = this.filter_name
      }
      return filters
    },
    get_organisms() {
      this.loading_organisms = true
      console.log("hey", this.parse_filters(), this.filter_federations)
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/organisms`,
          this.parse_filters()
      ).then((response) => {
        this.organisms = response.data
        this.loading_organisms = false
        this.get_organisms_stats()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_organisms_stats() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/organisms/stats`,
          this.parse_filters()
      ).then((response) => {
        this.stats = response.data
      }).catch((error) => {
        console.error(error)
      })
    }
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    }
  },
  watch: {
    page: {
      handler() {
        this.get_organisms()
      }
    },
    filter_federations: {
      handler() {
        this.get_organisms()
      }
    }
  }
});
</script>
