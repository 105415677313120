<template>

  <v-app-bar color="" app density="compact" rounded>

    <v-app-bar-nav-icon :to="{ name: 'Home'}" flat>
      <v-img class="mx-2" src="@/assets/mychip-logo.svg" height="30px" width="30px">
      </v-img>
    </v-app-bar-nav-icon>

    <v-toolbar-title class="hidden-xs">Mychip</v-toolbar-title>


    <v-btn color="primary" icon size="small" :to="{name: 'EventCalendar', params: {type: 'next'}}">
      <v-tooltip text="Eventos" activator="parent" location="bottom"></v-tooltip>
      <v-icon size="small" icon="fa-solid fa-calendar"/>
    </v-btn>

    <v-btn color="primary" icon size="small" :to="{name: 'EventCalendar', params: {type: 'past'}}">
      <v-tooltip text="Resultados" activator="parent" location="bottom"></v-tooltip>
      <v-icon size="small" icon="fa-solid fa-stopwatch"/>
    </v-btn>

    <v-btn color="primary" icon size="small" :to="{name: 'Circuits'}">
      <v-tooltip text="Circuitos" activator="parent" location="bottom"></v-tooltip>
      <v-icon size="small" icon="fa-solid fa-trophy"/>
    </v-btn>

    <!--    <v-btn color="primary" size="small" icon @click="language_dialog=true">-->
    <!--      <v-tooltip text="Idioma" activator="parent" location="bottom"></v-tooltip>-->
    <!--      <v-icon size="small" icon="fa-solid fa-language"/>-->
    <!--    </v-btn>-->

    <v-menu v-if="this.$root.is_authenticated">
      <template v-slot:activator="{ props }">
        <v-btn class="hidden-sm-and-up" icon size="small" v-bind="props">
          <v-icon size="small" icon="fa-solid fa-user"></v-icon>
        </v-btn>
        <v-btn variant="text" size="small" v-bind="props" class="hidden-xs">
          <v-icon start size="small" icon="fa-solid fa-user"></v-icon>
          {{ this.name }}
        </v-btn>
      </template>

      <v-list density="compact" v-if="this.$root.is_authenticated">
        <template v-if="this.is_admin">
          <v-list-subheader>Admin</v-list-subheader>
          <v-list-item v-if="this.is_admin" :to="{ name: 'Admin'}">
            <v-icon size="x-small" start icon="fa-solid fa-home"></v-icon>
            Panel
          </v-list-item>
          <v-list-item class="text-caption" v-if="this.is_admin" :to="{ name: 'AdminEventsList'}">
            <v-icon size="x-small" start icon="fa-solid fa-calendar"></v-icon>
            Eventos
          </v-list-item>
          <v-list-item class="text-caption" v-if="this.is_admin" :to="{ name: 'AdminCircuitList'}">
            <v-icon size="x-small" start icon="fa-solid fa-trophy"></v-icon>
            Circuitos
          </v-list-item>
          <v-list-item class="text-caption" v-if="this.is_admin" :to="{ name: 'AdminUsersList'}">
            <v-icon size="x-small" start icon="fa-solid fa-users"></v-icon>
            Usuarios
          </v-list-item>
          <v-list-item class="text-caption" v-if="this.is_admin" :to="{ name: 'AdminReadersList'}">
            <v-icon size="x-small" start icon="fa-solid fa-wifi"></v-icon>
            Lectores
          </v-list-item>
          <v-list-item class="text-caption" v-if="this.is_admin" :to="{ name: 'AdminOrganismsList'}">
            <v-icon size="x-small" start icon="fa-solid fa-building"></v-icon>
            Organizaciones
          </v-list-item>
          <v-list-item class="text-caption" v-if="this.is_admin" :to="{ name: 'AdminAdsList'}">
            <v-icon size="x-small" start icon="fa-solid fa-ad"></v-icon>
            Anuncios
          </v-list-item>
        </template>
        <!--        <v-list-item v-if="this.$root.roles.includes('user')">-->
        <!--          <v-list-item-title>Perfil</v-list-item-title>-->
        <!--        </v-list-item>-->
      </v-list>
    </v-menu>

    <!--      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>-->

  </v-app-bar>


  <v-main>
    <slot/>
  </v-main>

  <v-sheet color="primary">
    <v-container>
      <h4 class="mb-2 text-center text-uppercase font-weight-light">Patrocinadores & Partners</h4>
      <v-row justify="center">
        <v-col v-for="ad in ads" cols="6" sm="4" md="3" lg="2" xl="1">
          <a :href="ad.redirect" target="_blank">
            <v-img
                :src="ad.image"
                lazy-src="https://mychip-dev-images.s3.eu-central-1.amazonaws.com/logos/mychip-square.svg"
                aspect-ratio="1"
                class="bg-surface"
            >
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      color="grey-lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </a>
          <span class="text-center">{{ ad.name }}</span>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>

  <v-sheet>
    <v-container>
      <AdSense :ad_slot="1348879686"></AdSense>
    </v-container>
  </v-sheet>


  <v-sheet color="tertiary" class="mb-16 mt-4 pa-2">
    <v-container>
      <v-row justify="center">
        <v-col class="text-center" cols="6" sm="4" md="3" lg="2">
          <h4>Eventos</h4>
          <v-btn :to="{name: 'EventCalendar', params: {type: 'next'}}" color="white" variant="tonal" class="mb-1"
                 density="compact"
                 rounded="xl">
            Calendario
          </v-btn>
          <v-btn :to="{ name: 'AdminCircuitList'}" color="white" variant="tonal" class="mb-1" density="compact"
                 rounded="xl">
            Circuito
          </v-btn>
        </v-col>
        <v-col class="text-center" cols="6" sm="4" md="3" lg="2">
          <h4>Servicios</h4>
          <v-btn :to="{name: 'Company'}" color="white" variant="tonal" class="mb-1" density="compact" rounded="xl">
            Quiénes somos
          </v-btn>
          <!--          <v-btn disabled color="white" variant="tonal" class="mb-1" density="compact" rounded="xl">-->
          <!--            Cronometraje-->
          <!--          </v-btn>-->
          <!--          <v-btn disabled color="white" variant="tonal" class="mb-1" density="compact" rounded="xl">-->
          <!--            Inscripciones-->
          <!--          </v-btn>-->
          <v-btn color="white" variant="tonal" class="mb-1" density="compact" rounded="xl"
                 href="https://dorsalshop.com/" target="_blank">
            Dorsales
          </v-btn>
        </v-col>
        <v-col class="text-center" cols="6" sm="4" md="3" lg="2">
          <h4>Información</h4>

          <v-dialog width="500">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" color="white" variant="tonal" class="mb-1" density="compact" rounded="xl">
                Contacto
              </v-btn>
            </template>

            <template v-slot:default="{ isActive }">
              <v-card>
                <v-card-text class="text-center">
                  Contacta con nosotros a través del correo electrónico
                  <v-btn block size="small" color="primary" href="mailto:info@mychip.es">
                    <v-icon size="small" start icon="fa-solid fa-email"></v-icon>
                    info@mychip.es
                  </v-btn>
                </v-card-text>
              </v-card>
            </template>
          </v-dialog>


          <v-dialog
              v-model="legal_dialog"
              fullscreen
          >
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" color="white" variant="tonal" class="mb-1" density="compact" rounded="xl">
                Aviso legal
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Aviso legal</span>
              </v-card-title>
              <v-card-text class="ma-8 pa-8">

                <section>

                  <p>El sitio web Mychip es propiedad de <strong>MYCHIP, Tu Evento Deportivo SLU</strong> con <strong>CIF
                    B98908445</strong><br>
                    Inscrita en el registro mecantil de Valencia, tomo 9852 folio 86, inscripción primera con hoja
                    V-161529.<br>
                    El domicilio social se encuentra en la <strong>C/ Cervantes 18 de Villanueva de Castellón 46270,
                      Valencia (España)</strong>.<br>
                    Mail de contacto <a href="mailto:info@mychip.es">info@mychip.es</a></p>


                  <p>Información LSSI Cláusula de información general</p>
                  <p><strong>Mychip, tu evento deportivo SLU</strong>&nbsp;, en cumplimiento de la Ley 34/2002, de 11 de
                    julio, de servicios de la sociedad de la información y de comercio electrónico, le informa de que:&nbsp;<br>
                    Su denominación social es&nbsp; <strong>Mychip, tu evento deportivo SLU</strong>&nbsp;&nbsp;<br> Su
                    domicilio social está en C/ Cervantes 18&nbsp;, 46270 Villanueva de Castellón<br> CIF: B98908445</p>
                  <p>Web:&nbsp;www.mychip.es&nbsp;.&nbsp;<br> Registro mercantil de Valencia.&nbsp;<br> Tomo: 10273
                    Folio: 125 Hoja: V-175911.</p>
                  <ol>
                    <li>Por el mero uso de este sitio web, el usuario manifiesta su aceptación sin reservas de las
                      presentes condiciones de uso.
                    </li>
                    <li>Algunos de los servicios o información podrán requerir la autenticación del usuario mediante
                      "correo electrónico" (email) y "contraseña" (password), que previamente le habrá
                      facilitado&nbsp;<strong>Mychip, tu evento deportivo SLU</strong>&nbsp;<strong>.</strong>&nbsp;El
                      usuario garantiza la confidencialidad de estos datos, comprometiéndose a comunicar a&nbsp;<strong>Mychip,
                        tu evento deportivo SLU</strong>&nbsp; cualquier situación que haya llevado a la pérdida de
                      dicha confidencialidad.
                    </li>
                    <li>El usuario se obliga a no utilizar el sitio web ni la información ofrecida en el mismo para la
                      realización de actividades contrarias a las leyes, a la moral, al orden público y, en general, a
                      hacer un uso conforme a las presentes condiciones de uso. Ambos, acceso e información, es
                      responsabilidad exclusiva de quien lo realiza, sin que pueda responsabilizarse a&nbsp;<strong>Mychip,
                        tu evento deportivo SLU</strong>&nbsp; de los daños o perjuicios que pudieran derivarse de dicho
                      acceso o uso de la información ajenas a su voluntad.
                    </li>
                    <li><strong>Mychip, tu evento deportivo SLU</strong>&nbsp; no será responsable de los errores en el
                      acceso al sitio web o en sus contenidos, poniendo la mayor diligencia en que los mismos no se
                      produzcan.
                    </li>
                    <li>En cumplimiento de la legislación vigente en materia de Protección de Datos, se informa:</li>
                    <ol>
                      <li>Que sus datos personales se incorporarán al fichero denominado</li>
                    </ol>
                  </ol>
                  <ol>
                    <ol>
                      <ul>
                        <li>USUARIOS WEB</li>
                      </ul>
                      <li>Que si se inscribe en pruebas realizadas en países fuera de la Unión Europea sus datos serán
                        cedidos para las finalidades de gestión de su participación a países con un nivel de seguridad
                        no reconocido por la UE, lo cual acepta y consiente expresamente.
                      </li>
                      <li>Que puede ejercitar sus derechos de acceso, cancelación y oposición ante el responsable del
                        fichero.
                      </li>
                      <li>Que el responsable del fichero tratamiento es&nbsp;<strong>Mychip, tu evento deportivo
                        SLU</strong>&nbsp; &nbsp;ubicado en&nbsp; C/. Cervantes 18. 46270 Villanueva de Castellón
                      </li>
                    </ol>
                    <li>Las marcas, rótulos, signos distintivos o logos de&nbsp;<strong>Mychip, tu evento deportivo
                      SLU</strong>&nbsp;&nbsp;que aparecen en la página web son titularidad de&nbsp;<strong>Mychip, tu
                      evento deportivo SLU</strong>&nbsp;&nbsp;y están debidamente registrados. Igual referencia se hace
                      al dominio www.mychip.es. Todos los textos, dibujos gráficos, vídeos o soportes de audio,
                      propiedad de&nbsp;<strong>Mychip, tu evento deportivo SLU</strong>&nbsp; no podrán ser objeto de
                      ulterior modificación, copia, alteración, reproducción, adaptación o traducción por parte del
                      usuario o de terceros sin la expresa autorización por parte de&nbsp;<strong>Mychip, tu evento
                        deportivo SLU</strong>&nbsp;, salvo que se indique lo contrario. El uso no autorizado de la
                      información contenida en este sitio web, así como la cesión de los derechos de propiedad
                      intelectual o industrial dará lugar a las responsabilidades legalmente establecidas.
                    </li>
                  </ol>
                  <ol>
                    <ul>
                      <li>El establecimiento de cualquier hiperenlace desde una página web ajena, a cualesquiera de las
                        páginas del sitio web de&nbsp;<strong>Mychip, tu evento deportivo SLU</strong>&nbsp;&nbsp;estará
                        sometido a las siguientes condiciones:
                      </li>
                      <li>No se permite la reproducción ni total ni parcial de ninguno de los servicios contenidos en el
                        sitio web de&nbsp;<strong>Mychip, tu evento deportivo SLU</strong>&nbsp;
                      </li>
                      <li>No se establecerán Deep-links con el sitio web de&nbsp;<strong>Mychip, tu evento deportivo
                        SLU</strong>&nbsp; ni de sus servicios, ni se creará un browser o un border enviroment sobre las
                        mismas.
                      </li>
                      <li>No se incluirá ninguna manifestación falsa, inexacta o incorrecta sobre las páginas del sitio
                        web de&nbsp;<strong>Mychip, tu evento deportivo SLU</strong>&nbsp;<strong>.</strong>&nbsp;y sus
                        servicios y, en particular, salvo aquellos signos que formen parte del mismo hiperenlace a la
                        página web <strong>Mychip, tu evento deportivo SLU</strong>&nbsp;, no contendrá ninguna marca,
                        nombre comercial, rótulo de establecimiento, denominación, logotipo, eslogan u otros signos
                        distintivos pertenecientes <strong>Mychip, tu evento deportivo SLU</strong>&nbsp;
                      </li>
                      <li>Bajo ninguna circunstancia,&nbsp;<strong>Mychip, tu evento deportivo SLU</strong>&nbsp; será
                        responsable de los contenidos o servicios puestos a disposición del público en la página web
                        desde la que se realice el hiperenlace ni de las informaciones o manifestaciones incluidas en
                        las mismas.
                      </li>
                    </ul>
                  </ol>
                  <p>Cualquier hiperenlace a la web de&nbsp;<strong>Mychip, tu evento deportivo SLU</strong>&nbsp; se
                    efectuará a su página principal.</p>
                  <ol>
                    <li><strong>Mychip, tu evento deportivo SLU</strong>&nbsp;&nbsp;informa al usuario del sitio web que
                      puede ser necesaria la cumplimentación de formularios o impresos on-line con el fin de una
                      adecuada prestación del servicio.
                    </li>
                    <li>Las condiciones de uso de este sitio web tienen carácter indefinido.&nbsp;<strong>Mychip, tu
                      evento deportivo SLU</strong>&nbsp; podrá, en cualquier momento, modificarlas o introducir nuevos
                      términos, comprometiéndose a publicarlas en este sitio web. La prestación del servicio de este
                      sitio web y las presentes condiciones de uso se rigen por la Ley española. Las partes, con
                      renuncia expresa a cualquier otro fuero que pudiera corresponderles, acuerdan someterse a la
                      jurisdicción de los Juzgados y Tribunales de Xàtiva
                    </li>
                    <li>9. En cumplimiento de la legislación vigente en materia de Protección de Datos de Carácter
                      Personal: Derecho de información en la recogida de datos, le comunicamos que nos comprometemos a
                      garantizar la privacidad de sus datos personales recogidos en esta página. Le informamos que los
                      datos personales recogidos, serán incorporados a un fichero bajo nuestra responsabilidad, para el
                      tratamiento de datos de carácter personal, con la finalidad de informarle de los servicios que
                      ofrecemos. Como interesado directo, tiene derecho de acceso, rectificación, cancelación y
                      oposición al tratamiento de la información que le concierne y autoriza a que pase a formar parte
                      de nuestros ficheros, Puede ejercitar sus derechos sobre el tratamiento de datos personales ante
                      <strong>Mychip, tu evento deportivo SLU</strong>&nbsp; ubicado en C/ Cervantes 18, 46270
                      Villanueva de Castellón. Los fines del tratamiento son, gestión contable, fiscal y administrativa,
                      así como el envío de publicidad de nuestros servicios en el caso de que nos haya dado su
                      consentimiento. De conformidad con la legislación vigente de Protección de Datos, se informa:
                    </li>
                    <ul>
                      <li>Que sus datos personales se incorporarán al Tratamiento denominado Clientes</li>
                    </ul>
                  </ol>
                  <p>&nbsp;</p>

                </section>

              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    variant="elevated"
                    @click="legal_dialog = false">
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


          <v-btn :to="{name: 'ReturnPolicy'}" color="white" variant="tonal" class="mb-1" density="compact" rounded="xl">
            Política de devoluciones
          </v-btn>

          <v-btn :to="{name: 'Cookies'}" color="white" variant="tonal" class="mb-1" density="compact" rounded="xl">
            Aviso cookies
          </v-btn>


        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col><h6 class="text-center">© Mychip 2012-2023</h6></v-col>
      </v-row>
    </v-container>
  </v-sheet>

  <v-footer app fixed class=" font-weight-medium">
    <h4 v-if="app_name">{{ app_name }}
      <v-tooltip :text="app_version" activator="parent" location="bottom"></v-tooltip>
    </h4>
    <v-spacer></v-spacer>
    <v-btn color="" variant="outlined" icon size="x-small" href="https://www.instagram.com/mychip_info" target="_blank">
      <v-icon size="x-large" icon="fa-brands fa-instagram"/>
    </v-btn>
    <v-btn color="" variant="outlined" icon size="x-small" class="ml-2" href="https://www.facebook.com/infomychip"
           target="_blank">
      <v-icon size="x-large" icon="fa-brands fa-facebook"/>
    </v-btn>


  </v-footer>

  <v-dialog v-model="language_dialog" width="auto">
    <v-card class="" min-width="300px">
      <v-toolbar
          color="primary"
          title="Configruacion"
      ></v-toolbar>

      <v-card-text class="">

        <v-list>

          <v-list-item-title>Idioma</v-list-item-title>

          <v-list-item
              v-for="(lang, i) in supported_languages"
              @click="changeLang(lang)"
              :key="lang"
              active-color="primary"
              rounded="xl"
          >
            <template v-slot:prepend>
              <v-icon icon="fa-solid fa-circle-check" v-if="this.$root.$i18n.locale==lang"></v-icon>
              <v-icon icon="fa-regular fa-circle" v-else></v-icon>
            </template>

            <v-list-item-title v-t="{ path: 'language_name', locale: lang }"></v-list-item-title>
          </v-list-item>
        </v-list>

        <v-list>

          <v-list-item-title>Unidades</v-list-item-title>

          <v-list-item
              v-for="(unit, i) in supported_units"
              @click="changeUnits(unit)"
              :key="unit"
              active-color="primary"
              rounded="xl"
          >
            <template v-slot:prepend>
              <v-icon icon="fa-solid fa-circle-check" v-if="this.$root.units == unit"></v-icon>
              <v-icon icon="fa-regular fa-circle" v-else></v-icon>
            </template>

            <v-list-item-title>{{ $t(`units.${unit}`) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="language_dialog = false" block variant="tonal">OK</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import moment from "moment/moment";
import {SUPPORTED_LANGUAGES, SUPPORTED_UNITS} from "@/locales/constants";
import axios from "axios";
import AdSense from '../components/AdSense.vue'

export default {
  name: "PublicLayout",
  components: {AdSense},
  data: () => ({
    supported_languages: SUPPORTED_LANGUAGES,
    supported_units: SUPPORTED_UNITS,
    app_name: process.env.VUE_APP_NAME,
    app_version: process.env.VUE_APP_VERSION,
    is_production: process.env.NODE_ENV === 'production',
    search_dialog: false,
    language_dialog: false,
    ads: null,
    legal_dialog: false,
    search_form: {
      "sport_type": [],
      "distance": [0, 100],
      "slope": [0, 1000],
      "date": []
    },
  }),
  created() {
    axios.post(
        `${process.env.VUE_APP_ROOT_API}/ads`,
        {
          "homepage": true
        }
    ).then((response) => {
      this.ads = response.data
    })
  },
  methods: {
    changeLang(new_lang) {
      this.$store.commit('changeLang', {new_lang: new_lang})
      this.$root.$i18n.locale = new_lang
      this.$root.$data.moment.locale(new_lang)
    },
    changeUnits(new_units) {
      this.$store.commit('changeUnits', {new_units: new_units})
    },
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    is_admin: {
      get: function () {
        return this.$root.is_admin
      }
    },
    name: {
      get: function () {
        return this.$root.name
      }
    }
  }
};
</script>