<template>

  <v-parallax src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg" height="150px">
    <div class="d-flex flex-column fill-height justify-center align-center text-white">
      <h1 class="text-h4 font-weight-thin mb-4">
        <span>Aviso de Cookies</span>
      </h1>
    </div>
  </v-parallax>

  <v-container fluid style="max-width: 680px" >



    <h3>Última actualización: 13 de mayo 2024</h3>
    <br>
    <p>Esta política de cookies explica qué son las cookies y cómo las utilizamos en nuestro sitio web. Te recomendamos leer esta política para entender qué tipo de información recopilamos, cómo la utilizamos y cómo puedes controlar su uso.</p>
    <br>
    <h2>¿Qué son las cookies?</h2>
    <br>
    <p>Las cookies son pequeños archivos de texto que se almacenan en tu ordenador o dispositivo móvil cuando visitas un sitio web. Se utilizan ampliamente para hacer que los sitios web funcionen de manera más eficiente, así como para proporcionar información a los propietarios del sitio.</p>
    <br>
    <h2>¿Cómo utilizamos las cookies?</h2>
    <br>
    <p>Utilizamos cookies por varias razones, que se detallan a continuación. Desafortunadamente, en la mayoría de los casos, no hay opciones estándar de la industria para deshabilitar las cookies sin deshabilitar completamente la funcionalidad y las características que agregan a este sitio. Se recomienda que dejes todas las cookies si no estás seguro de si las necesitas o no, en caso de que se utilicen para proporcionar un servicio que utilices.</p>
    <br>
    <h2>Cookies necesarias:</h2>
    <br>
    <p>Estas cookies son esenciales para brindarte los servicios disponibles a través de nuestro sitio web y para permitir que utilices ciertas funciones. Sin estas cookies, los servicios que has solicitado no se pueden proporcionar, y solo se utilizan para proporcionarle esos servicios.</p>
    <br>
    <h2>Cookies de rendimiento y análisis:</h2>
    <br>
    <p>Estas cookies se utilizan para recopilar información sobre cómo los visitantes usan nuestro sitio web. Utilizamos esta información para compilar informes y para ayudarnos a mejorar el sitio. Las cookies recopilan información de forma anónima, incluido el número de visitantes al sitio, de dónde provienen los visitantes y las páginas que visitaron.</p>
    <br>
    <h2>Cookies de funcionalidad:</h2>
    <br>
    <p>Estas cookies se utilizan para proporcionar funcionalidades adicionales en nuestro sitio web, como recordar tus preferencias y permitirte interactuar con nuestro sitio web de manera efectiva cuando lo utilizas.</p>
    <br>
    <h2>Cookies de publicidad:</h2>
    <br>
    <p>Estas cookies se utilizan para hacer que la publicidad sea más relevante para ti y tus intereses. También se utilizan para limitar la cantidad de veces que ves un anuncio, así como para ayudar a medir la efectividad de la campaña publicitaria.</p>
    <br>
    <h2>Control de cookies</h2>
    <br>
    <p>Puedes controlar y administrar las cookies de varias maneras. Ten en cuenta que eliminar o deshabilitar las cookies puede afectar tu experiencia en el sitio web y algunas partes del sitio pueden no funcionar correctamente.</p>
    <br>
    <p>La mayoría de los navegadores web te permiten controlar las cookies a través de su configuración de preferencias. Para obtener más información sobre cómo controlar las cookies, visita el sitio web de tu navegador o consulta la función de ayuda de tu navegador.</p>
    <br>
    <h2>Cambios en esta política de cookies</h2>
    <br>
    <p>Nos reservamos el derecho de actualizar esta política de cookies en cualquier momento. Te recomendamos que revises esta política periódicamente para estar informado sobre cómo estamos utilizando las cookies.</p>
    <br>
    <h2>Contacto</h2>
    <br>
    <p>Si tienes alguna pregunta sobre nuestra política de cookies, por favor contáctanos al <v-btn class="pa-0" size="small" variant="text" href="mailto:info@mychip.es" target="_blank">correo electrónico (info@mychip.es)</v-btn>.</p>
  </v-container>

</template>

<script>
import {defineComponent} from 'vue';
import axios from "axios";
import router from "@/router";

export default defineComponent({
  name: 'CompanyView',
  data: () => ({
  }),
  computed: {
    english() {
      return this.$store.getters.language == 'en'
    },
    spanish() {
      return this.$store.getters.language == 'es'
    },
    valencian() {
      return this.$store.getters.language == 'ca'
    },
  }
});
</script>
