<template>

  <v-breadcrumbs bg-color="primary" density="compact" v-if="modality">

    <v-breadcrumbs-item :to="{ name: 'AdminEvent', params: { event_id: modality.event.slug }}"
                        class="d-inline-block text-truncate">
      {{ modality.event.name_short || modality.event.name }}
    </v-breadcrumbs-item>

    <v-breadcrumbs-divider></v-breadcrumbs-divider>

    <v-breadcrumbs-item :to="{ name: 'AdminEvent', params: { event_id: modality.event.slug, modality_selected: modality._id.$oid }}"
                        class="d-inline-block text-truncate">
      {{ modality.short_name || modality.name }}
    </v-breadcrumbs-item>

    <v-breadcrumbs-divider></v-breadcrumbs-divider>

    <v-breadcrumbs-item class="d-inline-block text-truncate">
      Resultados
    </v-breadcrumbs-item>

  </v-breadcrumbs>

  <v-container fluid>

    <v-row>
      <v-col><h1>Resultados</h1></v-col>
      <v-col class="text-right">
        <v-btn v-if="modality" :to="{name: 'Modality', params: {modality_id: modality._id.$oid}}" color="primary">Vista publica</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field v-model="filter_name" density="compact" clearable append-inner-icon="fa-solid fa-magnifying-glass"
                      @click:append-inner="get_registrations" @click:clear="get_registrations" @keydown.enter="get_registrations"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mb-2">
      <v-col>
        <v-chip-group>
          <v-chip label size="small">Atajos</v-chip>
          <v-chip label size="small" variant="outlined" class="text-caption text-uppercase">
            TAB
            <v-tooltip activator="parent" location="bottom">Pasa al siguiente tiempo vacío</v-tooltip>
          </v-chip>
          <v-chip label size="small" variant="outlined" class="text-caption text-uppercase">
            ESC
            <v-tooltip activator="parent" location="bottom">Borra el campo actual</v-tooltip>
          </v-chip>
          <v-chip label size="small" variant="outlined" class="text-caption text-uppercase">
            ENTER
            <v-tooltip activator="parent" location="bottom">Envía el tiempo</v-tooltip>
          </v-chip>
        </v-chip-group>
      </v-col>
      <v-col class="text-right">
        <v-chip label variant="outlined" size="small">mostrando<span v-if="stats" class="ml-1">{{ stats.total }}</span>/<span
            v-if="stats_no_filter">{{ stats_no_filter.total }}</span></v-chip>
      </v-col>
    </v-row>

    <v-progress-linear v-if="loading_registrations" class="mt-4" indeterminate :height="12">Resultados</v-progress-linear>

    <template v-if="!loading_registrations">
      <template v-if="registrations">

        <v-table density="compact">
          <thead>
          <tr class="text-caption text-uppercase font-weight-thin">
            <th>dorsal</th>
            <th>salida</th>
            <th v-for="(i, idx) in modality.intermediates">
              int{{idx+1}}
              <v-btn size="x-small" variant="elevated" @click="download_results(idx)">
                <v-icon size="x-small" icon="fa-solid fa-download"></v-icon>
              </v-btn>
            </th>
            <th>meta</th>
            <th>estado</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="r in registrations" :class="(r.result.intermediate_results.filter(x => x.total_time > 0).length != r.result.intermediate_results.length)?'bg-warning':''">
            <td class="text-caption">
              {{r.bib_number}}
              <v-tooltip activator="parent" location="bottom">{{r.name}} {{r.surname}}</v-tooltip>
            </td>
            <td class="text-caption">
              <template v-if="r.result">
                <span v-if="r.result.start_time_real && r.result.total_time_real && r.result.total_time && r.result.total_time_real && r.result.total_time != r.result.total_time_real">
                  +{{ r.result.total_time - r.result.total_time_real }}s
                </span>
              </template>
            </td>
            <td v-for="(i, idx) in modality.intermediates"  >
              <template v-if="r.result">
                <span v-if="r.result.intermediate_results && r.result.intermediate_results[idx] && r.result.intermediate_results[idx].total_time">
                  <template v-if="!delete_time_confirm[r.bib_number][i.order]">
                    {{$root.parse_time_from_seconds(r.result.intermediate_results[idx].total_time)}}
                    <v-icon size="x-small" icon="fa-solid fa-xmark" color="error" @click="delete_time_confirm[r.bib_number][i.order] = true"></v-icon>
                  </template>
                  <template v-else>
                    <template v-if="!deleting_time[r.bib_number][i.order]">
                      <v-btn size="x-small" variant="text" color="error" @click="delete_intermediate_time(r.bib_number, i.order, r)">borrar</v-btn>
                      <v-icon size="x-small" icon="fa-solid fa-ban" @click="delete_time_confirm[r.bib_number][i.order] = false"></v-icon>
                    </template>
                    <template v-else>
                      <span v-if="deleted_time[r.bib_number][i.order]">
                        <span class="text-caption text-success text-uppercase">borrado</span>
                        <v-icon end size="x-small" icon="fa-solid fa-check" color="success"></v-icon>
                      </span>
                      <v-icon v-else size="x-small" icon="fa-solid fa-spinner fa-spin"></v-icon>
                    </template>
                  </template>
                </span>
                <span v-else-if="['started', 'finished', 'retired'].includes(r.result.status)" class="text-no-wrap">
                  <template v-if="!adding_time[r.bib_number][i.order]">
                    <input autofocus v-model="new_time[r.bib_number][i.order]" @input="is_new_time_ok(r.bib_number, i.order)" v-on:keyup.esc="new_time[r.bib_number][i.order] = ''" v-on:keyup.enter="add_intermediate_time(r.bib_number, i.order, r)" class="text-center" v-maska data-maska="##:##:##" style="border: 1px solid #1a222d; width: 70px">
                    <v-icon end v-if="new_time_ok[r.bib_number][i.order]" @click="add_intermediate_time(r.bib_number, i.order, r)" size="x-small" icon="fa-solid fa-add" color="success"></v-icon>
                    <v-icon end v-else disabled size="x-small" icon="fa-solid fa-ban" color="grey"></v-icon>
                  </template>
                  <template v-else>
                    <span v-if="added_time[r.bib_number][i.order]">
                      <span class="text-caption text-success text-uppercase">Añadido</span>
                      <v-icon end size="x-small" icon="fa-solid fa-check" color="success"></v-icon>
                    </span>
                    <v-icon v-else size="x-small" icon="fa-solid fa-spinner fa-spin"></v-icon>
                  </template>
                </span>
              </template>
            </td>
            <td>
              <span v-if="r.result && r.result.total_time">{{$root.parse_time_from_seconds(r.result.total_time)}}</span>
            </td>
            <td class="text-caption">
              <span v-if="r.result && r.result.status">{{r.result.status}}</span>
            </td>
          </tr>
          </tbody>
        </v-table>

        <v-pagination
            v-if="!loading_registrations && stats"
            density="compact"
            v-model="page"
            :length="1 + parseInt(stats.total/page_size)"
        ></v-pagination>
      </template>
      <v-alert v-else type="info">No se han encontrado resultados</v-alert>
    </template>

  </v-container>

</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'AdminResultsList',
  components: {

  },
  data: () => ({
    now: null,
    modality_id: null,
    modality: null,
    all_modalities: null,
    loading_registrations: true,
    registrations: null,
    stats_no_filter: null,
    stats: null,
    page: 1,
    page_size: 50,
    filter_name: "",
    generating_excel_registrations: false,
    new_time_ok: {},
    delete_time_confirm: {},
    new_time: {},
    adding_time: {},
    added_time: {},
    deleting_time: {},
    deleted_time: {},
  }),
  created() {
    this.modality_id = this.$route.params.modality_id
    this.now = this.moment()
    document.title = "Resultados"
    this.get_modality()
  },
  methods: {
    is_new_time_ok(b, o) {
      let t = this.new_time[b][o]
      if ( t.length !== 8 ) {
        this.new_time_ok[b][o] = false
        return
      }
      let time_split = t.split(":")
      if  (parseInt(time_split[1]) > 59) {
        this.new_time_ok[b][o] = false
        return
      }
      if  (parseInt(time_split[2]) > 59) {
        this.new_time_ok[b][o] = false
        return
      }
      this.new_time_ok[b][o] = true
    },
    add_intermediate_time(b, o, reg) {
      let t = this.new_time[b][o]
      if ( !this.new_time_ok[b][o] ) {
        return
      }
      let time_split = t.split(":")
      let total_time =  parseInt(time_split[0]) * 3600 + parseInt(time_split[1]) * 60 + parseInt(time_split[2])
      let payload = {"data": [{
        'bib': b,
        'intermediate': this.modality.intermediates[o]._id.$oid,
        'registration': reg._id.$oid,
        'total_time': total_time,
        'source': 'manual',
      }]}
      this.adding_time[b][o] = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/add-intermediate`,
          payload
      ).then((response) => {
        console.log("added")
        this.added_time[b][o] = true
      }).catch((error) => {
        console.error(error)
      })
    },
    delete_intermediate_time(b, o, reg) {
      let payload = {
          'bib': b,
          'intermediate': this.modality.intermediates[o]._id.$oid,
          'registration': reg._id.$oid,
        }
      this.deleting_time[b][o] = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/delete-intermediate`,
          payload
      ).then((response) => {
        console.log("deleted")
        this.deleted_time[b][o] = true
      }).catch((error) => {
        console.error(error)
      })
    },
    parse_filters() {
      let filters = {
        "offset": this.page_size * (this.page - 1),
        "limit": this.page_size,
        "status":  ["finished", "retired", "started"]
      }
      filters["modality"] = this.modality_id
      if (this.filter_name !== null && this.filter_name.length > 0) {
        filters["free_text_search"] = this.filter_name
      }
      let order = ["result__status", "result__total_time", "result__shown_order"]
      this.modality.intermediates.slice().reverse().forEach(int => {
        order.push(`-result__intermediate_results__${int.order}__total_time`)
        order.push(`-result__intermediate_results__${int.order}__shown_order`)
      })
      filters["order"] = order
      return filters
    },
    get_modality() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/modalities`,
          {id: this.modality_id}
      ).then((response) => {
        this.modality = response.data[0]
        this.get_all_modalities()
        this.get_registrations_stats_no_filter()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_all_modalities() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/modalities`,
          {event: this.modality.event._id.$oid}
      ).then((response) => {
        this.all_modalities = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    get_registrations_stats_no_filter() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations/stats`,
          {"modality": this.modality_id}
      ).then((response) => {
        this.stats_no_filter = response.data
        this.get_registrations()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_registrations() {
      this.loading_registrations = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations`,
          this.parse_filters()
      ).then((response) => {
        let regs = response.data
        regs.forEach(r => {
          this.new_time[r.bib_number] = {}
          this.new_time_ok[r.bib_number] = {}
          this.delete_time_confirm[r.bib_number] = {}
          this.adding_time[r.bib_number] = {}
          this.deleting_time[r.bib_number] = {}
          this.added_time[r.bib_number] = {}
          this.deleted_time[r.bib_number] = {}
          this.modality.intermediates.forEach(i => {
            this.new_time[r.bib_number][i.order] = ""
            this.new_time_ok[r.bib_number][i.order] = false
            this.delete_time_confirm[r.bib_number][i.order] = false
            this.adding_time[r.bib_number][i.order] = false
            this.deleting_time[r.bib_number][i.order] = false
            this.added_time[r.bib_number][i.order] = false
            this.deleted_time[r.bib_number][i.order] = false
          })
        })
        this.registrations = regs
        this.loading_registrations = false
        this.get_registrations_stats()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_registrations_stats() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations/stats`,
          this.parse_filters()
      ).then((response) => {
        this.stats = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    download_results(intermediate) {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/download-results`,
          {
            "modality": this.modality_id,
            "intermediate": intermediate,
          }
      ).then((response) => {
        window.open(response.data, '_blank');
      }).catch((error) => {
        console.error(error)
      })
    },
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    }
  },
  watch: {
    page: {
      handler() {
        this.get_registrations()
      }
    },
  }
});
</script>
