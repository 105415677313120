export default {
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["welcome"])},
  "event": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["no events"]), _normalize(["event"]), _normalize([" events"])])},
  "slogan": {
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adding"])},
    "together": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["together"])}
  },
  "constants": {
    "event_status": {
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelled"])},
      "registrations_not_opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registrations not opened"])},
      "registrations_opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registration opened"])},
      "registrations_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sold out"])},
      "registrations_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registrations closed"])},
      "waiting_for_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["waiting for start"])},
      "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["live"])},
      "finished_provisional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finished"])},
      "finished_official": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finished"])}
    },
    "sport": {
      "walking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["walking"])},
      "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["running"])},
      "trail_running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trail running"])},
      "hiking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hikin"])},
      "time_trial_climb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["time trial climb"])},
      "cycling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cycling"])},
      "btt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mountain bike"])},
      "stages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stages"])},
      "biathlon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["biathlon"])},
      "triathlon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["triathlon"])},
      "swimming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["swimming"])},
      "open_waters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["open waters"])},
      "relays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relays"])},
      "climbing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["climbing"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other"])}
    },
    "chronotype": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])}
    },
    "gender": {
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["male"])},
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["female"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other"])}
    },
    "extra_services": {
      "description": {
        "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "coffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "classification_type": {
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["individual"])},
      "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teams"])}
    }
  },
  "_event": {
    "circuit_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this event is part of the circuit"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["register"])},
    "registration_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registration list"])},
    "edit_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit registration"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
    "federation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["federation"])},
    "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organizer"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["documents"])},
    "modality": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["a"]), _normalize(["modality"]), _normalize(["modalities"])])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["information"])},
    "error_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["option not available anymore"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["place"])},
    "datetime_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])}
  },
  "result_status": {
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finished"])},
    "out_of_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["out of time"])},
    "retired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["withdrawn"])},
    "disqualified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disqualifie"])},
    "not_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not started"])},
    "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["started"])}
  },
  "_modality": {
    
  },
  "language_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "page_titles": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events calendar"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classifications"])},
    "registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration list"])},
    "event_list": {
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event calendar"])},
      "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classifications"])}
    }
  },
  "_event_list": {
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "filter_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filters applied"])},
    "show_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show filters"])},
    "hide_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hide filters"])},
    "filters_name": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By name"])},
      "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By sport"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By date"])},
      "date_values": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])},
        "one_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 month from now"])},
        "three_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 months from now"])},
        "six_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["half a year from now"])},
        "one_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["one year from now"])}
      },
      "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By distance"])},
      "slider": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["any"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["up to"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
        "more_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more than"])}
      },
      "slope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slope"])},
      "nearby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Near me"])},
      "nearby_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate location services to use this filter"])}
    }
  },
  "units": {
    "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metric"])},
    "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imperial"])},
    "distance": {
      "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kilometers"])},
      "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["miles"])}
    }
  }
}