<template>

  <v-container style="max-width: 960px;">

    <template v-if="circuit == null">
      <v-progress-linear indeterminate height="12">Circuit</v-progress-linear>
    </template>
    <template v-else>
      <v-card color="primary">
        <v-toolbar>
          <v-toolbar-title>{{ circuit.name }}</v-toolbar-title>
          <v-toolbar-actions>
            <v-btn stacked>
              <v-icon size="small" icon="fa-solid fa-save"></v-icon>
              Guardar
            </v-btn>
            <v-btn stacked>
              <v-icon size="small" icon="fa-solid fa-eye"></v-icon>
              Vista
            </v-btn>
          </v-toolbar-actions>
        </v-toolbar>
      </v-card>
    </template>

  </v-container>

</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'AdminCircuit',
  components: {
  },
  data: () => ({
    now: null,
    circuit_id: null,
    circuit: null,
    circuit_events: null,
    create: true,
    search_event: false,
    search_event_text: null,
    events: null,
    loading_events: false,
  }),
  created() {
    this.circuit_id = this.$route.params.circuit_id
    this.now = this.moment()
    document.title = "Edit circuit"
    this.get_circuit()
    this.get_circuit_events()
  },
  methods: {
    get_circuit() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/circuits?t=${this.moment.now()}`,
          {"id": this.circuit_id,}
      ).then((response) => {
        this.circuit = response.data[0]
        // if (Object.hasOwn(this.ad, 'date_start')) {
        //   this.ad.date_start = this.moment.utc(this.ad.date_start.$date).format('YYYY-MM-DD')
        // }
        // if (Object.hasOwn(this.ad, 'date_end')) {
        //   this.ad.date_end = this.moment.utc(this.ad.date_end.$date).format('YYYY-MM-DD')
        // }
        // console.log(this.ad)
        // if (Object.hasOwn(this.ad, 'event')) {
        //   this.ad.event = this.ad.event.$oid
        // }
      }).catch((error) => {
        console.error(error)
      })
    },
    save_ad() {
      if ( this.ad.date_start == null ) {
        delete this.ad.date_start
      }
      if ( this.ad.date_end == null ) {
        delete this.ad.date_end
      }
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/ad?t=${this.moment.now()}`,
          this.ad
      ).then((response) => {
        if (response.data) {
          this.$router.go()
        }
      }).catch((error) => {
        console.error(error)
      })
    },
    get_circuit_events() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/events`,
          {
            "circuit": this.circuit_id
          }
      ).then((response) => {
        this.circuit_events = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    fetch_events() {
      this.events = null
      this.loading_events = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/events`,
          {
            "free_text_search": this.search_event_text
          }
      ).then((response) => {
        this.events = response.data
        this.loading_events = false
      }).catch((error) => {
        console.error(error)
      })
    }
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    },

    constants: {
      get: function () {
        return this.$root.$data.constants
      }
    },
  },
  watch: {
    page: {
      handler() {
        this.get_ads()
      }
    },
  }
});
</script>
