<template>

  <v-container style="max-width: 960px;">

    <v-row>
      <v-col><h1>Circuitos</h1></v-col>
    </v-row>
    <v-text-field v-model="filter_name" density="compact" clearable append-inner-icon="fa-solid fa-magnifying-glass"
                  @click:append-inner="get_circuits" @click:clear="get_circuits" @keydown.enter="get_circuits"></v-text-field>
    <v-progress-linear v-if="loading_circuits" class="mt-4" indeterminate :height="12">Circuitos</v-progress-linear>

    <template v-if="!loading_circuits">
      <template v-if="circuits">
        <CircuitsTable :circuits="circuits"></CircuitsTable>
        <v-pagination
            v-if="stats"
            v-model="page"
            :length="1 + parseInt(stats.total/page_size)"
            total-visible="4"
        ></v-pagination>
      </template>
      <v-alert v-else type="info">No circuits were found</v-alert>
    </template>

  </v-container>

</template>

<script>
import {defineComponent} from 'vue';
import Calendar from '../components/Calendar.vue'
import CircuitsTable from "@/components/CircuitsTable.vue";

export default defineComponent({
  name: 'AdminEventsList',
  components: {
    CircuitsTable
  },
  data: () => ({
    now: null,
    loading_circuits: true,
    circuits: null,
    stats: null,
    page: 1,
    page_size: 12,
    filter_name: "",
  }),
  created() {
    this.now = this.moment()
    document.title = "Admin Circuits"
    this.get_circuits()
  },
  methods: {
    parse_filters() {
      let filters = {
        "offset": this.page_size * (this.page - 1),
        "limit": this.page_size,
        "order": ["-date_start"]
      }
      if ( this.filter_name !== null && this.filter_name.length > 0 ) {
        filters["name"] = this.filter_name
      }
      return filters
    },
    get_circuits() {
      this.loading_circuits = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/circuits`,
          this.parse_filters()
      ).then((response) => {
        this.circuits = response.data
        this.loading_circuits = false
        this.get_circuits_stats()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_circuits_stats() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/circuits/stats`,
          this.parse_filters()
      ).then((response) => {
        this.stats = response.data
      }).catch((error) => {
        console.error(error)
      })
    }
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    }
  },
  watch: {
    page: {
      handler() {
        this.get_circuits()
      }
    },
  }
});
</script>
