<template>

  <v-container style="max-width: 960px;">

    <v-row>
      <v-col><h1>Eventos</h1></v-col>
    </v-row>
    <v-text-field v-model="filter_name" density="compact" clearable append-inner-icon="fa-solid fa-magnifying-glass"
                  @click:append-inner="get_events" @click:clear="get_events" @keydown.enter="get_events"></v-text-field>
    <v-progress-linear v-if="loading_events" class="mt-4" indeterminate :height="12">Events</v-progress-linear>

    <template v-if="!loading_events">
      <template v-if="events">
        <EventsTable :events="events" :registrations_stats="registrations_stats"></EventsTable>
        <v-pagination
            density="compact"
            v-if="stats"
            v-model="page"
            :length="1 + parseInt(stats.total/page_size)"
        ></v-pagination>
      </template>
      <v-alert v-else type="info">No events were found</v-alert>
    </template>

  </v-container>

</template>

<script>
import {defineComponent} from 'vue';
import Calendar from '../components/Calendar.vue'
import EventsTable from "@/components/EventsTable.vue";

export default defineComponent({
  name: 'AdminEventsList',
  components: {
    Calendar,
    EventsTable,
  },
  data: () => ({
    now: null,
    loading_events: true,
    events: null,
    registrations_stats: {},
    stats: null,
    page: 1,
    page_size: 12,
    filter_name: "",
  }),
  created() {
    this.now = this.moment()
    document.title = "Admin Events"
    this.get_events()
  },
  methods: {
    parse_filters() {
      let filters = {
        "offset": this.page_size * (this.page - 1),
        "limit": this.page_size,
        "order": ["-created_at"]
      }
      if ( this.filter_name !== null && this.filter_name.length > 0 ) {
        filters["free_text_search"] = this.filter_name
      }
      return filters
    },
    get_events() {
      this.loading_events = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/events`,
          this.parse_filters()
      ).then((response) => {
        this.events = response.data
        this.loading_events = false
        this.get_events_stats()
        this.get_events_registrations_stats()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_events_stats() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/events/stats`,
          this.parse_filters()
      ).then((response) => {
        this.stats = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    get_events_registrations_stats() {
      this.events.forEach(e => {
        this.axios.post(
            `${process.env.VUE_APP_ROOT_API}/registrations/stats`,
            {
              "event": e.slug,
            }
        ).then((response) => {
          this.registrations_stats[e._id.$oid] = response.data
        }).catch((error) => {
          console.error(error)
        })
      })
    }
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    }
  },
  watch: {
    page: {
      handler() {
        this.get_events()
      }
    },
  }
});
</script>
