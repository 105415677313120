import { createRouter, createWebHistory } from 'vue-router'

import PublicLayout from '../layouts/PublicLayout.vue'
import AdminLayout from '../layouts/AdminLayout.vue'

import HomeView from '../views/HomeView.vue'
import CompanyView from '../views/PageInfoCompany.vue'
import ReturnPolicy from '../views/PageReturnPolicy.vue'
import Cookies from '../views/PageInfoCookies.vue'
import CircuitView from '../views/CircuitView.vue'
import CircuitResults from '../views/CircuitResults.vue'
import EventView from '../views/EventView.vue'
import ModalityView from '../views/ModalityView.vue'
import IndividualResultView from '../views/IndividualResultView.vue'

import EventList from '../views/EventList.vue'
import CircuitList from '../views/CircuitList.vue'
import EventRegistrations from '../views/EventRegistrations.vue'
import EventRegister from '../views/EventRegister.vue'

import LogIn from "@/views/LogIn.vue";

import AdminView from "@/views/AdminView.vue";

import AdminReports from '../views/AdminReportsView.vue'

import AdminAdsList from "@/views/AdminAdsList.vue";
import AdminEventsList from "@/views/AdminEventsList.vue";
import AdminCircuitList from "@/views/AdminCircuitList.vue";
import AdminUsersList from "@/views/AdminUsersList.vue";
import AdminRegistrationsList from "@/views/AdminRegistrationsList.vue";
import AdminResultsList from "@/views/AdminResultsList.vue";
import AdminOrganismsList from "@/views/AdminOrganismsList.vue";
import AdminReadersList from "@/views/AdminReadersList.vue";

import AdminEventView from '../views/AdminEventView.vue'
import AdminOrganism from '../views/AdminOrganism.vue'
import AdminCircuit from '../views/AdminCircuit.vue'
import AdminUser from '../views/AdminUser.vue'
import AdminRegistration from '../views/AdminRegistration.vue'
import AdminAd from '../views/AdminAd.vue'
import AdminReader from '../views/AdminReader.vue'
import AdminReaderConfiguration from '../views/AdminReaderConfiguration.vue'

import store from '../store'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    redirect: "/",
  },
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      layout: PublicLayout
    }
  },
  {
    path: '/company',
    name: 'Company',
    component: CompanyView,
    meta: {
      layout: PublicLayout
    }
  },
  {
    path: '/return-policy',
    name: 'ReturnPolicy',
    component: ReturnPolicy,
    meta: {
      layout: PublicLayout
    }
  },

  {
    path: '/cookies',
    name: 'Cookies',
    component: Cookies,
    meta: {
      layout: PublicLayout
    }
  },
  {
    path: '/login',
    name: 'LogIn',
    component: LogIn,
    meta: {
      layout: PublicLayout
    }
  },
  {
    path: '/circuits',
    name: 'Circuits',
    component: CircuitList,
    meta: {
      layout: PublicLayout
    }
  },
  {
    path: '/c/:circuit_id',
    name: 'Circuit',
    component: CircuitView,
    meta: {
      layout: PublicLayout
    }
  },
  {
    path: '/c/:circuit_id/results/:modality_id?/:type?/:gender?',
    name: 'CircuitResults',
    component: CircuitResults,
    meta: {
      layout: PublicLayout
    }
  },
  {
    path: '/e/:event_id',
    name: 'Event',
    component: EventView,
    meta: {
      layout: PublicLayout
    }
  },
  {
    path: '/e/:event_id/registrations',
    name: 'EventRegistrations',
    component: EventRegistrations,
    meta: {
      layout: PublicLayout
    }
  },
  {
    path: '/e/:event_id/register',
    name: 'EventRegister',
    component: EventRegister,
    meta: {
      layout: PublicLayout
    }
  },
  {
    path: '/events/:type',
    name: 'EventCalendar',
    component: EventList,
    meta: {
      type: 'calendar',
      layout: PublicLayout
    }
  },
  {
    path: '/results',
    name: 'EventResults',
    component: EventList,
    meta: {
      type: 'results',
      layout: PublicLayout
    }
  },
  {
    path: '/m/:modality_id',
    name: 'Modality',
    component: ModalityView,
    meta: {
      layout: PublicLayout
    }
  },
  {
    path: '/r/:result_id',
    name: 'IndividualResult',
    component: IndividualResultView,
    meta: {
      layout: PublicLayout
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminView,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/admin/e/:event_id/:modality_selected?',
    name: 'AdminEvent',
    component: AdminEventView,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/admin/reports/:modality_id',
    name: 'AdminReports',
    component: AdminReports,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/admin/events',
    name: 'AdminEventsList',
    component: AdminEventsList,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/admin/circuits',
    name: 'AdminCircuitList',
    component: AdminCircuitList,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/admin/c/:circuit_id',
    name: 'AdminCircuit',
    component: AdminCircuit,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/admin/users',
    name: 'AdminUsersList',
    component: AdminUsersList,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/admin/user/:user_id',
    name: 'AdminUser',
    component: AdminUser,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/admin/registrations/:event_id/:modality_id?',
    name: 'AdminRegistrationsList',
    component: AdminRegistrationsList,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/admin/results/:modality_id',
    name: 'AdminResultsList',
    component: AdminResultsList,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/admin/registration/:registration_id',
    name: 'AdminRegistration',
    component: AdminRegistration,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/admin/organisms',
    name: 'AdminOrganismsList',
    component: AdminOrganismsList,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/admin/organism/:organism_id',
    name: 'AdminOrganism',
    component: AdminOrganism,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/admin/ads',
    name: 'AdminAdsList',
    component: AdminAdsList,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/admin/ad/:ad_id',
    name: 'AdminAd',
    component: AdminAd,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/admin/readers',
    name: 'AdminReadersList',
    component: AdminReadersList,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/admin/reader/:reader_id',
    name: 'AdminReader',
    component: AdminReader,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/admin/reader-configuration/:intermediate_id',
    name: 'AdminReaderConfiguration',
    component: AdminReaderConfiguration,
    meta: {
      layout: AdminLayout,
      admin: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/",
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createMemoryHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  }
})

router.beforeEach((to, from, next) => {
  const needs_admin = to.matched.some(record => record.meta.admin)
  const is_admin = store.getters.is_admin
  if ( needs_admin && !is_admin ) {
    // TODO: going to the page i am coming from
    next({name: 'LogIn'})
  }
  next()

})

export default router
