<template>
  <template v-if="loading">
    <v-progress-linear indeterminate :height="12" v-if="loading"></v-progress-linear>
  </template>
  <template v-else>

    <v-breadcrumbs color="" bg-color="primary" density="compact" v-if="circuit">

      <v-breadcrumbs-item :to="{ name: 'Circuit', params: { circuit_id: circuit.slug }}"
                          class="d-inline-block text-truncate">
        {{ circuit.name_short || circuit.name }}
      </v-breadcrumbs-item>

      <v-breadcrumbs-divider></v-breadcrumbs-divider>

      <v-breadcrumbs-item class="d-inline-block text-truncate">
        Clasificaciones acumuladas
      </v-breadcrumbs-item>


    </v-breadcrumbs>

    <v-container fluid style="max-width: 960px">

      <v-tabs center-active align-tabs="center" bg-color="primary" v-model="modality">
        <v-tab
            @click="$router.push({name: 'CircuitResults', params: {circuit_id: circuit_id, modality_id: m._id.$oid, gender: gender, type: type}}); reload_data()"
            v-for="m in circuit.modalities" :value="m._id.$oid">
          {{ m.name }}
        </v-tab>
      </v-tabs>

      <v-sheet class="pa-2  text-center" v-if="!loading_registrations">
        <v-row align-content="center" justify="center">
          <v-col cols="6" sm="4" class="pb-0">
            <v-select v-model="type" variant="outlined" label="Tipo clasificación"
                      @update:modelValue="$router.push({name: 'CircuitResults', params: {circuit_id: circuit_id, modality_id: modality, gender: gender, type: type}}); reload_data()"
                      hide-details density="compact" :items="[{name: 'individual', value: $t(`constants.classification_type.individual`)}, {name: 'teams', value: $t(`constants.classification_type.teams`)}]"
                      item-value="name" item-title="value"></v-select>
          </v-col>
          <v-col cols="6" sm="4" class="pb-0">
            <v-select v-model="gender" variant="outlined" label="Género"
                      @update:modelValue="$router.push({name: 'CircuitResults', params: {circuit_id: circuit_id, modality_id: modality, gender: gender, type: type}}); reload_data()"
                      hide-details density="compact" :items="[{name: 'male', value: $t(`constants.gender.male`)}, {name: 'female', value: $t(`constants.gender.female`)}]"
                      item-value="name" item-title="value"></v-select>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0 ">
            <v-text-field hide-details variant="outlined" clearable v-model="free_text_search"
                          density="compact" label="Buscar" append-inner-icon="fa-solid fa-magnifying-glass"
                          @click:append-inner="reload_data" @keydown.enter="reload_data" @click:clear="reload_data"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-sheet>

      <v-sheet class="pa-4 text-center text-uppercase">
        <h3>CLASIFICACIÓN {{current_modality.name}} {{$t(`constants.classification_type.${type}`)}} {{$t(`constants.gender.${gender}`)}}</h3>
      </v-sheet>

      <v-progress-linear indeterminate v-if="loading_registrations" height="12" class="pa-2">
        Cargando resultados
      </v-progress-linear>

      <v-sheet class="pl-2 pr-2 mt-0 pt-0 mb-0">
        <v-btn variant="text" size="small" color="primary" density="compact"
               :disabled="!races"
               @click="race_details = !race_details"
               :active="race_details">
          Detalle eventos
          <v-icon size="x-small" end icon="fa-solid fa-minus" v-if="race_details"></v-icon>
          <v-icon size="x-small" end icon="fa-solid fa-plus" v-else></v-icon>
        </v-btn>
      </v-sheet>

      <v-sheet v-if="race_details" class="pl-2 pr-2">
        <v-chip-group column>
          <v-chip v-for="(race, idx, idxx) in races" label size="x-small" class="mr-1">
            {{idxx + 1}}: {{race.name}}
          </v-chip>
        </v-chip-group>
      </v-sheet>


      <template v-if="['ctm_2023', 'femecv_2024_lliga', 'vall_uixo_5k'].includes(circuit.punctuation_type)">
        <Base :registrations="registrations" :events="events" :race_details="race_details" :category="category"
              :registrations_stats="registrations_stats" :gender="gender" :show_cat="show_cat"
              :loading_registrations="loading_registrations" :type="type" :circuit="circuit"
              :participants="participants" :races="races" :races_modalities="races_modalities"></Base>
      </template>


      <v-pagination
          density="compact"
          :disabled="loading_registrations_stats"
          v-if="registrations_stats"
          v-model="page"
          :length="1 + parseInt(registrations_stats.total/page_size)">
      </v-pagination>

    </v-container>
  </template>
</template>

<script>
import {defineComponent} from "vue";
import Base from '@/components/circuit_views/Base.vue'

export default defineComponent({
  name: 'CircuitView',
  components: {
    Base,
  },
  data: () => ({
    now: null,
    loading: true,
    circuit_id: null,
    circuit: null,
    current_modality: null,
    events: null,
    races: {},
    races_modalities: {},
    modality: null,
    gender: 'male',
    type: 'individual',
    loading_registrations: false,
    registrations: null,
    loading_registrations_stats: false,
    registrations_stats: null,
    show_cat: false,
    category: null,
    page: 1,
    page_size: 15,
    race_details: false,
    participants: null,
    free_text_search: null,
  }),
  created() {
    this.now = this.moment.utc()
    document.title = this.$root.app_name
    this.circuit_id = this.$route.params.circuit_id
    this.modality = this.$route.params.modality_id
    this.type = (this.$route.params.type) ? this.$route.params.type : this.type
    this.gender = (this.$route.params.gender) ? this.$route.params.gender : this.gender
    this.get_circuit()
  },
  methods: {
    get_circuit() {
      this.loading = true
      let payload = {
        slug: this.circuit_id
      }
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/circuits`,
          payload
      ).then((response) => {
        this.circuit = response.data[0]

        if (this.circuit.punctuation_type == "unassigned") {
          this.$router.push({name: 'Circuit', params: {circuit_id: this.circuit_id}}).then(() => {
            this.$router.go()
          })
        }

        if (!this.modality) {
          this.modality = this.circuit.modalities[0]._id.$oid
        }

        this.loading = false
        document.title = this.circuit.name_short || this.circuit.name
        this.reload_data()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_events() {
      this.loading_events = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/events`,
          {
            circuit: this.circuit._id.$oid,
            limit: 999
          }
      ).then((response) => {
        this.events = response.data
        this.loading_events = false

        this.races = {}
        this.races_modalities = {}
        this.events.forEach(e => {
          e.modalities.forEach(m => {
            if ( m.circuit_modality ) {
              if ( m.circuit_modality.$oid == this.modality ) {
                this.races[m._id.$oid] = e
                this.races_modalities[m._id.$oid] = m
              }
            }
          })
        })

      }).catch((error) => {
        console.error(error)
      })
    },
    reload_data() {
      this.circuit.modalities.forEach(m => {
        if ( m._id.$oid == this.modality ) {
          this.current_modality = m
        }
      })
      this.get_events()
      this.get_registrations()
    },
    get_registrations() {
      this.loading_registrations = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations`,
          this.parse_filters(),
      ).then((response) => {
        this.registrations = response.data
        this.loading_registrations = false
        if ( this.type == 'teams' ) {
          this.get_participants()
        }
        this.get_registrations_stats()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_registrations_stats() {
      this.loading_registrations_stats = true
      this.show_cat = false
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations/stats`,
          this.parse_filters(true),
      ).then((response) => {
        this.registrations_stats = response.data
        this.show_cat = !('category' in this.registrations_stats && Object.keys(this.registrations_stats.category).length <= 1);
        this.loading_registrations_stats = false
      }).catch((error) => {
        console.error(error)
      })
    },
    get_participants() {
      let ids = []
      this.registrations.forEach(r => {
        r.components.forEach(p => {
            ids.push(p.$oid)
        })
      })
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations`,
          {
            "ids": ids,
            "limit": ids.length + 1,
          },
      ).then((response) => {
        let participants = {}
        response.data.forEach(p => {
          participants[p._id.$oid] = p
        })
        this.participants = participants
      }).catch((error) => {
        console.error(error)
      })
    },
    parse_filters(only_totals=false) {
      let filters = {
        "modality": this.modality,
        "gender": [this.gender],
        "offset": this.page_size * (this.page - 1),
        "limit": this.page_size,
        "order": ["result.shown_order"],
        "circuit_registrations": true,
        "team_registrations": this.type == 'teams',
      }

      if ( only_totals === false  ) {
        if ( this.free_text_search !== null ) {
          filters["free_text_search"] = this.free_text_search
        }
      }

      // if (this.classification_filters['category'] !== null) {
      //   filters['category'] = [this.classification_filters['category']]
      // }
      //
      // if (this.classification_filters['club'] !== null) {
      //   filters['club'] = [this.classification_filters['club']]
      // }

      return filters
    },
  },
  watch: {
    page: {
      handler() {
        this.get_registrations()
      }
    },
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    is_admin: {
      get: function () {
        return this.$root.is_admin
      }
    },
    is_authenticated: {
      get: function () {
        return this.$root.is_authenticated
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    }
  }
})
</script>