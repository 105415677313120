<template>

  <v-container style="max-width: 960px;">

    <v-card>

      <v-toolbar color="primary">
        <v-toolbar-title>
          <span v-if="create">Crear anuncio</span>
          <span v-else>Editar anuncio</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn size="small" variant="text" stacked @click="save_ad">
            <v-icon size="small" icon="fa-solid fa-save"></v-icon>
            <span class="hidden-xs mt-1">Guardar</span>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

<!--      <v-sheet color="success" class="text-center" v-if="ad.is_active">-->
<!--        Anuncio activo-->
<!--      </v-sheet>-->

<!--      <v-sheet color="error" class="text-center" v-else>-->
<!--        Anuncio desactivado-->
<!--      </v-sheet>-->

      <v-card-text>
        <v-row>

          <v-col cols="12" sm="4">
            <v-text-field hide-details density="compact"
                          label="Nombre" variant="outlined" v-model="ad.name">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field hide-details density="compact"
                          label="Website" variant="outlined" v-model="ad.redirect">
            </v-text-field>
          </v-col>

          <v-col cols="8" sm="3">
            <v-text-field hide-details density="compact"
                          label="Imagen" variant="outlined" v-model="ad.image">
            </v-text-field>
          </v-col>

          <v-col cols="4" sm="1" class="border">
            <v-img :src="ad.image" height="25px"></v-img>
          </v-col>

          <v-col cols="8" sm="4">
            <v-text-field hide-details clearable density="compact" label="Evento" variant="outlined"
                          v-model="ad.event" :disabled="ad.date_start || ad.date_end">
            </v-text-field>
          </v-col>

          <v-col cols="4" sm="2">
            <v-btn block :active="search_event" :disabled="ad.date_start || ad.date_end"
                   @click="search_event = !search_event">
              Evento<v-icon end icon="fa-solid fa-magnifying-glass"></v-icon>
            </v-btn>
          </v-col>

          <v-col cols="8" sm="3">
            <v-text-field hide-details density="compact" label="Fecha inicio" type="date" clearable
                          variant="outlined" v-model="ad.date_start" :disabled="ad.event">
            </v-text-field>
          </v-col>

          <v-col cols="8" sm="3">
            <v-text-field hide-details type="date" density="compact" label="Fecha fin" clearable
                          variant="outlined" v-model="ad.date_end" :disabled="ad.event">
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea hide-details density="compact" :disabled="!ad.event" label="Texto anuncio" variant="outlined" v-model="ad.extra_text">
            </v-textarea>
          </v-col>

        </v-row>
      </v-card-text>

    </v-card>

    <v-expand-transition>
      <v-card v-if="search_event" class="mt-3">
        <v-toolbar>
          <v-toolbar-title>
            Asignar evento
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field hint="Busque un evento para asignarle el anuncio" persistent-hint density="compact"
                        append-inner-icon="fa-solid fa-magnifying-glass"
                        @click:append-inner="fetch_events" @click:clear="fetch_events" @keydown.enter="fetch_events"
                        clearable label="Evento" variant="outlined" v-model="search_event_text"></v-text-field>

          <v-progress-linear indeterminate v-if="loading_events" height="12">Eventos</v-progress-linear>

          <template v-if="events">
          <span class="text-caption">Mostrando solo 15 resultados</span>
            <v-table density="compact">
              <tbody>
                <tr v-for="e in events">
                  <td>{{e.numeric_id}}</td>
                  <td>{{e.name}}</td>
                  <td>
                    <span v-if="e.date_start">
                      {{ moment.utc(e.date_start.$date).format('DD/MM/YYYY') }}
                    </span>
                  </td>
                  <td>
                    <v-btn block size="small" @click="ad.event = e._id.$oid ; search_event = false ; events = null;">Asignar</v-btn>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </template>

        </v-card-text>
      </v-card>
    </v-expand-transition>

  </v-container>

</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'AdminAd',
  components: {
  },
  data: () => ({
    now: null,
    ad_id: null,
    ad: {
    },
    create: true,
    search_event: false,
    search_event_text: null,
    events: null,
    loading_events: false,
  }),
  created() {
    this.ad_id = this.$route.params.ad_id
    this.now = this.moment()
    document.title = "Edit ad"
    if ( this.ad_id != null && this.ad_id  != '0' ) {
      this.create = false
      this.get_ad()
    }
  },
  methods: {
    get_ad() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/ads?t=${this.moment.now()}`,
          {
            "id": this.ad_id,
          }
      ).then((response) => {
        this.ad = response.data[0]
        if (Object.hasOwn(this.ad, 'date_start')) {
          this.ad.date_start = this.moment.utc(this.ad.date_start.$date).format('YYYY-MM-DD')
        }
        if (Object.hasOwn(this.ad, 'date_end')) {
          this.ad.date_end = this.moment.utc(this.ad.date_end.$date).format('YYYY-MM-DD')
        }
        console.log(this.ad)
        if (Object.hasOwn(this.ad, 'event')) {
          this.ad.event = this.ad.event.$oid
        }
        console.log(this.ad)
      }).catch((error) => {
        console.error(error)
      })
    },
    save_ad() {
      if ( this.ad.date_start == null ) {
        delete this.ad.date_start
      }
      if ( this.ad.date_end == null ) {
        delete this.ad.date_end
      }
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/ad`,
          this.ad
      ).then((response) => {
        if (response.data) {
          this.$router.go()
        }
      }).catch((error) => {
        console.error(error)
      })
    },
    fetch_events() {
      this.events = null
      this.loading_events = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/events`,
          {
            "free_text_search": this.search_event_text
          }
      ).then((response) => {
        this.events = response.data
        this.loading_events = false
      }).catch((error) => {
        console.error(error)
      })
    }
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    },

    constants: {
      get: function () {
        return this.$root.$data.constants
      }
    },
  },
  watch: {
    page: {
      handler() {
        this.get_ads()
      }
    },
  }
});
</script>
