<template>

    <v-app-bar color="secondary" app density="compact" rounded >

      <v-app-bar-nav-icon :to="{ name: 'Home'}" flat>
        <v-img class="mx-2" src="@/assets/mychip-logo.svg" height="30px" width="30px">
        </v-img>
      </v-app-bar-nav-icon>


      <v-toolbar-title>Admin</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn size="small" icon :to="{name: 'Admin'}">
        <v-icon size="small" icon="fa-solid fa-home"></v-icon>
        <v-tooltip activator="parent" location="bottom">
          Admin
        </v-tooltip>
      </v-btn>
      <v-btn size="small" icon :to="{name: 'AdminEventsList'}">
        <v-icon size="small" icon="fa-solid fa-calendar"></v-icon>
        <v-tooltip activator="parent" location="bottom">
          Eventos
        </v-tooltip>
      </v-btn>
      <v-btn size="small" icon :to="{name: 'AdminCircuitList'}">
        <v-icon size="small" icon="fa-solid fa-trophy"></v-icon>
        <v-tooltip activator="parent" location="bottom">
          Circuitos
        </v-tooltip>
      </v-btn>
      <v-btn size="small" icon :to="{name: 'AdminUsersList'}">
        <v-icon size="small" icon="fa-solid fa-users"></v-icon>
        <v-tooltip activator="parent" location="bottom">
          Usuarios
        </v-tooltip>
      </v-btn>
      <v-btn size="small" icon :to="{name: 'AdminReadersList'}">
        <v-icon size="small" icon="fa-solid fa-wifi"></v-icon>
        <v-tooltip activator="parent" location="bottom">
          Lectores
        </v-tooltip>
      </v-btn>
      <v-btn size="small" icon :to="{name: 'AdminOrganismsList'}">
        <v-icon size="small" icon="fa-solid fa-building"></v-icon>
        <v-tooltip activator="parent" location="bottom">
          Organizaciones
        </v-tooltip>
      </v-btn>
      <v-btn size="small" icon :to="{name: 'AdminAdsList'}">
        <v-icon size="small" icon="fa-solid fa-ad"></v-icon>
        <v-tooltip activator="parent" location="bottom">
          Anuncios
        </v-tooltip>
      </v-btn>


      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn color="error" icon size="small" v-bind="props">
            <v-icon size="small" icon="fa-solid fa-right-from-bracket"></v-icon>
          </v-btn>
        </template>

        <v-list density="compact">
            <v-list-item @click="logout" color="error">
              Cerrar sesión
            </v-list-item>
        </v-list>
      </v-menu>


<!--      <v-btn size="small" icon @click="logout">-->
<!--        <v-icon size="small" icon="fa-solid fa-right-from-bracket" color="red"></v-icon>-->
<!--        <v-tooltip activator="parent" location="bottom">-->
<!--          Cerrar sesión-->
<!--        </v-tooltip>-->
<!--      </v-btn>-->

    </v-app-bar>

    <v-main>
      <slot />
    </v-main>

</template>

<script>
import moment from "moment/moment";
import {SUPPORTED_LANGUAGES} from "@/locales/constants";

export default {
  name: "AdminLayout",
  data: () => ({
    app_name: process.env.VUE_APP_NAME,
    app_version: process.env.VUE_APP_VERSION,
    language_dialog: false,
    search_form: {
      "sport_type": [],
      "distance": [0, 100],
      "slope": [0, 1000],
      "date": []
    },
  }),
  methods: {
    logout() {
      this.$store.commit('changeToken', {new_token: null})
      this.$store.commit('changeRole', {new_role: null})
      this.$router.push({name: 'Home'})
    }
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    is_admin: {
      get: function () {
        return this.$root.is_admin
      }
    },
    name: {
      get: function () {
        return this.$root.name
      }
    }
  }
};
</script>