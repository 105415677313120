<template>

  <template v-if="loading">
    <v-progress-linear indeterminate :height="12" v-if="loading"></v-progress-linear>
  </template>
  <template v-else>
    <!--  <v-app-bar scroll-behavior="hide" scroll-threshold="100" density="compact" color="tertiary" v-if="event_data && is_admin">-->
    <!--    <v-app-bar-title>Admin</v-app-bar-title>-->
    <!--    <v-spacer></v-spacer>-->
    <!--    <v-btn stacked :to="{name: 'AdminEvent', params: {event_id: event_data.slug}}">Editar</v-btn>-->
    <!--  </v-app-bar>-->

    <v-app-bar scroll-behavior="hide" scroll-threshold="100" density="compact" color="tertiary"
               v-if="circuit && is_admin">
      <v-app-bar-title>
        <v-chip label>{{ circuit.numeric_id }}</v-chip>
        Admin
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn stacked :to="{name: 'AdminEvent', params: {event_id: circuit.slug}}">Editar</v-btn>
    </v-app-bar>

    <v-sheet>
      <v-img v-if="circuit.poster" class="w-100" cover
             lazy-src="https://mychip-dev-images.s3.eu-central-1.amazonaws.com/posters/default.png"
             :src="circuit.poster"
             height="150px" gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,1)">
      </v-img>
      <h1 class="text-center text-uppercase text-no-wrap text-truncate hidden-xs">{{
          circuit.name_short || circuit.name
        }}</h1>
      <h2 class="text-center text-uppercase text-no-wrap text-truncate hidden-sm-and-up">
        {{ circuit.name_short || circuit.name }}</h2>
      <v-sheet class="text-center pb-1">
        <v-chip class="mr-1" size="small" variant="flat" label v-for="t in circuit.tags">{{ t }}</v-chip>
        <template v-for="o in circuit.organisms">
          <v-chip class="mr-1" pill size="small" v-if="o.type == 'federation'">
            <v-avatar start v-if="o.logo">
              <v-img :src="o.logo" cover></v-img>
            </v-avatar>
            {{ o.name }}
          </v-chip>
        </template>
      </v-sheet>

    </v-sheet>

    <v-container fluid style="max-width: 960px">

      <v-row>

        <v-col cols="12">
          <v-card>
            <v-card-title>Inscripciones al circuito</v-card-title>
            <template v-if="circuit.registration_type == 'auto'">
              <v-card-text>La inscripción a alguna prueba es suficiente para puntuar en el circuito</v-card-text>
            </template>
            <template v-else-if="circuit.registration_type == 'register'">
              <v-card-subtitle>Este circuito requiere de una inscripción previa</v-card-subtitle>
              <v-card-text>
                <v-row v-if="circuit.documents.filter((x) => x.type == 'registration').length > 0">
                  <v-col>
                    <span class="text-caption">{{ $t('_event.documents') }}</span>
                    <br>

                    <v-chip-group column>
                      <template v-for="d in circuit.documents">
                        <v-chip label v-if="d.type == 'registration'" :href="d.url" target="_blank">
                          <v-icon start size="small" icon="fa-solid fa-file"></v-icon>
                          {{ d.name }}
                        </v-chip>
                      </template>
                    </v-chip-group>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="justify-center">


                <v-row>
                  <v-col cols="12" sm="6">
                    <v-btn :to="{name: 'EventRegister', params: {'event_id': circuit_id}}" block
                           variant="elevated"
                           :color="circuit.status == 'registrations_not_opened' ? '' : circuit.status == 'registrations_opened' ? 'primary' : 'error' "
                           :disabled="circuit.status != 'registrations_opened'">
                      <span v-if="circuit.status == 'registrations_opened'">{{ $t('_event.register') }}</span>
                      <span v-else>{{ circuit.status }}</span>
                    </v-btn>
                    <h5 class="text-caption text-center">
                    <span v-if="circuit.registration_start && circuit.registration_end">
                      {{ moment.utc(circuit.registration_start.$date).format("DD/MM HH:mm") }}
                      - {{ moment.utc(circuit.registration_end.$date).format("DD/MM HH:mm") }}
                    </span>
                    </h5>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-btn variant="outlined" block
                           :to="{name: 'EventRegistrations', params: {'event_id': circuit.slug}}">Listado de inscritos
                    </v-btn>
                  </v-col>

                </v-row>

              </v-card-actions>
            </template>
          </v-card>
        </v-col>

        <v-col cols="12" v-if="circuit.documents.filter((x) => x.type == 'result').length > 0">
          <v-card>
            <v-card-title>Classificaciones</v-card-title>
            <v-card-subtitle>Actualmente las clasificaciones dinámicas no están disponibles</v-card-subtitle>
            <v-card-text>
              <template v-for="d in circuit.documents">
                <template v-if="d.type == 'result'">
                  <v-chip size="small" label :href="d.url" target="_blank">
                    <v-icon start size="small" icon="fa-solid fa-file"></v-icon>
                    {{ d.name }}
                  </v-chip>
                </template>
              </template>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>


      <v-row>
        <v-col>
          <v-btn v-if="circuit.punctuation_type != 'unassigned'" color="primary" block
                 :to="{name: 'CircuitResults', params: {circuit_id: circuit_id}}">
            Clasificaciones acumuladas
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <template v-if="moment(circuit.date_start.$date) < moment() && moment(circuit.date_end.$date) > moment()"
                  v-for="(x, idx) in {previous_event: previous_event, next_event: next_event}">
          <v-col cols="6" v-if="x">
            <v-card>
              <v-card-subtitle class="mt-2">
                <span v-if="idx == 'previous_event'">Último evento disputado</span>
                <span v-if="idx == 'next_event'">Próximo evento</span>
              </v-card-subtitle>
              <v-card-title class="text-wrap">{{ x.name }}</v-card-title>
              <v-card-actions>
                <v-btn color="secondary" variant="elevated"
                       @click="scroll_to(x._id.$oid)"
                       size="small" block>Ver
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </template>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>Eventos del circuito</h2>

          <v-progress-linear indeterminate height="12" v-if="loading_events">Cargando eventos</v-progress-linear>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-for="e in events" :id="e._id.$oid">
              <EventCardV2 :e="e" :in_circuit="true"></EventCardV2>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-container>
  </template>

</template>

<script>
import {defineComponent} from 'vue';
import EventCard from "@/components/EventCard.vue";
import EventCardV2 from "@/components/EventCardV2.vue";

export default defineComponent({
  name: 'CircuitView',
  data: () => ({
    now: null,
    loading: true,
    loading_events: true,
    circuit_id: null,
    circuit: null,
    events: null,
    active_event: 0,
    previous_event: null,
    next_event: null,
  }),
  components: {
    EventCard,
    EventCardV2
  },
  created() {
    this.now = this.moment.utc()
    document.title = this.$root.app_name
    this.circuit_id = this.$route.params.circuit_id
    this.get_circuit()
  },
  methods: {
    get_circuit() {
      this.loading = true
      let payload = {
        slug: this.circuit_id
      }
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/circuits`,
          payload
      ).then((response) => {
        this.circuit = response.data[0]
        this.loading = false
        document.title = this.circuit.name_short || this.circuit.name
        this.get_events()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_events() {
      this.loading_events = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/events`,
          {
            circuit: this.circuit._id.$oid,
            limit: 999
          }
      ).then((response) => {
        this.events = response.data

        let previous_event = null
        let next_event = null
        this.events.forEach(e => {
          if (next_event == null) {
            if (this.moment(e.date_start.$date) < this.moment()) {
              previous_event = e
            } else {
              next_event = e
            }
          }
        })

        this.previous_event = previous_event
        this.next_event = next_event

        this.loading_events = false
      }).catch((error) => {
        console.error(error)
      })
    },
    scroll_to(ref_id) {
      // console.log(this.$refs[ref_id])
      document.getElementById(ref_id).scrollIntoView({behavior: 'smooth'})
    }
  },

  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    is_admin: {
      get: function () {
        return this.$root.is_admin
      }
    },
    is_authenticated: {
      get: function () {
        return this.$root.is_authenticated
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    }
  }

});
</script>
