<template>
  <v-dialog scrollable width="auto">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" size="x-small" variant="text" icon="fa-solid fa-circle-plus">
      </v-btn>
    </template>

    <template v-slot:default="{ isActive }">

      <v-card>

        <v-toolbar color="primary">
          <v-toolbar-title>
            {{ circuit.name }}
          </v-toolbar-title>
          <v-toolbar-items>
            <v-btn @click="isActive.value = false" icon>
              <v-icon icon="fa-solid fa-times"></v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-title class="text-center text-h4 pa-8 text-wrap">{{r.name}} {{r.surname}}</v-card-title>

        <v-card-text>

          <v-row class="justify-center align-center">
            <v-col class="text-center" cols="6" sm="3">
              <span class="text-caption text-capitalize">Participaciones</span>
              <h2>{{ r.total_races }}</h2>
              <span class="text-caption">de {{Object.keys(races).length}}</span>
            </v-col>
            <v-col class="text-center" cols="6" sm="3">
              <span class="text-caption text-capitalize">Posición</span>
              <h2>{{ r.result.pos_gender }}</h2>
              <span class="text-caption">de {{ registrations_stats.gender[gender] }}</span>
              <br>
            </v-col>
            <v-col class="text-center" v-if="type == 'individual' && r.category" cols="6" sm="3">
              <span class="text-caption text-capitalize" >Posición categoría</span>
              <h2>{{ r.result.pos_category }} {{r.category}}</h2>
              <span class="text-caption">de {{ registrations_stats.category[r.category] }}</span>
            </v-col>
          </v-row>

          <v-row no-gutters v-if="type == 'teams'">
            <v-col>
              <v-btn variant="text" size="small" color="primary" density="compact"
                     @click="participant_details = !participant_details"
                     :active="participant_details">
                Detalle participantes
                <v-icon size="x-small" end icon="fa-solid fa-minus" v-if="participant_details"></v-icon>
                <v-icon size="x-small" end icon="fa-solid fa-plus" v-else></v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="mt-0 pt-0">
            <v-col>

              <v-table density="compact" >
                <thead>
                <tr class="text-caption">
                  <td v-if="type == 'teams'"></td>
                  <td class="hidden-xs">Distancia</td>
                  <td class="hidden-xs" v-if="type == 'individual'">Tiempo</td>
                  <td>Puntos</td>
                  <td>Pos</td>
                  <td v-if="type == 'individual' && show_cat">Puntos cat</td>
                  <td v-if="type == 'individual'  && show_cat">Pos cat</td>
                  <td class="text-left">Carrera</td>
                </tr>
                </thead>
                <tbody>
                <template v-if="races" v-for="(race, idx, idxx) in races">
                  <tr class="text-center">
                    <td v-if="type == 'teams'">
                      <v-icon icon="fa-solid fa-circle-check" size="x-small" color="success" v-if="r.race_scores[idx]"></v-icon>
                      <v-icon icon="fa-solid fa-circle-xmark" size="x-small" color="error" v-else></v-icon>
                    </td>
                    <td class="text-caption hidden-xs">{{ $root.parse_distance(races_modalities[idx].distance, 1, 1) }}</td>
                    <td class="text-caption hidden-xs" v-if="type == 'individual'">
                      <router-link v-if="r.race_results[idx] && r.race_results[idx].total_time"
                                   :to="{name: 'IndividualResult', params: {result_id: r.race_registrations[idx].$oid}}" style="color: #1a222d">
                        <span>{{ $root.parse_time_from_seconds(r.race_results[idx].total_time) }}</span>
                        <v-icon size="x-small" icon="fa-solid fa-square-arrow-up-right"></v-icon>
                        <v-tooltip activator="parent" location="bottom">Ver resultado</v-tooltip>
                      </router-link>
                    </td>
                    <td><span v-if="r.race_results[idx]">{{ r.race_results[idx].score }}</span></td>
                    <td><span v-if="r.race_results[idx]">{{ r.race_results[idx].pos_gender }}</span></td>
                    <td v-if="type == 'individual' && show_cat"><span v-if="r.race_results[idx]">{{ r.race_results[idx].score_cat }}</span></td>
                    <td v-if="type == 'individual' && show_cat"><span v-if="r.race_results[idx]">{{ r.race_results[idx].pos_category }}</span></td>
                    <td class="text-caption text-no-wrap text-left">{{ race.name }}</td>
                  </tr>
                  <template v-if="type == 'teams' && participant_details">
                    <tr v-for="part in r.race_components[idx]" class="text-center" >
                      <td></td>
                      <td class="hidden-xs"></td>
                      <td v-if="type == 'individual'" class="text-caption">{{ participants[part.$oid].race_results[idx].score }}</td>
                      <td v-if="type == 'teams'" class="text-caption">
                        <span v-if="participants[part.$oid].race_results[idx].total_time">{{ $root.parse_time_from_seconds(participants[part.$oid].race_results[idx].total_time) }}</span>
                      </td>
                      <td class="text-caption">{{ participants[part.$oid].race_results[idx].pos_gender }}</td>
                      <td class="text-caption text-left font-italic">{{ participants[part.$oid].name }} {{ participants[part.$oid].surname }}</td>
                    </tr>
                  </template>
                </template>
                <tr class="font-weight-bold text-center">
                  <td v-if="type == 'teams'"></td>
                  <td class="hidden-xs"></td>
                  <td class="hidden-xs" v-if="type == 'individual'"></td>
                  <td>{{r.result.best_score}}</td>
                  <td></td>
                  <td v-if="type == 'individual' && show_cat"><span v-if="r.category">{{r.result.best_score_cat}}</span></td>
                  <td v-if="type == 'individual' && show_cat"></td>
                  <td class="text-left">Total mejores</td>
                </tr>
                </tbody>
              </v-table>

            </v-col>
          </v-row>


        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>

export default {
  name: "VallUixo5kDetail",
  props: [
      "circuit",
      "r",
      "participants",
      "races",
      "races_modalities",
      "type",
      "registrations_stats",
      "gender",
      "show_cat"
  ],
  data: () => ({
    participant_details: false,
  }),
  mounted() {

  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    is_admin: {
      get: function () {
        return this.$root.is_admin
      }
    },
    is_authenticated: {
      get: function () {
        return this.$root.is_authenticated
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    }
  }
}
</script>