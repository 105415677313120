<template>

  <v-parallax src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg" height="150px">
    <div class="d-flex flex-column fill-height justify-center align-center text-white">
      <h1 class="text-h4 font-weight-thin mb-4">
        <span v-if="english">Who we are</span>
        <span v-if="spanish">¿Quiénes somos?</span>
        <span v-if="valencian">Qui som?</span>
      </h1>
    </div>
  </v-parallax>

  <v-container fluid style="max-width: 680px" >

    <h2 v-if="spanish">Nuestra historia</h2>
    <h2 v-if="valencian">La nostra història</h2>
    <h2 v-if="english">Our history</h2>

    <p v-if="spanish" class="text-justify">Mychip es una empresa de cronometraje de evento deportivos y gestión de inscripciones fundada en 2012 en una pequeña localidad de València con el objetivo convertir cada evento deportivo en único. David, Susanna, Carles y Adela se unieron con un propósito claro: transformar cada evento deportivo en una experiencia única y emocionante.</p>
    <p v-if="valencian" class="text-justify">Mychip és una empresa de cronometratge d'esdeveniments esportius i gestió d'inscripcions fundada allà per 2012 en una petita localitat de València amb l'objectiu de convertir cada esdeveniment en únic. David, Susanna, Carles i Adela s'uniren amb un propòsit clar: transformar cada esdeveniment deporitu en una experiència única i emocionant.</p>
    <p v-if="english" class="text-justify">Mychip is a sports event timing and registration management company founded on 2012 in a small town nearby València with the aim of making every event unique. David, Susanna, Carles and Adela came together with a clear objective: to transform each event into a unique and exciting experience.</p>

    <h3 v-if="spanish" class="text-center mt-8 text-secondary">Sumando kilómetros juntos</h3>
    <h3 v-if="valencian" class="text-center mt-8 text-secondary">Sumant quilòmetres junts</h3>
    <h3 v-if="english" class="text-center mt-8 text-secondary">Adding kilometers together</h3>

    <h2 v-if="spanish" class="mt-8">Detrás de cada evento</h2>
    <h2 v-if="valencian" class="mt-8">Darrere de cada esdeveniment</h2>
    <h2 v-if="english" class="mt-8">Behind every event</h2>

    <p v-if="spanish" class="text-justify">En Mychip, la clave reside en la personalización, y esto es lo que realmente nos distingue. Es el ingrediente mágico que convierte cualquier evento en una realidad. Desde ajustar cada detalle crucial en el proceso de inscripciones para garantizar el éxito del evento, hasta la personalización de los servicios de cronometraje, como el seguimiento en directo y las metas volantes. En Mychip, la personalización no es solo una opción, es nuestro compromiso de convertir cada evento en una experiencia única.</p>
    <p v-if="valencian" class="text-justify">A Mychip, la clau està en la personalització, i açò és el que realment ens distingueix. És l'ingredient màgic que convertix qualsevol esdeveniment en una realitat. Des d'ajustar cada detall important en el procés d'inscripcions per a garantir l'èxit de l'esdeveniment, fins la personalització dels servicis de cronometratge, com el seguiment en directe i les metes volants. A Mychip, la persontalització no és tan sols una opció, és el nostre compromís de convertir cada esdeveniment en una experiència única.</p>
    <p v-if="english" class="text-justify">At Myhcip, customization is key, and this is what really sets us apart. It is the magical ingredient that converts any event into reality. From fine-tuning every detail in the registration process to ensure event success, up to customizing timing services like live tracking. At Mychip, customization is not just an option, it is our commitment to turning each event into a unique experience.</p>

    <h2 v-if="spanish" class="mt-8">Nuestra trayectoria</h2>
    <h2 v-if="valencian" class="mt-8">La nostra trajectòria</h2>
    <h2 v-if="english" class="mt-8">Our trajectory</h2>

    <p v-if="spanish" class="text-justify">Desde el nacimiento de nuestra empresa, hemos cronometrado eventos de renombre a nivel nacional. Desde el cronometraje de ligas nacionales de la FEDME hasta competiciones autonómicas como la FEMECV, y destacando en el escenario nacional con eventos emblemáticos como el 10K de Aranjuez. Sin embargo, lo que realmente nos llena es presenciar a cada participante superándose a sí mismo en cada evento en el que decide tomar parte. ¡Cada paso es una victoria, y estamos emocionados de ser parte de ese viaje!</p>
    <p v-if="valencian" class="text-justify">Des del naixement de la nostra empresa, hem cronometrat esdeveniments de gran importància a nivell nacional. Desde el cronometratge de les lligues nacionals de la FEDME fins a competicions autonòmiques com la FEMECV, i destacant en l'escenari nacional amb esdeveniments emblemàtics com el 10K d'Aranjuez. Però, el que realment ens satisfà és presenciar a cada participant superant-se a sí mateix en cada esdeveniment en el que decideix competir. Cada pas és una victòria, i estem emocionants de ser part d'eixe viatge!</p>
    <p v-if="english" class="text-justify">Since the birth of our company, we have timed nationally renowned events. From the timing of nationa FEDME leagues to regional competitions such as the FEMECV, and standing out of the national stage with emblematic events such as the Aranjuez 10K. However, what really fulfills us is witnessing each participant breaking its own limits in each event in which he or she decides to take part. Every step is a victory, and we are exicted to be a part of that journey!</p>

    <h3 v-if="spanish" class="text-center mt-8 text-secondary">¡Bienvenidos a Mychip, seguimos sumando kilómetros juntos!</h3>
    <h3 v-if="valencian" class="text-center mt-8 text-secondary">Benvinguts a Mychip, seguim sumant quilòmetres junts!</h3>
    <h3 v-if="english" class="text-center mt-8 text-secondary">Welcome to Mychip, we keep adding kilometers together!</h3>

    <h2 v-if="spanish" class="mt-8">Conoce al equipo</h2>
    <h2 v-if="valencian" class="mt-8">Coneix a l'equip</h2>
    <h2 v-if="english" class="mt-8">Meet the team</h2>

    <v-row>
      <v-col cols="12">
        <v-card
            class="mx-auto"
            title="David Talens">
          <template v-slot:subtitle>
            <v-chip-group column>
              <v-chip label size="small" variant="flat" class="mr-1">Gerente</v-chip>
              <v-chip label size="small" variant="flat" class="mr-1">Preparación de eventos</v-chip>
              <v-chip label size="small" variant="flat" class="mr-1">Cronometraje</v-chip>
            </v-chip-group>
          </template>
          <template v-slot:prepend>
            <v-avatar size="80">
              <v-img   src="https://mychip-prod-images.s3.eu-central-1.amazonaws.com/team/4c95517102a122909ffc06e9c5256624-sticker.PNG"></v-img>
            </v-avatar>
          </template>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card
            class="mx-auto"
            title="Susanna Sebastià">
          <template v-slot:subtitle>
            <v-chip-group column>
              <v-chip label size="small" variant="flat" class="mr-1">Preparación de eventos</v-chip>
              <v-chip label size="small" variant="flat" class="mr-1">Cronometraje</v-chip>
            </v-chip-group>
          </template>
          <template v-slot:prepend>
            <v-avatar size="80">
              <v-img   src="https://mychip-prod-images.s3.eu-central-1.amazonaws.com/team/c3a805ea4391c2e4110854a619eb5c89-sticker.PNG"></v-img>
            </v-avatar>
          </template>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card
            class="mx-auto"
            title="Adela Talens">
          <template v-slot:subtitle>
            <v-chip-group column>
              <v-chip label size="small" variant="flat" class="mr-1">Marketing</v-chip>
              <v-chip label size="small" variant="flat" class="mr-1">Redes sociales</v-chip>
              <v-chip label size="small" variant="flat" class="mr-1">Contabilidad</v-chip>
              <v-chip label size="small" variant="flat" class="mr-1">Cronometraje</v-chip>
            </v-chip-group>
          </template>
          <template v-slot:prepend>
            <v-avatar size="80">
              <v-img   src="https://mychip-prod-images.s3.eu-central-1.amazonaws.com/team/a46503bee696be23a8f1d3333732a87b-sticker.PNG"></v-img>
            </v-avatar>
          </template>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card
            class="mx-auto"
            title="Carles Talens">
          <template v-slot:subtitle>
            <v-chip-group column>
              <v-chip label size="small" variant="flat" class="mr-1">Desarrollo de software</v-chip>
              <v-chip label size="small" variant="flat" class="mr-1">Impresión de dorsales</v-chip>
              <v-chip label size="small" variant="flat" class="mr-1">Cronometraje</v-chip>
            </v-chip-group>
          </template>
          <template v-slot:prepend>
            <v-avatar size="80">
              <v-img   src="https://mychip-prod-images.s3.eu-central-1.amazonaws.com/team/471cd4baf96d2e26381aecf3dcc3adf3-sticker.PNG"></v-img>
            </v-avatar>
          </template>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card
            class="mx-auto"
            title="Mireia Esteve">
          <template v-slot:subtitle>
            <v-chip-group column>
              <v-chip label size="small" variant="flat" class="mr-1">Cronometraje</v-chip>
            </v-chip-group>
          </template>
          <template v-slot:prepend>
            <v-avatar size="80">
              <v-img   src="https://mychip-prod-images.s3.eu-central-1.amazonaws.com/team/mireia.jpeg"></v-img>
            </v-avatar>
          </template>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card
            class="mx-auto"
            title="Ulisses Ortiz">
          <template v-slot:subtitle>
            <v-chip-group column>
              <v-chip label size="small" variant="flat" class="mr-1">Impresión de dorsales</v-chip>
              <v-chip label size="small" variant="flat" class="mr-1">Cronometraje</v-chip>
            </v-chip-group>
          </template>
          <template v-slot:prepend>
            <v-avatar size="80">
              <v-img   src="https://mychip-prod-images.s3.eu-central-1.amazonaws.com/team/fc80cdaf2c3dc48cf75b72c0265e22c8-sticker.PNG"></v-img>
            </v-avatar>
          </template>
        </v-card>
      </v-col>

    </v-row>



  </v-container>

</template>

<script>
import {defineComponent} from 'vue';
import axios from "axios";
import router from "@/router";

export default defineComponent({
  name: 'CompanyView',
  data: () => ({
  }),
  computed: {
    english() {
      return this.$store.getters.language == 'en'
    },
    spanish() {
      return this.$store.getters.language == 'es'
    },
    valencian() {
      return this.$store.getters.language == 'ca'
    },
  }
});
</script>
