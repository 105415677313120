<template>

  <v-table density="compact">

    <thead>
    <tr>
      <th>Circuit</th>
      <th>Inicio</th>
      <th>Fin</th>
      <th>Registro</th>
      <th>Puntuacion</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="c in circuits">
      <td>
        <router-link :to="{name: 'AdminEvent', params: { event_id: c.slug }}" class="text-uppercase">{{ c.name_short || c.name }}</router-link>
      </td>
      <td>{{c.date_start}}</td>
      <td>{{c.date_end}}</td>
      <td>{{c.registration_type}}</td>
      <td>{{c.punctuation_type}}</td>
    </tr>
    </tbody>
  </v-table>
</template>
<script>
export default {
  name: "CircuitsTable",
  props: ["circuits"],
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    }
  }
};
</script>