import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {loadFonts} from './plugins/webfontloader'
import VueNumber from 'vue-number-animation'
import posthogPlugin from './plugins/posthog'
import i18n from './i18n'
import { vMaska } from "maska"
import VueQRCodeComponent from 'vue-qrcode-component'
import print from 'vue3-print-nb'

loadFonts()

let prod_mode = process.env.NODE_ENV === 'production'

if ( !prod_mode ) {
    console.log(`v${process.env.VUE_APP_VERSION} - ${process.env.NODE_ENV}`)
    console.log(`${process.env.VUE_APP_ROOT_API}`)
}

let app = createApp(App)
            .use(router)
            .use(store)
            .use(vuetify)
            .use(VueNumber)
            .use(print)
            .use(i18n).directive("maska", vMaska)

app.component('qr-code', VueQRCodeComponent)

if ( prod_mode ) {
    app.use(posthogPlugin)
}

app.mount('#app')
