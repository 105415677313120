<template>

  <v-container style="max-width: 960px;">

    <v-card>

      <v-toolbar color="primary">
        <v-toolbar-title>
          <span v-if="create">Crear organismo</span>
          <span v-else>Editar organismo</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn size="small" variant="text" stacked @click="save_organism">
            <v-icon size="small" icon="fa-solid fa-save"></v-icon>
            <span class="hidden-xs mt-1">Guardar</span>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-row>

          <v-col cols="12" sm="6">
            <v-text-field hide-details density="compact"
                          label="Nombre" variant="outlined" v-model="organism.name">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field hide-details density="compact"
                          label="Web" variant="outlined" v-model="organism.website">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field hide-details density="compact"
                          label="Logo (url)" variant="outlined" v-model="organism.logo">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-select v-if="constants" variant="outlined" density="compact"
                      hide-details
                      label="Tipo"
                      v-model="organism.type"
                      :items="constants.organizertype">
            </v-select>
          </v-col>

<!--          <v-col cols="12" sm="6">-->
<!--            <v-text-field hide-details density="compact"-->
<!--                          label="Teléfono" variant="outlined" v-model="organism.contact.phone">-->
<!--            </v-text-field>-->
<!--          </v-col>-->

<!--          <v-col cols="12" sm="6">-->
<!--            <v-text-field hide-details density="compact"-->
<!--                          label="e-mail" variant="outlined" v-model="organism.contact.email">-->
<!--            </v-text-field>-->
<!--          </v-col>-->

        </v-row>
      </v-card-text>

    </v-card>

    <template v-if="events">
      <h3>Eventos de este organismo</h3>
      <EventsTable :events="events"></EventsTable>
    </template>

  </v-container>

</template>

<script>
import {defineComponent} from 'vue';
import EventsTable from "@/components/EventsTable.vue";

export default defineComponent({
  name: 'AdminOrganism',
  components: {
    EventsTable
  },
  data: () => ({
    now: null,
    organism_id: null,
    organism: {
      contact: {}
    },
    create: true,
    events: null,
  }),
  created() {
    this.organism_id = this.$route.params.organism_id
    this.now = this.moment()
    document.title = "Edit Organism"
    console.log(this.organism_id)
    if ( this.organism_id != null && this.organism_id  != '0' ) {
      this.create = false
      this.get_organism()
    }
  },
  methods: {
    get_organism() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/organisms`,
          {
            "id": this.organism_id
          }
      ).then((response) => {
        this.organism = response.data[0]
        this.get_events()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_events() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/events`,
          {
            "organisms": [this.organism_id],
            "limit": 50,
          }
      ).then((response) => {
        this.events = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    save_organism() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/organism`,
          this.organism
      ).then((response) => {
        if (response.data) {
          this.$router.push({name: 'AdminOrganismsList'})
        }
      }).catch((error) => {
        console.error(error)
      })
    }
  },
  computed: {
    events() {
      return events
    },
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    },

    constants: {
      get: function () {
        return this.$root.$data.constants
      }
    },
  },
  watch: {
    page: {
      handler() {
        this.get_organisms()
      }
    },
  }
});
</script>
