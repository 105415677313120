<template>

  <v-container style="max-width: 960px;">

    <v-card>

      <v-toolbar color="primary">
        <v-toolbar-title>
          <span v-if="create">Crear usuario</span>
          <span v-else>Editar usuario</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn size="small" variant="text" stacked @click="save_user">
            <v-icon size="small" icon="fa-solid fa-save"></v-icon>
            <span class="hidden-xs mt-1">Guardar</span>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-row>

          <v-col cols="12" sm="4">
            <v-text-field hide-details density="compact"
                          label="Nombre" variant="outlined" v-model="user.name">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="8">
            <v-text-field hide-details density="compact"
                          label="Web" variant="outlined" v-model="user.surname">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field hide-details density="compact"
                          label="ID" variant="outlined" v-model="user.id_number">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field hide-details density="compact" type="date"
                          label="Fecha nacimiento" variant="outlined" v-model="user.birthdate">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-select v-if="constants" variant="outlined" density="compact"
                      hide-details
                      label="Género"
                      v-model="user.gender"
                      :items="constants.gender">
            </v-select>
          </v-col>

          <v-col cols="12" sm="3">
            <v-select v-if="constants" variant="outlined" density="compact"
                      hide-details
                      multiple
                      label="Roles"
                      v-model="user.role"
                      :items="constants.userrole">
            </v-select>
          </v-col>

          <v-col cols="12" sm="3">
            <v-switch v-model="user.public" color="primary" label="publico" hide-details></v-switch>
          </v-col>

          <v-col cols="12" sm="3">
            <v-switch v-model="user.is_fake" color="primary" label="Fake" hide-details></v-switch>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field hide-details density="compact" readonly disabled
                          label="Origen" variant="outlined" v-model="user.source">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field hide-details density="compact" type="date" disabled readonly
                          label="Creado" variant="outlined" v-model="user.created_at">
            </v-text-field>
          </v-col>

        </v-row>
      </v-card-text>

    </v-card>

    <template v-if="registrations">
      <h3>Inscripciones de este usuario</h3>
      <RegistrationsTable :registrations="registrations" :show_race="true" :show_user="false" :show_event_date="true"></RegistrationsTable>
    </template>

  </v-container>

</template>

<script>
import {defineComponent} from 'vue';
import RegistrationsTable from "@/components/RegistrationsTable.vue";

export default defineComponent({
  name: 'AdminUser',
  components: {
    RegistrationsTable,
  },
  data: () => ({
    now: null,
    user_id: null,
    user: {
      contact: {}
    },
    create: true,
    registrations: null,
  }),
  created() {
    this.user_id = this.$route.params.user_id
    this.now = this.moment()
    document.title = "Edit user"
    if ( this.user_id != null && this.user_id  != '0' ) {
      this.create = false
      this.get_user()
    }
  },
  methods: {
    get_user() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/users?t=${this.moment.now()}`,
          {
            "id": this.user_id,
          }
      ).then((response) => {
        this.user = response.data[0]
        this.user.created_at = this.moment.utc(this.user.created_at.$date).format('YYYY-MM-DD')
        if (Object.hasOwn(this.user, 'birthdate')) {
          this.user.birthdate = this.moment.utc(this.user.birthdate.$date).format('YYYY-MM-DD')
        }
        this.get_registrations()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_registrations() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations`,
          {
            "user": this.user_id,
            "limit": 50,
          }
      ).then((response) => {
        this.registrations = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    save_user() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/user`,
          this.user
      ).then((response) => {
        if (response.data) {
          this.$router.go()
        }
      }).catch((error) => {
        console.error(error)
      })
    }
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    },

    constants: {
      get: function () {
        return this.$root.$data.constants
      }
    },
  },
  watch: {
    page: {
      handler() {
        this.get_users()
      }
    },
  }
});
</script>
