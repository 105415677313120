<template>

  <v-container fluid class="fill-height" style="max-width: 480px">
    <v-row align="center" justify="center">
      <v-col class="">
        <v-card :loading="loading">
          <v-card-title>Log in</v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field prepend-inner-icon="fa-solid fa-envelope" v-model="email" label="E-mail" variant="outlined"></v-text-field>
              <v-text-field prepend-inner-icon="fa-solid fa-lock" v-model="password" label="Password" type="password" variant="outlined"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions >
            <v-btn @click="submit" block variant="elevated" color="primary">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {defineComponent} from 'vue';
import axios from "axios";
import router from "@/router";

export default defineComponent({
  name: 'LogInView',
  data: () => ({
    email: null,
    password: null,
    loading: false,
  }),
  created() {
  },
  methods: {
    submit(){
      this.loading = true
      axios.post(`${process.env.VUE_APP_ROOT_API}/login?email=${this.email}&password=${this.password}`).then((response) => {
        this.$store.commit('changeToken', {new_token: response.data['token']})
        this.$store.commit('changeRole', {new_role: response.data['role']})
        this.$store.commit('changeName', {new_name: response.data['name']})
        this.loading = false
        if (this.is_admin) {
          router.push({name: 'Admin'})
        } else {
          router.push({name: 'Home'})
        }
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    }
  },

  components: {},

  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    is_admin: {
      get: function () {
        return this.$root.is_admin
      }
    },
    is_authenticated: {
      get: function () {
        return this.$root.is_authenticated
      }
    }
  }

});
</script>
