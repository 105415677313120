<template>

  <v-container style="max-width: 960px;">

    <v-row>
      <v-col><h1>Publicidades</h1></v-col>
      <v-col class="text-right"><v-btn class="mb-2" color="primary" :to="{name: 'AdminAd', params: { ad_id: 0 }}">Nuevo anuncio</v-btn></v-col>
    </v-row>
    <v-text-field v-model="filter_name" density="compact" clearable append-inner-icon="fa-solid fa-magnifying-glass"
                  @click:append-inner="get_ads" @click:clear="get_ads" @keydown.enter="get_ads"></v-text-field>
    <v-progress-linear v-if="loading_ads" class="mt-4" indeterminate :height="12">Ads</v-progress-linear>

    <template v-if="!loading_ads">
      <template v-if="ads">
        <v-table density="compact">
          <thead>
          <tr>
            <th></th>
            <th>Nombre</th>
            <th>Imagen</th>
            <th>Orden</th>
            <th>Website</th>
            <th>Evento</th>
            <th>Fechas</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="ad in ads">
            <td>
              <v-chip  label color="success" size="small">Activo</v-chip>
              <v-chip label color="error" size="small">Desactivado</v-chip>
            </td>
            <td>
              <router-link :to="{name: 'AdminAd', params: { ad_id: ad._id.$oid }}">{{ad.name}}</router-link>
            </td>
            <td><v-img :src="ad.image" height="25px"></v-img></td>
            <td>{{ad.weight}}</td>
            <td class="text-caption">{{ad.redirect}}</td>
            <td>{{ad.event}}</td>
            <td>
              <span v-if="ad.date_start">desde {{moment.utc(ad.date_start.$date).format('DD/MM/YYYY')}}</span>
              <span v-if="ad.date_end"> hasta {{moment.utc(ad.date_end.$date).format('DD/MM/YYYY')}}</span>
            </td>
          </tr>
          </tbody>
        </v-table>
        <v-pagination
            v-model="page"
            total-visible="4">
        </v-pagination>
      </template>
      <v-alert v-else type="info">No ads were found</v-alert>
    </template>


  </v-container>

</template>

<script>
import {defineComponent} from 'vue';
import Calendar from '../components/Calendar.vue'

export default defineComponent({
  name: 'AdminAdsList',
  components: {
    Calendar,
  },
  data: () => ({
    now: null,
    loading_ads: true,
    ads: null,
    page: 1,
    page_size: 12,
    filter_name: "",
  }),
  created() {
    this.now = this.moment()
    document.title = "Admin Ads"
    this.get_ads()

  },
  methods: {
    get_ads() {
      let filters = {
        "offset": this.page_size * (this.page - 1),
        "limit": this.page_size,
      }
      if ( this.filter_name !== null && this.filter_name.length > 0 ) {
        filters["name"] = this.filter_name
      }
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/ads`,
          filters
      ).then((response) => {
        this.ads = response.data
        this.loading_ads = false
      }).catch((error) => {
        console.error(error)
      })
    }
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    }
  },
  watch: {
    page: {
      handler() {
        this.get_events()
      }
    },
  }
});
</script>
