<template>

  <v-table density="compact">

    <thead>
    <tr>
      <th></th>
      <th></th>
      <th>Evento</th>
      <th>Fecha</th>
      <th>Donde</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="e in events">
      <td>
        <v-chip size="x-small" label>{{ $t(`constants.event_status.${e.status}`) }}</v-chip>
      </td>
      <td>{{ e.numeric_id }}</td>
      <td>
        <router-link :to="{name: 'AdminEvent', params: { event_id: e.slug }}" class="text-uppercase">{{ e.name_short || e.name }}</router-link>
        <v-chip class="ml-1" size="x-small" label variant="tonal" v-for="t in e.tags">{{t}}</v-chip>
        <v-chip label size="x-small" class="ml-1" v-if="e.modalities">
          {{ e.modalities.length }}
          <v-tooltip activator="parent">
            <div v-for="m in e.modalities" class="text-uppercase text-caption">{{ m.name }}</div>
          </v-tooltip>
        </v-chip>
        <br>
        <router-link v-if="e.circuit" :to="{name: 'AdminEvent', params: { event_id: e.circuit.slug }}" class="ml-1 text-caption text-uppercase">{{ e.circuit.name }}</router-link>
      </td>
      <td><v-chip label size="small" v-if="e.date_start">{{ moment(e.date_start.$date).format("DD/MM/YYYY")}}</v-chip></td>
      <td><span v-if="e.location && e.location.city" class="text-caption">{{ e.location.city }}</span></td>
      <td>
          <span v-if="registrations_stats && registrations_stats[e._id.$oid]" class="text-caption">
            {{registrations_stats[e._id.$oid].total}}<span v-if="e.registration_limit">/{{ e.registration_limit }}</span>
            <v-progress-linear v-if="e.registration_limit" :model-value="registrations_stats[e._id.$oid].total/e.registration_limit*100"></v-progress-linear>
          </span>
      </td>
    </tr>
    </tbody>
  </v-table>
</template>
<script>
export default {
  name: "EventsTable",
  props: ["events", "registrations_stats"],
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    }
  }
};
</script>