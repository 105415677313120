<template>

  <v-container style="max-width: 960px;">

    <v-row>
      <v-col><h1>Usuarios</h1></v-col>
    </v-row>

    <v-text-field v-model="filter_name" density="compact" clearable append-inner-icon="fa-solid fa-magnifying-glass"
                  @click:append-inner="get_users" @click:clear="get_users" @keydown.enter="get_users"
    ></v-text-field>

    <v-progress-linear v-if="loading_users" class="mt-4" indeterminate :height="12">Usuarios</v-progress-linear>

    <v-row class="mb-0" no-gutters>
      <v-col class="mb-0 pb-0">
        <v-switch class="ma-0 pa-0" density="compact" v-model="filter_fake" label="Incluir fake" @click="get_users"
                  color="primary"></v-switch>
      </v-col>
      <v-col class="text-right">
        <v-chip label variant="outlined" size="small">mostrando <span v-if="stats">{{ stats.total }}</span>/<span
            v-if="stats_no_filter">{{ stats_no_filter.total }}</span></v-chip>
      </v-col>
    </v-row>
    <template v-if="!loading_users">
      <template v-if="users">
        <v-table>
          <thead>
          <tr>
            <th></th>
            <th>Usuario</th>
            <th>ID</th>
            <th>Contacto</th>
            <th>F. Nac.</th>
            <th>G</th>
            <th>Fake?</th>
            <th>source</th>
            <th></th>
            <th>Creado</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="u in users">
            <td>
              <v-avatar size="small" v-if="u.avatar"
                        :image="u.avatar"></v-avatar>
            </td>
            <td class="text-no-wrap">
              <router-link :to="{name: 'AdminUser', params: { user_id: u._id.$oid }}" class="text-uppercase">
                {{ u.name }}<br><b class="text-uppercase">{{ u.surname }}</b>
              </router-link>
            </td>
            <td>
              <v-chip label size="small">{{ u.id_number }}</v-chip>
            </td>
            <td>
              <template v-if="u.contact">
                <v-chip size="x-small" label v-if="u.contact.email">{{ u.contact.email }}</v-chip>
                <v-chip size="x-small" label v-if="u.contact.phone">{{ u.contact.phone }}</v-chip>
              </template>
            </td>
            <td>
              <span class="text-caption" v-if="u.birthdate">{{ moment(u.birthdate.$date).format("DD/MM/YYYY") }}</span>
            </td>
            <td>
              <span class="text-caption">
                {{ u.gender == 'male' ? 'M' : u.gender == 'female' ? ' F' : '-'  }}
              </span>
            </td>
            <td>
              <v-chip label color="error" size="x-small" v-if="u.is_fake">FAKE</v-chip>
              <v-chip label color="success" size="x-small" v-else>REAL</v-chip>
            </td>
            <td>
              <v-chip label size="x-small" v-if="u.source">{{ u.source }}</v-chip>
            </td>
            <td>
              <v-chip v-for="r in u.role" label size="x-small">{{ r }}</v-chip>
            </td>
            <td>
              <span class="text-caption">
                {{ moment.utc(u.created_at.$date).format('DD/MM/YY') }}
                <v-tooltip activator="parent" location="bottom">
                  hace {{ moment.duration(this.now.diff(moment(u.created_at.$date))).humanize() }}
                </v-tooltip>
              </span>
            </td>
          </tr>
          </tbody>
        </v-table>
        <v-pagination
            v-if="stats"
            v-model="page"
            :length="1 + parseInt(stats.total/page_size)"
            total-visible="4"
        ></v-pagination>
      </template>
      <v-alert v-else type="info">No users were found</v-alert>
    </template>

  </v-container>

</template>

<script>
import {defineComponent} from 'vue';
import Calendar from '../components/Calendar.vue'
import EventsTable from "@/components/EventsTable.vue";

export default defineComponent({
  name: 'AdminEventsList',
  components: {
    Calendar,
    EventsTable,
  },
  data: () => ({
    now: null,
    loading_users: true,
    users: null,
    stats_no_filter: null,
    stats: null,
    page: 1,
    page_size: 8,
    filter_name: "",
    filter_fake: false,
  }),
  created() {
    this.now = this.moment()
    document.title = "Users"
    this.get_users()
    this.get_users_stats_no_filter()
  },
  methods: {
    parse_filters() {
      let filters = {
        "offset": this.page_size * (this.page - 1),
        "limit": this.page_size,
        "order": ["-created_at"]
      }
      if (!this.filter_fake) {
        filters["fake"] = false
      }
      if (this.filter_name !== null && this.filter_name.length > 0) {
        filters["name"] = this.filter_name
      }
      return filters
    },
    get_users() {
      this.loading_users = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/users`,
          this.parse_filters()
      ).then((response) => {
        this.users = response.data
        this.loading_users = false
        this.get_users_stats()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_users_stats() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/users/stats`,
          this.parse_filters()
      ).then((response) => {
        this.stats = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    get_users_stats_no_filter() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/users/stats`,
          {}
      ).then((response) => {
        this.stats_no_filter = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    }
  },
  watch: {
    page: {
      handler() {
        this.get_users()
      }
    },
  }
});
</script>
