<template>
  <template v-if="loading_event">
    <v-progress-linear indeterminate :height="12" v-if="loading_event"></v-progress-linear>
  </template>
  <template v-else>

    <v-breadcrumbs color="" bg-color="primary" density="compact" v-if="event_data" >

      <v-breadcrumbs-item v-if="!event_data.is_circuit" :to="{ name: 'Event', params: { event_id: event_data.slug }}"
                          class="d-inline-block text-truncate">
        {{ event_data.name_short || event_data.name }}
      </v-breadcrumbs-item>

      <v-breadcrumbs-item v-else :to="{ name: 'Circuit', params: { circuit_id: event_data.slug }}"
                          class="d-inline-block text-truncate">
        {{ event_data.name_short || event_data.name }}
      </v-breadcrumbs-item>

      <v-breadcrumbs-divider></v-breadcrumbs-divider>

      <v-breadcrumbs-item>Inscripción</v-breadcrumbs-item>

    </v-breadcrumbs>

    <v-sheet>
      <v-img class="w-100" cover lazy-src="https://mychip-dev-images.s3.eu-central-1.amazonaws.com/posters/default.png"
             :src="event_data.poster"
             height="150px" gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,1)">
      </v-img>

      <!--      <router-link :to="{name: 'Event', params: {event_id: this.event_id}}">-->
      <!--      </router-link>-->
      <h1 class="text-center text-uppercase text-no-wrap text-truncate">
        {{ event_data.name }}
      </h1>

    </v-sheet>

    <v-container style="max-width: 960px;">


      <h1>Inscripción</h1>

      <v-progress-linear indeterminate :height="6" v-if="loading_iframe"></v-progress-linear>
      <div id ='dframe' style='width:100%; max-width:100%'></div>


    </v-container>

  </template>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'EventRegister',
  components: {},
  data: () => ({
    now: null,
    loading_event: true,
    loading_iframe: true,
    event_id: null,
    event_data: null,
  }),
  created() {
    let jquery = document.createElement('script')
    jquery.setAttribute('src', 'https://code.jquery.com/jquery-3.7.0.min.js')
    document.head.appendChild(jquery)
    let rts_script = document.createElement('script')
    rts_script.setAttribute('src', 'https://rockthesportv2.blob.core.windows.net/scripts/pym.js')
    document.head.appendChild(rts_script)
    this.event_id = this.$route.params.event_id
    this.now = this.moment()
    this.get_event_data()
  },
  methods: {
    get_event_data() {
      this.loading_event = true
      let url = `${process.env.VUE_APP_ROOT_API}/events`
      this.axios.post(url, {
        "slug": this.event_id,
        "search_circuits": true,
      }).then((response) => {
        this.event_data = response.data[0]
        document.title = `${this.event_data.name} - ${this.$i18n.t('page_titles.register')}`
        this.loading_event = false
        this.load_iframe_rts()
      }).catch((error) => {
        console.error(error)
      }).finally(() => {

      })
    },
    load_iframe_rts() {
      this.loading_iframe = true
      setTimeout(() => {
        let iframe = document.createElement('script')
        iframe.type = 'text/javascript'
        var code = `var pymParent = new pym.Parent('dframe', 'https://inscripciones.mychip.es/es/evento/${this.event_data.slug_rts}/inscripcion/selecciona-tarifa?frm=1&men=0&tit=1&stop=0', {})`
        iframe.appendChild(document.createTextNode(code))
        document.body.appendChild(iframe)
        this.loading_iframe = false
      }, 1500)
    }
  },
  watch: {},
  computed: {
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    },
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    is_admin: {
      get: function () {
        return this.$root.is_admin
      }
    },
  }
});
</script>
