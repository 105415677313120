<template>
  <div>
    <v-card rounded="10" max-width="60px" class="text-center mt-2 mb-2">
      <v-sheet color="primary" class="text-caption">{{ moment(date).format("ddd") }}</v-sheet>
      <h3 class="ma-0 pa-0">{{ moment(date).format("D") }}</h3>
      <v-sheet color="" class="text-caption ma-0 pa-0">{{ moment(date).format("MMM YYYY") }}</v-sheet>
      <v-tooltip activator="parent" location="bottom" class="font-weight-thin font-italic text-caption ma-0 pa-0">{{ moment(date).from(moment.now()) }}</v-tooltip>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "Calendar",
  props: ["date"],
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    }
  }
};
</script>