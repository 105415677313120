<template>

    <template v-if="loading">
      <v-progress-linear indeterminate :height="12" v-if="loading"></v-progress-linear>
    </template>
    <template v-else>

    <v-container fluid style="max-width: 960px">

      <h1>Circuitos</h1>


      <template v-if="circuits !== null">
        <template v-if="circuits">
          <v-row>
            <v-col cols="12" v-for="c in circuits">
              <EventCardV2 :e="c"></EventCardV2>
            </v-col>
          </v-row>
          <v-pagination
              v-if="circuits_stats !== null"
              v-model="page"
              :length="1 + parseInt(circuits_stats.total/page_size)"
              total-visible="4">
          </v-pagination>
        </template>
        <v-alert v-else type="info">No circuits were found</v-alert>
      </template>

    </v-container>
  </template>

</template>

<script>
import {defineComponent} from 'vue';
import EventCard from "@/components/EventCard.vue";
import EventCardV2 from "@/components/EventCardV2.vue";
import CircuitCard from "@/components/CircuitCard.vue";

export default defineComponent({
  name: 'CircuitList',
  components: {
    EventCard,
    EventCardV2,
    CircuitCard,
  },
  data: () => ({
    now: null,
    loading: true,
    circuits: null,
    circuits_stats: null,
    page: 1,
    page_size: 12,
  }),
  created() {
    this.now = this.moment.utc()
    document.title = 'Circuits'
    this.get_circuits()
  },
  methods: {
    parse_filters() {
      let filters = {
        "offset": this.page_size * (this.page - 1),
        "limit": this.page_size,
      }
      return filters
    },
    get_circuits() {
      this.circuits_stats = null
      this.loading = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/circuits`,
          this.parse_filters()
      ).then((response) => {
        this.circuits = response.data
        this.loading = false
        this.get_circuits_stats()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_circuits_stats() {
      this.circuits_stats = null
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/circuits/stats`,
          this.parse_filters()
      ).then((response) => {
        this.circuits_stats = response.data
        this.loading = false
      }).catch((error) => {
        console.error(error)
      })
    }
  },

  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    is_admin: {
      get: function () {
        return this.$root.is_admin
      }
    },
    is_authenticated: {
      get: function () {
        return this.$root.is_authenticated
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    }
  },

  watch: {
    page: {
      handler() {
        this.get_circuits()
      }
    }
  }

});
</script>
