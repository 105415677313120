<template>
  <div>
  <v-text-field :readonly="readonly" hide-details="true" density="compact" label="Nombre" variant="outlined" >
<!--    <template #append-inner v-if="readonly">-->
<!--      <div style="width:30px">-->
<!--        <v-img style="margin: auto 0" max-height="25" max-width="30" src="@/assets/rts-icon.png"/>-->
<!--      </div>-->
<!--    </template>-->
  </v-text-field>
  {{data}}
  </div>
</template>
<script>
export default {
  name: "Field",
  props: ["model", "data", "readonly"],
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    models: {
      get: function () {
        return this.$root.$data.models
      }
    },
    constants: {
      get: function () {
        return this.$root.$data.constants
      }
    },
    fields_from_rts: {
      get: function () {
        return this.$root.$data.fields_from_rts
      }
    },
    invariants: {
      get: function () {
        return this.$root.$data.invariants
      }
    },
  }
};
</script>