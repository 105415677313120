<template>
  <template v-if="loading">
    <v-progress-linear indeterminate :height="12" v-if="loading"></v-progress-linear>
  </template>

  <template v-else>

    <v-container style="max-width: 960px;">

      <v-system-bar v-if="event_data && event_data.is_circuit" color="accent">
        Editando circuito
        <v-spacer></v-spacer>
      </v-system-bar>


      <template v-if="registration_stats">
        <v-expand-transition>
          <v-row class="mb-2">
            <v-col v-if="registration_stats.total" cols="12">
              <v-card>
                <v-progress-linear v-if="event_data && event_data.registration_limit" color="primary" height="8"
                                   :model-value="100*registration_stats.total/event_data.registration_limit"></v-progress-linear>
                <v-card-text class="text-center">
                  <h2>
                    <number :from="0" :to="registration_stats.total" :duration="1" easing="Power1.easeOut"/>
                    <span v-if="event_data && event_data.registration_limit"
                          class="text-caption">/{{ event_data.registration_limit }}</span>
                  </h2>
                  inscritos
                  <v-table density="compact" class="hidden-xs">
                    <tbody>
                    <template v-if="event_data && registration_stats.modalities"
                              v-for="(m, idx) in event_data.modalities">
                      <tr>
                        <td width="1%" class="text-uppercase text-caption text-no-wrap">{{ m.name_short || m.name }}
                        </td>
                        <td>
                          <v-progress-linear height="10" class="text-caption text-uppercase"
                                             v-if="registration_stats.modalities[m._id.$oid]"
                                             :model-value="(100*registration_stats.modalities[m._id.$oid]/registration_stats.total)">
                          </v-progress-linear>
                          <v-progress-linear height="10" class="text-caption text-uppercase mt-2"
                                             color="primary"
                                             v-if="registration_stats.modalities[m._id.$oid] && modalities && modalities[idx] && modalities[idx].registration_limit"
                                             :model-value="(100*registration_stats.modalities[m._id.$oid]/modalities[idx].registration_limit)">
                          </v-progress-linear>
                        </td>
                        <td width="1%" class="text-caption text-no-wrap">
                          <span>{{ registration_stats.modalities[m._id.$oid] }}/{{ registration_stats.total }} total</span>
                          <br>
                          <span
                              v-if="modalities[idx].registration_limit">{{ registration_stats.modalities[m._id.$oid] }}/{{ modalities[idx].registration_limit }} limit</span>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </v-table>
                </v-card-text>
              </v-card>
            </v-col>

            <template v-if="['live', 'finished_provisional', 'finished_official'].includes(event_data.status)">
              <v-col v-if="registration_stats.status && registration_stats.status.took_start > 0">
                <v-card>
                  <v-card-text class="text-center">
                    <h2>
                      <number :from="0" :to="registration_stats.status.took_start" :duration="1"
                              easing="Power1.easeOut"/>
                    </h2>
                    empiezan
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col v-if="registration_stats.status && registration_stats.status.finished > 0">
                <v-card>
                  <v-card-text class="text-center">
                    <h2>
                      <number :from="0" :to="registration_stats.status.finished" :duration="1" easing="Power1.easeOut"/>
                    </h2>
                    acaban
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col v-if="registration_stats.status && registration_stats.status.retired > 0">
                <v-card>
                  <v-card-text class="text-center">
                    <h2>
                      <number :from="0" :to="registration_stats.status.retired" :duration="1" easing="Power1.easeOut"/>
                    </h2>
                    abandonan
                  </v-card-text>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-expand-transition>
      </template>
      <template v-else>
        <v-progress-linear indeterminate height="12">Estadísticas inscripciones</v-progress-linear>
      </template>


      <v-sheet v-if="event_data" color="transparent" class="d-flex">
        <h1 class="text-center text-uppercase text-no-wrap text-truncate">{{
            event_data.name_short || event_data.name
          }}</h1>
        <v-spacer></v-spacer>
        <v-chip label size="" class="mt-2 mb-2 pl-2 pr-2">
          <v-icon size="small" :icon="$root.get_icon_from_status(event_data.status)" start></v-icon>
          <span class="text-uppercase">{{ $t(`constants.event_status.${event_data.status}`) }}</span>
        </v-chip>
      </v-sheet>

      <v-card>

        <v-toolbar :color="event_data.is_circuit ? '' : 'primary'">
          <v-btn size="small" variant="text" stacked :disabled="is_modified_event()"
                 v-if="event_data.status == 'live' && !finish_event_confirm" @click="finish_event_confirm = true">
            <v-icon size="small" icon="fa-solid fa-flag-checkered"></v-icon>
            <span class="mt-1">Finalizar</span>
            <v-tooltip activator="parent">
              <b>Finalizar el evento</b>
              <br>
              Cambiará el estado del evento (todas las modalidades) a finalizado
            </v-tooltip>
          </v-btn>
          <v-btn size="small" variant="text" stacked v-if="finish_event_confirm" @click="finish_event_confirm = false">
            <span class="mt-1">Cancelar</span>
          </v-btn>
          <v-btn size="small" variant="text" stacked class="bg-error" v-if="finish_event_confirm"
                 @click="finalize_all_modalities">
            <span class="mt-1">Finalizar</span>
          </v-btn>
          <v-toolbar-title>{{ event_data.numeric_id }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>


            <v-dialog fullscreen scrim
                      transition="dialog-bottom-transition" scrollable
                      width="100%">

              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" size="small" variant="text" stacked>
                  <v-icon size="small" icon="fa-solid fa-qrcode"></v-icon>
                </v-btn>
              </template>

              <template v-slot:default="{ isActive }">

                <v-toolbar color="primary">
                  <v-toolbar-title>Generar código QR</v-toolbar-title>
                  <v-toolbar-items>
<!--                    <v-btn @click="print_qr">-->
<!--                      <v-icon icon="fa-solid fa-print"></v-icon>-->
<!--                    </v-btn>-->
                    <v-btn v-print="'#print_qr'">
                      <v-icon icon="fa-solid fa-print"></v-icon>
                    </v-btn>
                    <v-btn @click="isActive.value = false" icon>
                      <v-icon icon="fa-solid fa-times"></v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>

                <v-card>
                  <v-card-text>
                    <v-row class="bg-white">
                      <v-col cols="12">
                        <v-text-field v-model="qr_text_top" hide-details="true" density="compact" label="Texto arriba"
                                      variant="outlined"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" offset-sm="4" class="text-center">
                        {{
                          void (url = `https://mychip.es${this.$router.resolve({
                            name: 'Event',
                            params: {event_id: event_data.slug},
                          }).href}?utm_source=poster_qr&utm_campaing=${event_data.numeric_id}`)
                        }}
                        <v-card border class="border-dashed">
                        <v-table id="print_qr" class="text-center">
                          <thead>
                          <tr><td class="text-pre-line"><h1>{{ event_data.name_short || event_data.name }}</h1></td></tr>
                          <tr><td class="text-pre-line"><h2>{{ qr_text_top }}</h2></td></tr>
                          </thead>
                          <tbody><tr><td class="qr_container"><qr-code id="qr_itself" class="justify-center d-flex align-center" :text="url" error-level="L"></qr-code></td></tr></tbody>
                          <tfoot><tr><td class="text-pre-line"><h2>{{ qr_text_bottom }}</h2></td></tr></tfoot>
                        </v-table>
                        </v-card>
                            <!--                        <v-responsive-->
<!--                            id="print_qr"-->
<!--                            :aspect-ratio="21/29"-->
<!--                            width="100%"-->
<!--                            class="border border-opacity-100 pa-4 text-center">-->
<!--                          <h1>{{ event_data.name_short || event_data.name }}</h1>-->
<!--                          <br>-->
<!--                          <h2>{{ qr_text_top }}</h2>-->
<!--                          <v-row align="center"-->
<!--                                 justify="center">-->
<!--                            <v-col class="text-center">-->
<!--                              <qr-code id="qr_itself" class="justify-center d-flex align-center" :text="url"-->
<!--                                       error-level="L"></qr-code>-->
<!--                            </v-col>-->
<!--                          </v-row>-->
<!--                          <h2 class="text-pre-line">{{ qr_text_bottom }}</h2>-->
<!--                        </v-responsive>-->
                      </v-col>
                      <v-col cols="12">
                        <v-textarea v-model="qr_text_bottom" hide-details="true" density="compact" label="Texto abajo"
                                    variant="outlined"></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

              </template>
            </v-dialog>


            <v-btn size="small" variant="text" stacked
                   :to="{name: 'AdminRegistrationsList', params: {event_id: event_data._id.$oid}}">
              <v-icon size="small" icon="fa-solid fa-users"></v-icon>
              <v-tooltip location="bottom" activator="parent" class="hidden-xs">Inscripciones</v-tooltip>
            </v-btn>
            <v-btn size="small" variant="text" stacked :disabled="is_modified_event()"
                   :href="`https://app.rockthesport.com/es/es/master/managementevent/${event_data.rts_numeric_id}`"
                   target="_blank">
              <v-icon size="small" icon="fa-solid fa-edit"></v-icon>
              <v-tooltip location="bottom" activator="parent" class="hidden-xs">Editar en RTS</v-tooltip>
            </v-btn>


            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn size="small" variant="text" stacked :disabled="is_modified_event()"
                       v-bind="props" :loading="updating_rts"
                       v-if="!this.$root.$data.past_statuses.includes(event_data.status)"
                       target="_blank">
                  <v-icon size="small" icon="fa-solid fa-download"></v-icon>
                </v-btn>
              </template>

              <v-list density="compact">
                <v-list-subheader>Evento y modalidades</v-list-subheader>
                <!--                <v-list-subheader>últ. actualización: {{ moment.utc(event_data.rts_last_import.$date).format('DD/MM HH:mm') }}</v-list-subheader>-->
                <v-list-item @click="update_rts('events', false)" color="error">Actualizar evento (light)</v-list-item>
                <v-list-item @click="update_rts('events', true)" color="error">Actualizar evento (force)</v-list-item>
                <v-list-subheader>Inscripciones</v-list-subheader>
                <v-list-item @click="update_rts('registrations', false)" color="error">Actualizar registros (light)
                </v-list-item>
                <v-list-item disabled @click="update_rts('registrations', true)" color="error">Actualizar registros
                  (force)
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn :loading="saving_event" size="small" variant="text" stacked @click="save_event"
                   :disabled="!is_modified_event()">
              <v-icon v-if="saved_event" size="small" color="success" icon="fa-solid fa-circle-check"></v-icon>
              <v-icon v-else size="small" icon="fa-solid fa-save" color="warning"></v-icon>
              <v-tooltip location="bottom" activator="parent" class="hidden-xs">Guardar</v-tooltip>
            </v-btn>
            <v-btn size="small" variant="text" stacked v-if="event_data"
                   :to="event_data.is_circuit ? {name: 'Circuit', params: {'circuit_id': event_data.slug}} : {name: 'Event', params: {'event_id': event_data.slug}}">
              <v-icon size="small" icon="fa-solid fa-eye"></v-icon>
              <v-tooltip location="bottom" activator="parent" class="hidden-xs">Ver parte pública</v-tooltip>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>


        <v-tabs v-model="tab" color="primary" grow bg-color="">
          <v-tab value="info">
            Info
          </v-tab>
          <v-tab value="location">
            Localizacion
          </v-tab>
          <v-tab value="registrations">
            Inscripciones
          </v-tab>
          <v-tab value="organisms">
            Organismos
          </v-tab>
          <v-tab value="documents">
            Documentos
          </v-tab>
          <v-tab value="tech">
            Tech
          </v-tab>
        </v-tabs>

        <v-window disabled v-model="tab">
          <v-window-item value="info" class="pa-4">

            <v-row>
              <v-col cols="12" sm="8">
                <v-text-field :readonly="readonly" hide-details="true" density="compact" label="Nombre"
                              variant="outlined" v-model="event_data.name">
                  <template #append-inner v-if="readonly">
                    <div style="width:30px">
                      <v-img style="margin: auto 0" max-height="25" max-width="30" src="@/assets/rts-icon.png"/>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field hide-details="true" density="compact" label="Nombre corto" variant="outlined"
                              v-model="event_data.name_short"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" sm="3">
                <v-select v-if="constants" hide-details="true" variant="outlined" density="compact"
                          disabled
                          label="Estado"
                          v-model="event_data.status"
                          :items="constants.status">
                </v-select>
              </v-col>
              <v-col cols="6" sm="3">
                <v-text-field type="number" hide-details="true" density="compact" label="Orden" variant="outlined"
                              v-model="event_data.order"></v-text-field>
              </v-col>
              <v-col cols="4" sm="2">
                <v-switch hide-details="true" density="compact" label="Visible" v-model="event_data.visible"
                          color="primary"></v-switch>
              </v-col>
              <v-col cols="4" sm="2">
                <v-switch hide-details="true" density="compact" label="Featured" v-model="event_data.featured"
                          color="primary"></v-switch>
              </v-col>
              <v-col cols="4" sm="2">
                <v-switch hide-details="true" density="compact" label="External" v-model="event_data.external"
                          color="primary"></v-switch>
              </v-col>
            </v-row>


            <v-row>
              <v-col>
                <v-text-field disabled type="date" hide-details="true" density="compact"
                              label="Fecha inicio" variant="outlined" v-model="event_data.date_start">

                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field disabled type="date" hide-details="true" density="compact" label="Fecha fin"
                              variant="outlined"
                              v-model="event_data.date_end" :min="event_data.date_start"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="8">
                <v-combobox
                    variant="outlined"
                    density="compact"
                    v-model="event_data.tags"
                    hide-selected
                    label="Tags"
                    chips
                    multiple
                    hide-details
                    small-chips
                    closable-chips>
                </v-combobox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select label="Cronometradores" v-if="timers" multiple chips
                          variant="outlined" density="compact" hide-details
                          v-model="event_data.timers" :items="timers" item-title="name" item-value="_id">
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-combobox
                    variant="outlined"
                    density="compact"
                    v-model="event_data.rrss_hashtags"
                    hide-selected
                    hide-details
                    label="Hashtags redes sociales"
                    chips
                    multiple
                    :items="['#running', '#trailRunning', '#21k', '#10k']"
                    small-chips
                    closable-chips>
                </v-combobox>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete v-if="!event_data.is_circuit" clearable v-model="event_data.circuit"
                                v-model:search="search_circuits_text" @update:search="get_circuits"
                                density="compact" hide-details label="Circuito al que pertenece"
                                variant="filled" bg-color="primary"
                                :items="circuits" item-value="_id" item-title="name"></v-autocomplete>
              </v-col>
            </v-row>

          </v-window-item>


          <v-window-item value="location" class="pa-4">
            <v-row>
              <v-col>
                <v-text-field :disabled="readonly" hide-details="true" density="compact" label="Lugar del evento"
                              variant="outlined"></v-text-field>
              </v-col>
              <v-col cols="auto">
                <v-btn :disabled="readonly" variant="outlined" v-on:click="get_location_from_str()">Buscar</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col offset="1">
                <template v-if="event_data.location">
                  <div v-if="event_data.location.street">{{ event_data.location.street }}</div>
                  <div>{{ event_data.location.city }}, {{ event_data.location.postcode }}</div>
                  <div>{{ event_data.location.region }}, {{ event_data.location.county }}
                    ({{ event_data.location.county_iso }})
                  </div>
                  <div>{{ event_data.location.state }} ({{ event_data.location.state_iso }})</div>
                  <div>{{ event_data.location.country }} ({{ event_data.location.country_iso }})</div>
                </template>

              </v-col>
            </v-row>
          </v-window-item>

          <v-window-item value="registrations" class="pa-4">

            <v-row v-if="!event_data.is_circuit">
              <v-col cols="12" sm="4">
                <v-text-field type="date" hide-details="true" density="compact" disabled
                              label="Inicio inscripciones" variant="outlined" v-model="event_data.registration_start">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field type="date" hide-details="true" density="compact" disabled
                              label="Fin inscripciones" variant="outlined" v-model="event_data.registration_end">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field type="number" hide-details="true" density="compact"
                              label="Límite inscritos" variant="outlined" v-model="event_data.registration_limit">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-checkbox hide-details density="compact" v-model="event_data.show_registration_list" disabled
                            label="Mostrar listado inscritos"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-checkbox hide-details density="compact" v-model="event_data.show_total_registrations" disabled
                            label="Mostrar total inscritos"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-checkbox hide-details density="compact" v-model="event_data.show_registrations_by_modality" disabled
                            label="Permitir serparar por modalidad"></v-checkbox>
              </v-col>

              <v-col cols="12" sm="4">
                <v-checkbox hide-details density="compact" v-model="event_data.allow_team_registration"
                            label="Habilitar creación de equipos"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field hide-details variant="outlined" density="compact"
                              v-model="event_data.team_registration_name"
                              :disabled="!event_data.allow_team_registration" label="Nombre del boton"></v-text-field>
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col cols="12" sm="6">
                <v-select v-model="event_data.registration_type" variant="outlined" density="compact"
                          label="Tipo de registro"
                          :items="constants.circuitregistrations" hide-details="true"></v-select>
              </v-col>

              <v-col cols="12" sm="6">
                <v-select v-model="event_data.punctuation_type" variant="outlined" density="compact"
                          label="Tipo de puntuacion"
                          :items="constants.circuitpunctuations" hide-details="true"></v-select>
              </v-col>
            </v-row>

          </v-window-item>

          <v-window-item value="organisms" class="pa-4">
            <template v-if="event_data.organisms">
              <template v-for="o in event_data.organisms">
                <v-chip class="mr-1" pill link size="small" closable @click:close="remove_organism(o._id.$oid)">
                  <v-avatar start v-if="o.logo">
                    <v-img :src="o.logo" cover></v-img>
                  </v-avatar>
                  {{ o.name }}
                </v-chip>
              </template>
            </template>
            <template v-else>
              No organisms added for this event
            </template>
            <v-card variant="outlined" class="ma-2">
              <v-card-title>Add more organisms</v-card-title>
              <v-card-text>
                <v-text-field v-model="typed_organism" @input="fetch_organisms"
                              :loading="loading_organisms"
                              variant="outlined" density="compact"
                              hint="minim 3 characters"
                              clearable
                              @click:clear="available_organisms = []"
                              label="Search organism">
                </v-text-field>
                <v-progress-circular v-if="loading_organisms" indeterminate></v-progress-circular>
                <template v-if="!loading_organisms">
                  <template v-if="available_organisms.length > 0">
                    <template v-for="(o, idx) in available_organisms">
                      <v-chip :disabled="contains_organism(o._id.$oid)" class="mr-1" label size="small"
                              @click="add_organism(idx)">{{ o.name }}
                      </v-chip>
                    </template>
                  </template>
                </template>
              </v-card-text>
            </v-card>
          </v-window-item>

          <v-window-item value="documents" class="pa-4">
            <v-table density="compact">
              <thead>
              <tr>
                <th>Nombre</th>
                <th>Tipo</th>
                <th>Enlace</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(d, idx) in event_data.documents">
                <td>
                  <v-combobox v-model="d.name" variant="outlined" density="compact"
                              label="Nombre document" hide-details="true"
                              :items="['Reglamento', 'Autorización menores']"></v-combobox>
                </td>
                <td>
                  <v-select v-model="d.type" variant="outlined" density="compact"
                            :items="['result', 'registration']" hide-details="true"></v-select>
                </td>
                <td><a :href="d.url" target="_blank">Link</a></td>
                <td class="text-right">
                  <v-btn variant="text" color="error" size="small" v-if="delete_documents_confirm === -1"
                         @click="delete_documents_confirm = idx">
                    <v-icon icon="fa-solid fa-trash"></v-icon>
                  </v-btn>
                  <template v-if="delete_documents_confirm === idx">
                    <v-btn variant="text" color="error" size="small" @click="delete_document(idx)">Eliminar</v-btn>
                    <v-btn variant="text" size="small" @click="delete_documents_confirm = -1">Cancelar</v-btn>
                  </template>
                </td>
              </tr>
              </tbody>
            </v-table>

            <v-card variant="outlined" class="mt-2" :disabled="uploading_new_document"
                    :loading="uploading_new_document">
              <v-card-title>Añadir documento</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-combobox v-model="new_document_model.name" variant="outlined" density="compact"
                                label="Nombre document" hide-details="true"
                                :items="['Reglamento', 'Autorización menores']"></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-select v-model="new_document_model.type" variant="outlined" density="compact"
                              :disabled="new_document_model.name.length == 0"
                              label="Tipo documento" :items="['result', 'registration']" hide-details="true"></v-select>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-select v-model="new_document_file_type" variant="outlined" density="compact"
                              :disabled="new_document_model.name.length == 0 || new_document_model.type.length == 0"
                              label="Tipo archivo" :items="['url', 'upload']" hide-details="true"></v-select>
                  </v-col>
                  <v-col cols="12" v-if="new_document_file_type !== null">
                    <v-file-input @input="encode_file"
                                  v-if="new_document_file_type == 'upload'"
                                  variant="outlined" density="compact" label="elige documento"
                                  hide-details="true"></v-file-input>
                    <v-text-field variant="outlined" density="compact" v-else append-icon="fa-solid fa-add"
                                  @click:append="add_document" v-model="new_document_model.url"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>

            </v-card>
          </v-window-item>

          <v-window-item value="tech" class="pa-4">

            <v-card border variant="outlined">
              <v-card-text>


                <v-row>
                  <v-col>
                    <v-alert type="warning" icon="fa-solid fa-triangle-exclamation">
                      <v-alert-title>Zona de peligro</v-alert-title>
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="3" v-if="!event_data.is_circuit">
                    <v-switch hide-details v-model="event_data.allow_parse_json" label="Permitir MDG json"
                              color="error"></v-switch>
                    <span class="text-caption">Permite parsear json fuera de plazo.</span>
                  </v-col>
                  <v-col cols="12" sm="3" v-if="!event_data.is_circuit">
                    <v-switch hide-details v-model="event_data.importing_mdg" label="Importando MDG json"
                              color="error"></v-switch>
                    <span class="text-caption">Indica si el evento esta parseando el json. Desactívelo para desbloquearlo manualmente.</span>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-switch hide-details v-model="event_data.rts_importing_registrations" label="Importando RTS"
                              color="error"></v-switch>
                    <span class="text-caption">Indica si el evento esta importando registros desde RTS. Desactívelo para desbloquearlo manualmente.</span>
                    <span class="text-caption font-italic" v-if="event_data.rts_last_import_registrations"><br>Última importación: {{
                        moment.utc(event_data.rts_last_import_registrations.$date).format('DD/MM HH:mm:ss')
                      }}</span>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <span>Última sincronización de atributos con el evento en RTS:</span>
                    <span class="text-caption font-italic" v-if="event_data.rts_last_import"> {{
                        moment.utc(event_data.rts_last_import.$date).format('DD/MM HH:mm:ss')
                      }}</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="3">
                    <v-checkbox hide-details v-model="event_data.is_circuit" label="Es circuito"
                                color="error"></v-checkbox>
                    <span class="text-caption">Indica si este evento es un circuito o un evento normal</span>
                  </v-col>

                  <v-col cols="12" sm="3" v-if="event_data.is_circuit">
                    <v-btn color="tertiary" @click="score_circuit" :loading="scoring_circuit" block>Puntuar circuito</v-btn>
                    <span class="text-caption">Ejecuta en segundo plano la puntuación del circuito acorde con los parámetros configurados (pestanya inscripciones)</span>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>

          </v-window-item>
        </v-window>


        <v-progress-linear indeterminate v-if="loading_modalities"></v-progress-linear>
        <template v-if="modalities">
          <v-card>
            <v-card-title>Modalidades</v-card-title>
            <v-tabs grow color="primary" center-active density="compact" show-arrows v-model="tab_modalities"
                    :disabled="saving_modality">
              <v-tab v-for="m in modalities" :value="m._id.$oid" :max-width="150" class="">
                <span class="text-no-wrap text-truncate pa-1" style="max-width: 150px">
                  <span v-if="m.distance">{{ parseInt(m.distance / 1000) }}K: </span>{{ m.short_name || m.name }}
                </span>
              </v-tab>
            </v-tabs>
          </v-card>

          <v-window disabled v-model="tab_modalities">

            <v-window-item v-for="(m, idx) in modalities" :value="m._id.$oid" class="pa-4">

              <v-card color="">

                <v-toolbar :color="event_data.is_circuit ? 'tertiary' : 'secondary'">

                  <v-btn size="small" variant="text" stacked v-if="m.status == 'live' && !finish_modality_confirm"
                         @click="finish_modality_confirm = true">
                    <v-icon size="small" icon="fa-solid fa-flag-checkered"></v-icon>
                    <span class="mt-1">Finalizar</span>
                    <v-tooltip activator="parent">
                      <b>Finalizar la modalidad</b>
                      <br>
                      Cambiará el estado de la modalidad a finalizado
                    </v-tooltip>
                  </v-btn>
                  <v-btn size="small" variant="text" stacked v-if="finish_modality_confirm"
                         @click="finish_modality_confirm = false">
                    <span class="mt-1">Cancelar</span>
                  </v-btn>
                  <v-btn size="small" variant="text" stacked class="bg-error" v-if="finish_modality_confirm"
                         @click="finalize_modality(idx)">
                    <span class="mt-1">Finalizar</span>
                  </v-btn>

                  <v-toolbar-title>{{ event_data.numeric_id }}_{{ m.numeric_id }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items class="">

                    <v-btn size="small" variant="text" stacked :to="{name: 'AdminReports', params: {modality_id: m._id.$oid}}">
                      <v-icon size="small" icon="fa-solid fa-print"></v-icon>
                      <span class="hidden-xs mt-1">Informes</span>
                    </v-btn>


                    <v-dialog transition="dialog-bottom-transition" width="50%">



                      <template v-slot:activator="{ props }">
                        <v-btn size="small" variant="text" stacked v-bind="props" @click="token.current_modality = m.numeric_id">
                          <v-icon size="small" icon="fa-solid fa-id-card-clip"></v-icon>
                          <span class="hidden-xs mt-1">Token MDG</span>
                        </v-btn>
                      </template>

                      <template v-slot:default="{ isActive }">
                        <v-card>

                          <v-toolbar
                              color="primary"
                              title="Generar tokens"
                          >
                            <v-toolbar-items>
                              <v-btn @click="isActive.value = false" icon>
                                <v-icon icon="fa-solid fa-times"></v-icon>
                              </v-btn>
                            </v-toolbar-items>
                          </v-toolbar>

                          <v-card-text>
                            <v-row>
                              <v-col cols="12">
                                <h5>Descalificacion</h5>
                              </v-col>
                              <v-col cols="3">
                                <v-checkbox label="Descalificado" v-model="token.dsq" hide-details density="compact"></v-checkbox>
                              </v-col>
                              <v-col cols="9">
                                <v-combobox hide-details variant="outlined"
                                            :disabled="!token.dsq"
                                            v-model="token.dsq_reason"
                                            label="Motivo descalificación"
                                            :items="['Participar con el dorsal de otra persona', 'Participar en una modalidad distinta a la que se ha inscrito sin autorización previa']"
                                            density="compact"></v-combobox>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="12">
                                <h5>Penalizaciones</h5>
                              </v-col>
                            </v-row>

                            <v-row v-for="(pen, idx) in token.penalizations">
                              <v-col cols="3">
                                <v-text-field hide-details variant="outlined" type="number"
                                              v-model="token.penalizations[idx].seconds"
                                              label="Segundos"
                                              density="compact"></v-text-field>
                              </v-col>
                              <v-col cols="7">
                                <v-combobox hide-details variant="outlined"
                                              v-model="token.penalizations[idx].reason"
                                              label="Motivo"
                                            :items="['Tarjeta amarilla', 'Tarjeta roja', 'Hacer caso omiso de las indicaciones de los jueces o voluntarios']"
                                              density="compact"></v-combobox>
                              </v-col>
                              <v-col cols="2">
                                <v-btn block variant="elevated"
                                       @click="token.penalizations.splice(idx, 1)"
                                       color="error">Eliminar</v-btn>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="5">
                                <v-btn block variant="elevated" size="small"
                                       @click="token.penalizations.push({...new_penalization_template})"
                                       color="primary">Añadir penalización</v-btn>
                              </v-col>
                            </v-row>


                            <v-row>
                              <v-col cols="12">
                                <v-textarea density="compact"
                                            append-inner-icon="fa-solid fa-copy"
                                            @click:append-inner="copy_generated_token"
                                    hide-details variant="outlined" readonly
                                    v-model="computed_token"></v-textarea>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </template>
                    </v-dialog>

                    <v-btn size="small" variant="text" stacked
                           :to="{name: 'AdminResultsList', params: {modality_id: m._id.$oid}}">
                      <v-icon size="small" icon="fa-solid fa-list-ol"></v-icon>
                      <span class="hidden-xs mt-1">Resultados</span>
                    </v-btn>
                    <v-btn :loading="saving_modality" size="small" variant="text" stacked @click="save_modality(idx)"
                           :disabled="!is_modified_modality(idx)">
                      <v-icon v-if="saved_modality" size="small" color="success"
                              icon="fa-solid fa-circle-check"></v-icon>
                      <v-icon v-else size="small" icon="fa-solid fa-save" color="warning"></v-icon>
                      <span class="hidden-xs mt-1">Guardar</span>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>

                <v-row class="pa-4">

                  <v-col cols="12" v-if="event_data.circuit && event_circuit">
                    <v-progress-linear indeterminate height="12" v-if="!event_circuit">Cargando modalidades del
                      circuito
                    </v-progress-linear>
                    <v-select v-else :items="event_circuit.modalities" item-title="name" item-value="_id"
                              v-model="m.circuit_modality" bg-color="primary" clearable
                              hide-details density="compact" variant="filled" label="Modalidad del circuito"></v-select>
                  </v-col>

                  <v-col cols="12" sm="8">
                    <v-text-field v-model="m.name" hide-details="true" density="compact" label="Nombre"
                                  variant="outlined">
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field v-model="m.short_name" hide-details="true" density="compact" label="Nombre corto"
                                  variant="outlined"></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field v-model="m.description" hide-details="true" density="compact" label="Descripción"
                                  variant="outlined"></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-combobox
                        variant="outlined"
                        density="compact"
                        v-model="m.rrss_hashtags"
                        hide-selected
                        hide-details
                        label="Hashtags redes sociales"
                        chips
                        multiple
                        :items="['#running', '#trailRunning', '#21k', '#10k']"
                        small-chips
                        closable-chips>
                    </v-combobox>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-select v-if="constants" hide-details="true" variant="outlined" density="compact"
                              disabled
                              label="Estado"
                              v-model="m.status"
                              :items="constants.status">
                    </v-select>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-select v-if="constants" hide-details="true" variant="outlined" density="compact"
                              label="Tipo de deporte"
                              v-model="m.sport"
                              :items="constants.sporttype">
                    </v-select>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-select v-if="constants" hide-details="true" variant="outlined" density="compact"
                              label="Tipo de cronometro"
                              v-model="m.type"
                              :items="constants.chronotype">
                    </v-select>
                  </v-col>

                  <v-col cols="4" sm="4">
                    <v-text-field v-model="m.distance" type="number" hide-details="true" density="compact"
                                  label="Distancia (m)"
                                  variant="outlined"></v-text-field>
                  </v-col>

                  <v-col cols="4" sm="4">
                    <v-text-field v-model="m.slope" type="number" hide-details="true" density="compact"
                                  label="Desnivel (m+)"
                                  variant="outlined"></v-text-field>
                  </v-col>

                  <v-col cols="4" sm="4">
                    <v-text-field v-model="m.price" type="number" hide-details="true" density="compact"
                                  label="Precio (€)"
                                  variant="outlined"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="8">
                    <v-text-field type="date" hide-details="true" density="compact"
                                  label="Fecha inicio" variant="outlined" v-model="m.datetime_start_date">
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field type="time" hide-details="true" density="compact"
                                  label="Hora inicio" variant="outlined" v-model="m.datetime_start_time">
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="8">
                    <v-text-field type="date" hide-details="true" density="compact" :min="m.datetime_start_date"
                                  label="Fecha fin (cierre)" variant="outlined" v-model="m.datetime_end_date">
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field type="time" hide-details="true" density="compact"
                                  label="Hora fin (cierre)" variant="outlined" v-model="m.datetime_end_time">
                    </v-text-field>
                  </v-col>

                  <v-col cols="6" sm="3">
                    <v-text-field v-model="m.numeric_id" type="number" hide-details="true" density="compact"
                                  label="Id numerico" disabled
                                  variant="outlined"></v-text-field>
                  </v-col>

                  <v-col cols="6" sm="3">
                    <v-text-field v-model="m.order" type="number" hide-details="true" density="compact" label="Orden"
                                  variant="outlined"></v-text-field>
                  </v-col>

                  <v-col cols="6" sm="3">
                    <v-switch hide-details="true" density="compact" label="Visible" v-model="m.visible"
                              color="primary"></v-switch>
                  </v-col>

                  <v-col cols="6" sm="3">
                    <v-switch hide-details="true" density="compact" label="Cronometrada" v-model="m.is_timed"
                              color="primary"></v-switch>
                  </v-col>

                  <v-col cols="6" sm="4">
                    <v-text-field v-model="m.registration_limit" type="number" hide-details="true" density="compact"
                                  label="Límite inscritos"
                                  variant="outlined"></v-text-field>
                  </v-col>

                  <v-col cols="6" sm="4">
                    <v-btn @click="select_color_modality = !select_color_modality" block :color="m.bib_color">
                      Color dorsal
                      <v-icon end icon="fa-solid fa-crosshairs"></v-icon>
                    </v-btn>


                    <v-expand-transition>

                      <v-color-picker
                          v-if="select_color_modality"
                          v-model="m.bib_color"
                          class="ma-2"
                          show-swatches
                          hide-inputs
                          hide-sliders
                          swatches-max-height="400px">

                      </v-color-picker>

                    </v-expand-transition>

                  </v-col>

                  <v-col cols="6" sm="4">
                    <v-switch hide-details="true" density="compact" label="Classificacion tiempo real"
                              v-model="m.positions_by_real_time"
                              disabled color="primary"></v-switch>
                  </v-col>

                  <v-col cols="12">
                    <v-card variant="outlined">
                      <v-card-text>
                        <v-row>

                          <v-col cols="12">
                            <v-checkbox density="compact" label="Cambiar para todas las modalidades" hide-details="true"
                                        v-model="modality_change_registration_datetime_all"></v-checkbox>
                          </v-col>

                          <v-col cols="12" sm="8">
                            <v-text-field type="date" hide-details="true" density="compact"
                                          label="Fecha inicio inscripciones" variant="outlined"
                                          v-model="m.registration_start_date">
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" sm="4">
                            <v-text-field type="time" hide-details="true" density="compact"
                                          label="Hora inicio inscripciones" variant="outlined"
                                          v-model="m.registration_start_time">
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" sm="8">
                            <v-text-field type="date" hide-details="true" density="compact"
                                          label="Fecha fin inscripciones" variant="outlined"
                                          v-model="m.registration_end_date">
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" sm="4">
                            <v-text-field type="time" hide-details="true" density="compact"
                                          label="Hora fin inscripciones" variant="outlined"
                                          v-model="m.registration_end_time">
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12">
                    <v-row>
                      <v-col cols="auto">
                        <h3>Lugar de salida:</h3>

                        <span v-if="JSON.stringify(m.location_start) == JSON.stringify(event_data.location)">Mismo que el evento</span>
                        <template v-else>
                          <span>
                            Independiente para la modalidad
                            <v-btn size="x-small">
                              Reestablecer
                            </v-btn>
                            <v-tooltip activator="parent" location="bottom">
                              {{ m.location_start }}
                            </v-tooltip>
                          </span>
                        </template>
                      </v-col>
                      <v-col class="text-right">

                        <v-dialog scrim
                                  transition="dialog-bottom-transition" scrollable
                                  width="100%">

                          <template v-slot:activator="{ props }">
                            <v-btn variant="tonal" size="small"
                                   @click="location_query = null"
                                   v-bind="props">
                              Modificar
                              <v-icon end icon="fa-solid fa-edit"></v-icon>
                            </v-btn>
                          </template>

                          <template v-slot:default="{ isActive }">
                            <v-card>
                              <v-toolbar color="primary" title="Lugar de salida para la modalidad">
                              </v-toolbar>

                              <v-card-text>
                                <v-row>
                                  <v-col>
                                    <v-text-field label="Buscar localización"
                                                  hide-details density="compact"
                                                  v-model="location_query"
                                                  @click:append-inner="get_location_from_str"
                                                  @keydown.enter="get_location_from_str"
                                                  append-inner-icon="fa-solid fa-magnifying-glass">
                                    </v-text-field>
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col>
                                    {{ location_found }}
                                  </v-col>
                                </v-row>

                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="location_query = null ; isActive.value = false">Cancelar</v-btn>
                                <v-btn :disabled="!location_found" color="primary"
                                       @click="m.location_start = location_found ; isActive.value = false">Asignar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>


                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-row>
                      <v-col cols="auto"><h3>Recorrido y puntos de control</h3></v-col>
                      <v-col class="text-right">
                        <v-btn variant="tonal" size="small"
                               @click="current_intermediate = JSON.parse(JSON.stringify(new_intermediate_model)); current_modality = idx; add_new_intermediate_modal = true">
                          NUEVO
                          <v-icon end icon="fa-solid fa-circle-plus"></v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>


                    <v-table density="compact">
                      <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Distancia (m)</th>
                        <th>Altitud (m)</th>
                        <th>Cronometrado</th>
                        <th>Servicios extra</th>
                        <!--                          <th>Cierre</th>-->
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(i, intermediate_idx) in m.intermediates">
                        <td>{{ i.location.custom_name }}</td>
                        <td>{{ $root.parse_distance(i.distance_from_start, true, true) }}</td>
                        <td>{{ i.height }}</td>
                        <td>
                          {{ (i.timed) ? 'Sí' : 'No' }}
                          <v-btn class="ml-1" size="x-small" v-if="i.timed && i._id"
                                 :to="{name: 'AdminReaderConfiguration', params: { intermediate_id: i._id.$oid }}"
                                 :color="(i._id.$oid in reader_configurations) ? 'success' : ''">
                            <v-icon icon="fa-solid fa-circle-check" v-if="i._id.$oid in reader_configurations"></v-icon>
                            <v-icon v-else icon="fa-solid fa-cog"></v-icon>
                          </v-btn>
                        </td>
                        <td>{{ (i.extra_services) ? i.extra_services.length : 0 }}</td>
                        <!--                          <td>{{ i.datetime_end }}</td>-->
                        <td>
                          <template
                              v-if="confirm_remove_intermediate === false || confirm_remove_intermediate !== intermediate_idx">
                            <v-btn variant="elevated" size="small"
                                   @click="editing_intermediate = intermediate_idx; current_modality = idx;  current_intermediate = {...i}; add_new_intermediate_modal = true;">
                              <v-icon icon="fa-solid fa-edit"></v-icon>
                              <v-tooltip activator="parent" location="bottom">Editar</v-tooltip>
                            </v-btn>
                            <v-btn color="error" size="small" variant="elevated"
                                   @click="confirm_remove_intermediate = intermediate_idx">
                              <v-icon icon="fa-solid fa-trash"></v-icon>
                              <v-tooltip activator="parent" location="bottom">Eliminar</v-tooltip>
                            </v-btn>
                          </template>
                          <v-btn-group density="compact" rounded="3" variant="outlined"
                                       v-if="confirm_remove_intermediate === intermediate_idx">
                            <v-btn variant="outlined" size="small" color=""
                                   @click="confirm_remove_intermediate = false">
                              Cancelar
                            </v-btn>
                            <v-btn variant="outlined" size="small" color="error"
                                   @click="remove_intermediate(idx, intermediate_idx)">
                              Eliminar
                            </v-btn>
                          </v-btn-group>
                        </td>
                      </tr>
                      </tbody>
                    </v-table>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <h3>Categorías</h3>
                    <v-card density="compact" border >
                      <v-card-text>
                        Cálculo categorías MDG
                      <v-row>
                        <v-col><v-switch label="Año natural" color="primary"
                                         @click="category_calc_date = (!category_calc_natural_year) ? moment(category_calc_date).endOf('year').format('YYYY-MM-DD') : moment(event_data.date_start).format('YYYY-MM-DD')"
                                         v-model="category_calc_natural_year" hide-details density="compact"></v-switch></v-col>
                        <v-col><v-text-field :disabled="category_calc_natural_year" variant="outlined" type="date" label="Fecha de referencia" v-model="category_calc_date" hide-details density="compact"></v-text-field></v-col>
                      </v-row>
                      </v-card-text>
                    </v-card>

                    <v-table density="compact">
                      <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Género</th>
                        <th>Desde</th>
                        <th>Hasta</th>
<!--                        <th>Año natural?</th>-->
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="c in m.categories">
                        <td>{{ c.name }}</td>
                        <td>{{ c.gender }}</td>
                        <td>
                          {{ c.age_from }}
                          <br>
                          <span class="text-caption">
                            {{ moment(category_calc_date).subtract(c.age_to + 1, 'years').add(1, 'days').format('DD/MM/YYYY') }}
                          </span>
                        </td>
                        <td>
                          {{ c.age_to }}
                          <br>
                          <span class="text-caption">
                            {{ moment(category_calc_date).subtract(c.age_from, 'years').format('DD/MM/YYYY') }}
                          </span>
                        </td>
<!--                        <td>{{ c.natural_year }}</td>-->
                      </tr>
                      </tbody>
                    </v-table>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <h3>Precios</h3>
                    <v-table density="compact">
                      <thead>
                      <tr>
                        <th>Desde</th>
                        <th>Hasta</th>
                        <th>Precio</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="p in m.price_history">
                        <td>{{ moment(p.start.$date).format('DD/MM HH:mm') }}</td>
                        <td>{{ moment(p.end.$date).format('DD/MM HH:mm') }}</td>
                        <td>{{ p.price }}</td>
                      </tr>
                      </tbody>
                    </v-table>
                  </v-col>

                  <v-col cols="12">
                    <v-card variant="outlined">
                      <v-card-text>
                        <v-row>

                          <v-col cols="12">
                            <v-checkbox density="compact" label="Cambiar para todas las modalidades" hide-details="true"
                                        v-model="modality_change_extra_services_all"></v-checkbox>
                          </v-col>
                          <v-col cols="12">
                            <v-select v-if="constants" variant="outlined" density="compact"
                                      multiple chips
                                      label="Servicios extra en linea de meta"
                                      v-model="m.extra_services"
                                      :items="constants.intermediateextraservicestype">
                            </v-select>
                          </v-col>
                        </v-row>

                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12">
                    <v-card variant="outlined">
                      <v-card-title>Track</v-card-title>
                      <v-card-text>
                        <v-file-input @input="upload_gpx($event, modality=m, name='gpx_track')"
                                      :disabled="m.gpx_track != null"
                                      variant="outlined" density="compact" label="subir track .gpx"
                                      hide-details="true"></v-file-input>
                        <v-progress-linear indeterminate height="12" v-if="uploading_gpx">Subiendo...
                        </v-progress-linear>
                        <v-alert type="error" title="Error subiendo track"
                                 text="Asegúrese de subir un fichero .gpx válido" v-if="upload_gpx_error"></v-alert>
                        <template v-if="m.gpx_track">
                          <v-row no-gutters>
                            <v-col>
                              <v-switch :disabled="m.distance == null" hide-details v-model="m.gpx_use_own_distance"
                                        color="primary" label="Usar distancia GPX"></v-switch>
                            </v-col>
                            <v-col>
                              <v-switch hide-details v-model="m.gpx_elevation_profile_show_names" color="primary"
                                        label="Mostrar nombres en perfil"></v-switch>
                            </v-col>
                          </v-row>
                          <MychipMap class="mt-4" style="height: 250px; width: 100%" :show_map="false"
                                     :modality="m"></MychipMap>
                        </template>
                      </v-card-text>
                      <v-card-actions v-if="m.gpx_track">
                        <v-spacer></v-spacer>
                        <v-btn variant="elevated" color="error" v-if="!delete_track_confirm"
                               @click="delete_track_confirm = true">Eliminar track
                        </v-btn>
                        <v-btn variant="elevated" color="error" v-if="delete_track_confirm" @click="m.gpx_track = null">
                          Eliminar
                        </v-btn>
                        <v-btn variant="elevated" v-if="delete_track_confirm" @click="delete_track_confirm = false">
                          Cancelar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>

                  <v-divider></v-divider>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="3">
                        <v-switch hide-details v-model="m.calculating_positions" label="Calculando posiciones"
                                  color="error"></v-switch>
                      </v-col>
                    </v-row>
                  </v-card-text>


                </v-row>

              </v-card>
            </v-window-item>
          </v-window>
        </template>


      </v-card>


    </v-container>

    <v-dialog v-model="add_new_intermediate_modal" width="500">
      <v-card>
        <v-card-title>
          Añadir nuevo intermedio
        </v-card-title>
        <v-card-text>
          <template v-if="current_intermediate">
            <v-text-field variant="outlined" density="compact" label="Nombre"
                          v-model="current_intermediate.location.custom_name"></v-text-field>
            <v-text-field variant="outlined" density="compact" type="number" label="Distancia desde salida (m)"
                          v-model="current_intermediate.distance_from_start"></v-text-field>
            <v-text-field variant="outlined" density="compact" type="number" label="Altitud (m)"
                          v-model="current_intermediate.height"></v-text-field>
            <v-switch density="compact" label="Cronometrado" color="primary"
                      v-model="current_intermediate.timed"></v-switch>
            <!--            <v-text-field variant="outlined" type="date" density="compact" :min="modalities[current_modality].datetime_start_date" label="Fecha cierre" v-model="current_intermediate.datetime_end_date"></v-text-field>-->
            <!--            <v-text-field variant="outlined" type="time" density="compact" :min="modalities[current_modality].datetime_start_time" label="Fecha cierre" v-model="current_intermediate.datetime_end_time"></v-text-field>-->
            <v-select v-if="constants" variant="outlined" density="compact"
                      multiple chips
                      label="Servicios extra"
                      v-model="current_intermediate.extra_services"
                      :items="constants.intermediateextraservicestype">
            </v-select>

            <!--            datetime_end = fields.DateTimeField() -->
            <!--            is_finish = fields.BooleanField(default=False) -->

          </template>
          <template v-else>
            No intermediate found
          </template>
        </v-card-text>
        <v-card-actions class="text-right">
          <v-btn @click="add_new_intermediate_modal = false; current_intermediate = null; current_modality = null;">
            Cancelar
          </v-btn>
          <v-btn :disabled="current_intermediate == null || !current_intermediate.distance_from_start > 0"
                 @click="add_or_modify_intermediate">
            <span v-if="editing_intermediate !== null">Modificar</span>
            <span v-else>Añadir</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </template>
</template>

<style>
.qr_container img {
  width: 100%;
}
</style>

<script>
import {defineComponent} from 'vue';
import MarqueeText from 'vue-marquee-text-component'
import Field from '../components/Field.vue'
import RegistrationsTable from "@/components/RegistrationsTable.vue";
import MychipMap from "@/components/MychipMap.vue";
import QrCode from "vue-qrcode-component";
import router from "../router";

export default defineComponent({
  name: 'AdminEvent',
  components: {
    QrCode,
    MarqueeText,
    Field,
    RegistrationsTable,
    MychipMap,
  },
  data: () => ({
    now: null,
    tab: "info",
    finish_event_confirm: false,
    finish_modality_confirm: false,
    saving_event: false,
    saved_event: false,
    saving_modality: false,
    saved_modality: false,
    loading: true,
    event_id: null,
    modality_selected: null,
    event_data: null,
    original_event_data: null,
    tab_modalities: "info",
    loading_modalities: true,
    modalities_datetime_fields: ['datetime_start', 'datetime_end', 'registration_start', 'registration_end'],
    modalities: {},
    original_modalities: {},
    modality_change_registration_datetime_all: false,
    modality_change_extra_services_all: false,
    modality_change_status_all: false,
    readonly: true,
    select_color_modality: false,
    delete_documents_confirm: -1,
    location_query: null,
    location_found: null,
    locations: {
      event_start: null,
      event_end: null,
    },
    available_organisms: [],
    typed_organism: "",
    loading_organisms: false,
    original_new_document_model: {
      name: "",
      type: "",
      url: ""
    },
    new_document_model: null,
    uploading_new_document: false,
    new_document_file_type: null,
    new_intermediate_model: {
      location: {
        custom_name: ""
      },
      timed: true,
    },
    add_new_intermediate_modal: null,
    current_modality: null,
    current_intermediate: null,
    editing_intermediate: null,
    confirm_remove_intermediate: false,
    filter_registration_name: null,
    reader_configurations: {},
    timers: [],
    registration_stats: null,
    updating_rts: false,
    uploading_gpx: false,
    delete_track_confirm: false,
    upload_gpx_error: false,
    qr_text_top: "CLASSIFICACIONS",
    qr_text_bottom: "EN DIRECTE",
    search_circuits_text: null,
    circuits: [],
    loading_circuits: false,
    event_circuit: null,
    scoring_circuit: false,
    category_calc_natural_year: false,
    category_calc_date: null,
    token: {
      dsq: false,
      dsq_reason: "",
      penalizations: [],
      token: "",
      current_modality: null,
    },
    new_penalization_template: {'seconds': 0, 'reason': ''}
  }),
  created() {
    this.event_id = this.$route.params.event_id
    this.modality_selected = this.$route.params.modality_selected
    this.now = this.moment()

    this.new_document_model = {...this.original_new_document_model}

    this.fetch_event_data()
    this.fetch_registration_stats()

    setInterval(() => {
      this.now = this.moment()
    }, 1000)


  },
  methods: {
    router() {
      return router
    },
    fetch_registration_stats() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations/stats`,
          {
            "event": this.event_id,
          }
      ).then((response) => {
        this.registration_stats = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    fetch_event_data() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/events`,
          {
            "slug": this.event_id,
            "search_circuits": true,
            "use_cache": this.moment()
          }
      ).then((response) => {
        let d = response.data[0]

        document.title = d.name
        if (Object.hasOwn(d, 'date_start')) {
          d.date_start = this.moment.utc(d.date_start.$date).format('YYYY-MM-DD')
          this.category_calc_date = this.moment.utc(d.date_start.$date).format('YYYY-MM-DD')
        }
        if (Object.hasOwn(d, 'date_end')) {
          d.date_end = this.moment.utc(d.date_end.$date).format('YYYY-MM-DD')
        }
        if (Object.hasOwn(d, 'registration_start')) {
          d.registration_start = this.moment(d.registration_start.$date).format('YYYY-MM-DD')
        }
        if (Object.hasOwn(d, 'registration_end')) {
          d.registration_end = this.moment(d.registration_end.$date).format('YYYY-MM-DD')
        }

        if (Object.hasOwn(d, 'circuit') && Object.hasOwn(d.circuit, '_id')) {
          d.circuit = d.circuit._id
        }

        this.event_data = d
        this.original_event_data = JSON.parse(JSON.stringify(this.event_data))

        this.loading = false
        this.tab_modalities = this.event_data.modalities[0]._id.$oid
        if (this.modality_selected != null && this.modality_selected != "") {
          this.tab_modalities = this.modality_selected
        }

        if (this.event_data.circuit) {
          this.fetch_circuit_data(this.event_data.circuit.$oid)
          this.get_circuits()
        }

        this.fetch_modalities()
        this.fetch_timers()
      }).catch((error) => {
        console.error(error)
      })
    },
    fetch_timers() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/users/timers`,
          {}
      ).then((response) => {
        this.timers = response.data
      })
    },
    fetch_modalities() {
      this.loading_modalities = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/modalities`,
          {event: this.event_data._id.$oid}
      ).then((response) => {
        let modalities = response.data

        modalities.forEach(m => {
          this.modalities_datetime_fields.forEach(field => {
            if (Object.hasOwn(m, field)) {
              m[`${field}_date`] = this.moment.utc(m[field].$date).format('YYYY-MM-DD')
              m[`${field}_time`] = this.moment.utc(m[field].$date).format('HH:mm')
              delete m[field]
            }
          })
        })

        this.modalities = modalities
        this.original_modalities = JSON.parse(JSON.stringify(this.modalities))
        this.loading_modalities = false
        this.get_reader_configurations()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_reader_configurations() {
      let ints = []
      this.event_data.modalities.forEach(modality => {
        modality.intermediates.forEach(int => {
          ints.push(int.$oid)
        })
      })
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/reader-configurations`,
          {
            "intermediate": ints,
          }
      ).then((response) => {
        let reader_configurations = response.data
        reader_configurations.forEach(rc => {
          this.reader_configurations[rc.intermediate.$oid] = rc
        })
      }).catch((error) => {
        console.error(error)
      })
    },
    get_location_from_str() {
      this.location_found = null
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/location`,
          {"query": this.location_query, "include_street": true, "location_with_custom_name": true}
      ).then((response) => {
        this.location_found = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    save_event() {
      this.saving_event = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/event`,
          this.event_data
      ).then((response) => {
        if (response.data) {
          this.saved_event = true
          setTimeout(() => {
            this.saved_event = false
            this.$router.push({
              name: 'AdminEvent',
              params: {event_id: this.event_data.slug, modality_selected: this.tab_modalities}
            }).then(() => {
              this.$router.go()
            })
          }, 500)
        }
      }).catch((error) => {
        console.error(error)
      }).finally(() => {
        this.saving_event = false
      })
    },
    save_modality(idx) {
      this.saving_modality = true

      let mods = []
      let mod_to_change = {...this.modalities[idx]}
      let some_global_change = this.modality_change_registration_datetime_all || this.modality_change_extra_services_all

      if (some_global_change) {
        for (const [key, mod] of Object.entries(this.modalities)) {

          if (this.modality_change_registration_datetime_all) {
            let fields = ['registration_start', 'registration_end']
            fields.forEach(field => {
              let f_date = `${field}_date`
              let f_time = `${field}_time`
              mod[f_date] = mod_to_change[f_date]
              mod[f_time] = mod_to_change[f_time]
            })
          }

          if (this.modality_change_extra_services_all) {
            mod['extra_services'] = mod_to_change['extra_services']
          }

          mods.push({...mod})
        }
      } else {
        mods.push({...this.modalities[idx]})
      }

      mods.forEach(mod => {
        this.modalities_datetime_fields.forEach(field => {
          let f_date = `${field}_date`
          let f_time = `${field}_time`

          if (Object.hasOwn(mod, f_date)) {
            let v_date = mod[f_date]
            if (Object.hasOwn(mod, f_time)) {
              let v_time = mod[f_time]
              mod[field] = `${v_date} ${v_time}`
              delete mod[f_date]
              delete mod[f_time]
            } else {
              mod[field] = mod[f_time]
              delete mod[field]
            }
          }
        })

        this.axios.post(
            `${process.env.VUE_APP_ROOT_API}/modality`,
            mod
        ).then((response) => {
          if (response.data) {
            this.saving_modality = false
            this.saved_modality = true
            setTimeout(() => {
              this.saved_modality = false
              this.$router.push({
                name: 'AdminEvent',
                params: {event_id: this.event_data.slug, modality_selected: this.tab_modalities}
              }).then(() => {
                this.$router.go()
              })
            }, 500)
          }
        }).catch((error) => {
          console.error(error)
        }).finally(() => {
          this.saving_modality = false
        })
      })
    },
    finalize_modality(idx) {
      this.modalities[idx].status = 'finished_provisional'
      this.save_modality(idx)
    },
    finalize_all_modalities() {
      for (const [key, mod] of Object.entries(this.modalities)) {
        this.finalize_modality(key)
      }
    },
    add_or_modify_intermediate() {
      if (this.editing_intermediate === null) {
        this.modalities[this.current_modality]['intermediates'].push(this.current_intermediate)
      } else {
        this.modalities[this.current_modality]['intermediates'][this.editing_intermediate] = this.current_intermediate
        this.editing_intermediate = null
      }
      this.current_intermediate = null
      this.current_modality = null
      this.add_new_intermediate_modal = null
    },
    remove_intermediate(mod, idx) {
      this.modalities[mod].intermediates.splice(idx, 1)
      this.confirm_remove_intermediate = false
    },
    delete_document(idx) {
      this.event_data.documents.splice(idx, 1)
      this.delete_documents_confirm = -1
    },
    fetch_organisms() {
      if (this.typed_organism.length <= 2) {
        return false
      }
      this.loading_organisms = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/organisms`,
          {"name": this.typed_organism}
      ).then((response) => {
        console.log(response.data)
        this.available_organisms = response.data
      }).catch((error) => {
        console.error(error)
      }).finally(() => {
        this.loading_organisms = false
      })
    },
    fetch_circuit_data(id) {
      this.event_circuit = null
      console.log("getting circuit", id)
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/circuits`,
          {
            "id": id
          }
      ).then((response) => {
        this.event_circuit = response.data[0]
      }).catch((error) => {
        console.error(error)
      })
    },
    get_circuits() {
      this.loading_circuits = true
      let filters = {}
      if (!this.event_data.circuit) {
        filters = {
          "limit": 10,
          "order": ["-numeric_id"]
        }
        if (this.search_circuits_text !== null && this.search_circuits_text.length > 0) {
          filters["name"] = this.search_circuits_text
        }
      } else {
        filters = {
          "id": this.event_data.circuit.$oid
        }
      }
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/circuits`,
          filters
      ).then((response) => {
        this.circuits = response.data
        this.loading_circuits = false
      }).catch((error) => {
        console.error(error)
      })
    },
    update_rts(type, force) {
      this.updating_rts = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/update-rts`,
          {
            "type": type,
            "force": force,
            "update_all": type == 'registrations',
            "events": [this.event_data.slug_rts]
          },
      )
      setTimeout(() => {
        this.$router.go()
      }, 3000)
    },
    score_circuit() {
      this.scoring_circuit = true
      this.axios.post(
        `${process.env.VUE_APP_ROOT_API}/circuit-scorer`,
        {
          "circuit": this.event_data.slug
        },
      )
      setTimeout(() => {
        this.$router.go()
      }, 3000)
    },
    add_organism(idx) {
      let oid = this.available_organisms[idx]._id.$oid
      if (this.contains_organism(oid)) {
        return false
      }
      this.event_data.organisms = [...this.event_data.organisms, this.available_organisms[idx]]
    },
    remove_organism(id) {
      let new_organisms = []
      this.event_data.organisms.forEach(o => {
        if (o._id.$oid != id) {
          new_organisms.push(o)
        }
      })
      this.event_data.organisms = new_organisms
    },
    contains_organism(id) {
      for (const o of this.event_data.organisms) {
        if (o._id.$oid === id) {
          return true
        }
      }
      return false
    },
    add_document() {
      this.event_data.documents = [...this.event_data.documents, this.new_document_model]
      this.new_document_model = {...this.original_new_document_model}
      this.new_document_file_type = null
    },
    encode_file(event) {
      this.uploading_new_document = true
      let reader = new FileReader()
      console.log(event)
      let file = event.target.files[0]
      reader.readAsDataURL(file)
      reader.onload = () => {
        console.log(reader.result)
        let payload = {
          "file": reader.result,
          "original_filename": file.name,
          "name": this.new_document_model.name,
          "event": this.event_id
        }
        this.axios.post(
            `${process.env.VUE_APP_ROOT_API}/upload`,
            payload
        ).then((response) => {
          if (response.data.success) {
            this.new_document_model.url = response.data.url
            this.add_document()
          }
        }).catch((error) => {
          console.error(error)
        }).finally(() => {
          this.uploading_new_document = false
        })
      };
    },
    upload_gpx(event, modality) {
      this.uploading_gpx = true
      this.upload_gpx_error = false
      let reader = new FileReader()
      let file = event.target.files[0]
      if (!file.name.endsWith('.gpx')) {
        console.log("Only allowing GPX!")
        this.uploading_gpx = false
        this.upload_gpx_error = true
      } else {
        reader.readAsDataURL(file)
        reader.onload = () => {
          let payload = {
            "file": reader.result,
            "original_filename": file.name,
            "name": "track_gpx",
            "event": this.event_id,
            "modality": modality._id.$oid,
          }
          this.axios.post(
              `${process.env.VUE_APP_ROOT_API}/upload`,
              payload
          ).then((response) => {
            modality.gpx_track = response.data.url
          }).catch((error) => {
            console.error(error)
          }).finally(() => {
            this.uploading_gpx = false
          })
        };
      }
    },
    print_qr() {
      var mywindow = window.open('', 'PRINT', 'height=400,width=600');

      mywindow.document.write('<html><head><title>' + document.title + '</title>');
      mywindow.document.write('</head><body >');
      mywindow.document.write('<h1>' + document.title + '</h1>');
      mywindow.document.write(document.getElementById('print_qr').innerHTML);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      // mywindow.close();

      return true;
    },
    copy_generated_token() {
      navigator.clipboard.writeText(this.computed_token)
    },
    is_modified_modality(idx_modality) {
      return JSON.stringify(this.modalities[idx_modality]) != JSON.stringify(this.original_modalities[idx_modality])
    },
    is_modified_event() {
      return JSON.stringify(this.event_data) != JSON.stringify(this.original_event_data)
    }
  },
  computed: {
    computed_token: {
      get: function () {
        let token = `token=${this.event_data.numeric_id}_${this.token.current_modality}`
        if ( this.token.dsq && this.token.dsq_reason != "") {
          token = `${token},desc=${this.token.dsq_reason}`
        }
        if ( this.token.penalizations.length > 0 ) {
          token = `${token},pen=`
          this.token.penalizations.forEach((p, idx) => {
            token = `${token}${idx>0?';':''}${p.seconds}:${p.reason}`
          })
        }
        return token
      }
    },
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    },
    models: {
      get: function () {
        return this.$root.$data.models
      }
    },
    constants: {
      get: function () {
        return this.$root.$data.constants
      }
    },
    fields_from_rts: {
      get: function () {
        return this.$root.$data.fields_from_rts
      }
    },
    invariants: {
      get: function () {
        return this.$root.$data.invariants
      }
    },
  }
});
</script>
