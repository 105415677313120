<template>

  <v-container style="max-width: 960px;">

    <v-card :loading="intermediate === null">

      <v-toolbar color="primary">
        <v-toolbar-title>
          <span>Configurar lector para intermedio</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn size="small" variant="text" stacked @click="save_reader_configuration" :disabled="time_end == null">
            <v-icon size="small" icon="fa-solid fa-save"></v-icon>
            <span class="hidden-xs mt-1">Guardar</span>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-row v-if="intermediate">

<!--          <v-col cols="12" sm="4" v-if="intermediate">-->
<!--            <v-text-field hide-details density="compact" label="Intermedio" variant="outlined" disabled v-model="intermediate.location.custom_name">-->
<!--              <span>[{{intermediate.order+1}}][{{$root.parse_distance(intermediate.distance_from_start, 0, 1)}}]</span>-->
<!--            </v-text-field>-->
<!--          </v-col>-->

          <v-col cols="12" v-if="modality">
            <v-breadcrumbs density="compact">

              <v-breadcrumbs-item :to="{ name: 'AdminEvent', params: { event_id: modality.event.slug }}"
                                  class="d-inline-block text-truncate">
                {{ modality.event.name_short || modality.event.name }}
              </v-breadcrumbs-item>

              <v-breadcrumbs-divider></v-breadcrumbs-divider>

              <v-breadcrumbs-item class="d-inline-block text-truncate"
                                  :to="{ name: 'AdminEvent', params: { event_id: modality.event.slug, modality_selected: modality._id.$oid }}">
                {{ modality.short_name || modality.name }}
              </v-breadcrumbs-item>

              <v-breadcrumbs-divider></v-breadcrumbs-divider>

              <v-breadcrumbs-item class="d-inline-block text-truncate" v-if="intermediate">
                Intermedio <b>[{{ intermediate.order + 1 }}] [{{$root.parse_distance(intermediate.distance_from_start, 0, 1)}}] {{ intermediate.location.custom_name }}</b>
              </v-breadcrumbs-item>

            </v-breadcrumbs>

<!--              <span to="" class="text-uppercase text-caption">{{modality.event.name_short || modality.event.name}}</span>|-->
<!--              <span class="text-uppercase text-caption">{{modality.short_name || modality.name}}</span>|-->
<!--              <span class="text-uppercase font-weight-black text-caption" v-if="intermediate">{{ intermediate.location.custom_name }}</span>-->
          </v-col>

          <v-col cols="12" sm="5" v-if="intermediate">
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn block v-bind="props">
                  <span v-if="reader_configuration && reader_configuration.reader">
                    <v-icon start size="small" icon="fa-solid fa-wifi"></v-icon>
                    {{this.readers_map[reader_configuration.reader].name}} <v-chip size="x-small" label>{{ this.readers_map[reader_configuration.reader].numeric_id }}</v-chip>
                  </span>
                  <span v-else>
                    Seleccionar lector
                    <v-icon end size="small" icon="fa-solid fa-caret-down"></v-icon>
                  </span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(r, index) in readers" :key="index" :value="r">
                  <v-list-item-title @click="reader_configuration.reader = r._id.$oid">{{r.name}} <v-chip size="x-small" label>{{ r.numeric_id }}</v-chip></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field hide-details type="number" density="compact" label="Minutos entre lecturas" variant="outlined" v-model="security_minutes">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="8">
            <v-text-field hide-details v-model="date_start" :min="min_date" :max="max_date" type="date" density="compact" label="Fecha inicio" variant="outlined" :disabled="!(reader_configuration && reader_configuration.reader && reader_configuration.reader.length > 0)">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field hide-details v-model="time_start" density="compact"  label="Hora inicio" variant="outlined" type="time" :disabled="date_start == null">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="8">
            <v-text-field hide-details v-model="date_end" :min="min_date" :max="max_date" type="date" density="compact" label="Fecha inicio" variant="outlined" :disabled="time_start == null">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field hide-details v-model="time_end" density="compact"  label="Hora inicio" variant="outlined" type="time" :disabled="date_end == null">
            </v-text-field>
          </v-col>

        </v-row>
      </v-card-text>

    </v-card>

  </v-container>

</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'AdminReaderConfiguration',
  components: {
  },
  data: () => ({
    now: null,
    intermediate_id: null,
    intermediate: null,
    reader_configuration: null,
    readers: null,
    readers_map: null,
    create: true,
    date_start: null,
    time_start: null,
    date_end: null,
    time_end: null,
    security_minutes: 0,
    modality: null,
    min_date: null,
    max_date: null,
  }),
  created() {
    this.intermediate_id = this.$route.params.intermediate_id
    this.now = this.moment()
    document.title = "Configurar punto de control"
    this.get_intermediate()
  },
  methods: {
    get_intermediate() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/intermediates?t=${this.moment.now()}`,
          {
            "id": this.intermediate_id,
          }
      ).then((response) => {
        this.intermediate = response.data[0]
        this.get_readers()
        this.get_modality()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_modality() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/modalities?t=${this.moment.now()}`,
          {
            "intermediates": [this.intermediate_id],
          }
      ).then((response) => {
        let modality = response.data[0]
        let min_date = null
        let max_date = null
        if ( modality.datetime_start ) {
          min_date = this.moment(modality.datetime_start.$date).format("YYYY-MM-DD")
          if ( modality.datetime_end ) {
            max_date = this.moment(modality.datetime_end.$date).format("YYYY-MM-DD")
          } else {
            max_date = min_date
          }
        }
        this.min_date = min_date
        this.max_date = max_date
        this.modality = modality
      }).catch((error) => {
        console.error(error)
      })
    },
    get_readers() {
      this.axios.post(`${process.env.VUE_APP_ROOT_API}/readers?t=${this.moment.now()}`, {}).then((response) => {
        this.readers = response.data
        this.readers_map = {}
        this.readers.forEach((r) => {
          this.readers_map[r._id.$oid] = r
        })
        this.get_reader_configurations()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_reader_configurations() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/reader-configurations?t=${this.moment.now()}`,
          {
            "intermediate": [this.intermediate_id]
          }
      ).then((response) => {
        this.reader_configuration = response.data[0]
        if ( this.reader_configuration == null ) {
          this.reader_configuration = {}
          this.reader_configuration.intermediate = this.intermediate_id
        } else {
          this.reader_configuration.reader = this.reader_configuration.reader.$oid
          this.date_start = this.moment.utc(this.reader_configuration.datetime_start.$date).format("YYYY-MM-DD")
          this.time_start = this.moment.utc(this.reader_configuration.datetime_start.$date).format("HH:mm")
          this.date_end = this.moment.utc(this.reader_configuration.datetime_end.$date).format("YYYY-MM-DD")
          this.time_end = this.moment.utc(this.reader_configuration.datetime_end.$date).format("HH:mm")
          this.security_minutes = parseInt(this.reader_configuration.security_seconds/60)
        }
      }).catch((error) => {
        console.error(error)
      })
    },
    save_reader_configuration() {
      this.reader_configuration['datetime_start'] = `${this.date_start} ${this.time_start}`
      this.reader_configuration['datetime_end'] = `${this.date_end} ${this.time_end}`
      this.reader_configuration['security_seconds'] = this.security_minutes * 60
      console.log(this.reader_configuration)
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/reader-configuration`,
          this.reader_configuration
      ).then((response) => {
        if (response.data === true) {
          this.$router.go()
        } else {
          console.error(response.data.error)
        }
      }).catch((error) => {
        console.error(error)
      })
    },
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    },
    constants: {
      get: function () {
        return this.$root.$data.constants
      }
    },
  },
  watch: {
  }
});
</script>
