<template>
  <template v-if="loading">
    <v-progress-linear indeterminate :height="12" v-if="loading"></v-progress-linear>
  </template>
  <template v-else>

    <!--    <marquee-text :repeat="5">-->
    <!--      <v-chip class="ml-12 mr-2" prepend-icon="fa-solid fa-circle-exclamation fa-beat" label color="error" size="x-small"-->
    <!--              variant="flat">LIVE ANNOUNCEMENT 1/3-->
    <!--      </v-chip>-->
    <!--      <span>La entrega de trofeos se pospone hasta las 2:30 pm</span>-->
    <!--    </marquee-text>-->


    <v-app-bar scroll-behavior="hide" scroll-threshold="100" density="compact" color="tertiary" v-if="modality_data && is_admin">
      <v-app-bar-title>
        <v-chip label>{{ event_data.numeric_id }}<span v-if="modality_data">_{{modality_data.numeric_id}}</span></v-chip>
        Admin
      </v-app-bar-title>

      <v-spacer></v-spacer>

      <v-btn stacked :to="{name: 'AdminEvent', params: {event_id: modality_data.event.slug, modality_selected: this.modality_data._id.$oid}}">
        <v-icon size="x-small" icon="fa-solid fa-edit"></v-icon>
        <span class="hidden-xs">Editar</span>
      </v-btn>

      <v-btn stacked :to="{name: 'AdminReports', params: {modality_id: this.modality_data._id.$oid}}">
        <v-icon size="x-small" icon="fa-solid fa-print"></v-icon>
        <span class="hidden-xs">Informes</span>
      </v-btn>

      <v-btn stacked v-if="modality_data.intermediates.length > 0" :to="{name: 'AdminResultsList', params: {modality_id: this.modality_data._id.$oid}}">
        <v-icon size="x-small" icon="fa-solid fa-list-ol"></v-icon>
        <span class="hidden-xs">Tiempos</span>
      </v-btn>
      <v-btn stacked v-if="modality_data.intermediates.length > 0" :active="show_reader_configurations" @click="show_reader_configurations = !show_reader_configurations">
        <v-icon size="x-small" icon="fa-solid fa-wifi"></v-icon>
        <span class="hidden-xs">Lectores</span>
      </v-btn>
    </v-app-bar>

    <v-breadcrumbs color="" bg-color="primary" density="compact" v-if="modality_data" >

      <v-breadcrumbs-item v-if="modality_data.event.circuit && modality_data.circuit_modality && event_data"
                          :to="{ name: 'Circuit', params: { circuit_id: event_data.circuit.slug }}"
                          class="d-inline-block text-truncate">
        {{ event_data.circuit.name_short || event_data.circuit.name }}
      </v-breadcrumbs-item>

      <v-breadcrumbs-divider v-if="modality_data.event.circuit && modality_data.circuit_modality"></v-breadcrumbs-divider>

      <v-breadcrumbs-item v-if="modality_data" :to="{ name: 'Event', params: { event_id: modality_data.event.slug }}"
                          class="d-inline-block text-truncate">
        {{ modality_data.event.name_short || modality_data.event.name }}
      </v-breadcrumbs-item>

      <v-breadcrumbs-divider v-if="event_data && event_data.modalities.length > 1"></v-breadcrumbs-divider>
      <v-breadcrumbs-item v-if="modality_data && event_data && event_data.modalities.length > 1">
        <v-btn v-if="modality_data" variant="outlined" class="text-body-2 pa-1" size="">
          {{ modality_data.short_name || modality_data.name }}
          <v-icon class="ml-2" size="small" icon="fa-solid fa-chevron-down"></v-icon>
          <v-menu activator="parent">
            <v-list density="compact">
              <template v-for="m in event_data.modalities">
                <v-list-item v-if="m.is_timed"
                             @click="this.modality_id = m._id.$oid ; this.$router.push({name: 'Modality', params: {modality_id: m._id.$oid}}).then(() => { this.$router.go() })"
                             :disabled="m._id.$oid == modality_id">
                  <v-list-item-title>{{ m.short_name || m.name }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </v-btn>
      </v-breadcrumbs-item>

    </v-breadcrumbs>

    <v-sheet color="tertiary" v-if="modality_data" class="text-caption">
      <v-row no-gutters class="ml-3 mr-2">
        <v-col cols="6">
          <span class="hidden-xs">{{ moment.utc(modality_data.datetime_start.$date).format('LL') }}</span>
          <span class="hidden-sm-and-up">{{ moment.utc(modality_data.datetime_start.$date).format('DD/MM/YYYY') }}</span>
        </v-col>
        <v-col class="text-right" cols="6" v-if="modality_data.location_start && modality_data.location_start.city">
          {{ modality_data.location_start.city.split('/')[0].trim() }}
          <span class="hidden-xs" v-if="modality_data.location_start.county">, {{
              modality_data.location_start.county.split('/')[0].trim()
            }}</span>
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet color="default" class="pa-1">


      <v-menu v-if="modality_data && modality_data.status === 'live'">
        <template v-slot:activator="{ props }" >
          <v-btn size="small" variant="elevated" class="mr-1" v-bind="props" :loading="loading_modality_stats || loading_modality || loading_classification_stats || loading_classification">
            <v-progress-circular size="small" :model-value="100*autoload_remaining/autoload" v-if="autoload > 0">{{ autoload_remaining }}</v-progress-circular>
            <v-icon icon="fa-solid fa-rotate-right" disabled v-if="autoload == 0"></v-icon>
            <v-icon end icon="fa-solid fa-caret-down"></v-icon>
          </v-btn>
        </template>

        <v-list density="compact">
          <v-list-subheader>Recarga automática</v-list-subheader>
          <v-list-item class="text-caption" @click="change_autoload_option(true, 0)">
            <v-icon size="x-small" start icon="fa-solid fa-toggle-off"></v-icon>Off
          </v-list-item>
          <v-list-item class="text-caption" @click="change_autoload_option(true, 30)">
            <v-icon size="x-small" start icon="fa-solid fa-toggle-on"></v-icon>30s
          </v-list-item>
          <v-list-item class="text-caption" @click="change_autoload_option(true, 60)">
            <v-icon size="x-small" start icon="fa-solid fa-home"></v-icon>60s
          </v-list-item>
          <v-list-item class="text-caption" @click="change_autoload_option(true, 90)">
            <v-icon size="x-small" start icon="fa-solid fa-home"></v-icon>90s
          </v-list-item>
        </v-list>
      </v-menu>

      <v-dialog fullscreen scrim
                v-if="modality_data.gpx_track"
                transition="dialog-bottom-transition" scrollable
                width="100%">

        <template v-slot:activator="{ props }">
          <v-btn size="small" variant="elevated" color="success" class="mr-1" v-bind="props">
            <v-icon  size="x-small" icon="fa-solid fa-map" start></v-icon>
            Ver mapa
          </v-btn>
        </template>

        <template v-slot:default="{ isActive }">

          <v-toolbar color="primary">

            <v-toolbar-title>
              Track y perfil
              <h3>{{ modality_data.short_name || modality_data.name }}</h3>
            </v-toolbar-title>
            <v-toolbar-items>
              <v-btn @click="isActive.value = false" icon>
                <v-icon icon="fa-solid fa-times"></v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <MychipMap style="height: 100%; width: 100%" :modality="modality_data"></MychipMap>
        </template>

      </v-dialog>

      <v-chip label variant="flat" class="mr-1 text-uppercase" v-if="modality_data">
        <v-icon start :icon="$root.get_icon_from_status(modality_data.status)"
                :color="$root.get_icon_color_from_status(modality_data.status)" size="x-small"></v-icon>
        <span>{{ $t(`constants.event_status.${modality_data.status}`) }}</span>
      </v-chip>
      <v-chip label class="mr-1"
              v-if="modality_data && modality_data.status == 'live' && modality_data.chrono_start">
        {{ $root.parse_time_from_seconds((now.diff(moment(modality_data.chrono_start.$date))) / 1000) }}
        <v-tooltip activator="parent" location="bottom">
          Empezó {{ moment.utc(modality_data.chrono_start.$date).format('DD/MM HH:mm') }}
        </v-tooltip>
      </v-chip>
      <v-btn variant="plain" size="small" :disabled="loading_modality_stats"
             @click="modality_info_panel = !modality_info_panel">
        <span v-if="!modality_info_panel"><v-icon start size="x-small" icon="fa-solid fa-plus"></v-icon><span class="hidden-xs">Más </span> info</span>
        <span v-else><v-icon start size="x-small" icon="fa-solid fa-minus"></v-icon><span class="hidden-xs">Menos </span>info</span>
      </v-btn>

      <v-btn variant="plain" size="small" @click="modality_info_panel = false ; extra_classification_panel = !extra_classification_panel" v-if="event_data && event_data.documents.filter((x) => x.type == 'result').length > 0">
      <span>
        <v-icon v-if="extra_classification_panel" start size="x-small" icon="fa-solid fa-minus"></v-icon>
        <v-icon v-else start size="x-small" icon="fa-solid fa-plus"></v-icon>
        Clasificaciones<span class="hidden-xs"> extra</span>
      </span>
      </v-btn>

      <v-expand-transition>
        <div v-show="modality_info_panel">

          <v-expansion-panels class="mt-2" variant="popout">

            <v-expansion-panel>
              <v-expansion-panel-title>Estado de los participantes</v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-table density="compact" v-if="modality_stats" class="text-caption">
                  <tbody>
                  <tr>
                    <td>Inscritos</td>
                    <td>{{ modality_stats.total || 0 }}</td>
                  </tr>
                  <template v-for="(value, idx) in modality_stats.status">
                    <tr v-if="idx != 'started'">
                      <td>{{ idx }}</td>
                      <td>{{ value }}</td>
                    </tr>
                  </template>
                  </tbody>
                </v-table>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-title>Participantes por punto de control</v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-table density="compact" class="text-caption" v-if="modality_data && modality_stats">
                  <tbody>
                  <tr>
                    <td>Salida</td>
                    <td>{{ modality_stats.status.took_start || 0 }}</td>
                  </tr>
                  <tr v-for="value in modality_data.intermediates">
                    <td>KM {{ (value["distance_from_start"] / 1000).toFixed(1) }}</td>
                    <td>{{ modality_stats.result_intermediate_results[value['order']] || 0 }}</td>
                  </tr>
                  <tr>
                    <td>Meta</td>
                    <td>{{ modality_stats.status.finished || 0 }}</td>
                  </tr>
                  </tbody>
                </v-table>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-title>Participantes por género</v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-table class="text-caption" density="compact" v-if="modality_stats">
                  <tbody>
                  <tr v-for="(value, idx) in modality_stats.gender">
                    <td>{{ idx }}</td>
                    <td>{{ value }}</td>
                  </tr>
                  </tbody>
                </v-table>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-title>Participantes por categoría</v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-table class="text-caption" density="compact" v-if="modality_stats">
                  <tbody>
                  <template v-for="(value, idx) in modality_stats.category">
                    <tr v-if="idx != ''">
                      <td>{{ idx }}</td>
                      <td>{{ value }}</td>
                    </tr>
                  </template>
                  </tbody>
                </v-table>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-title>Participantes por club</v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-table class="text-caption" density="compact" v-if="modality_stats">
                  <tbody>
                  <template v-for="(value, idx) in Object.fromEntries(Object.entries(modality_stats.club).slice(1, 10))">
                    <tr v-if="idx != ''">
                      <td>{{ idx }}</td>
                      <td>{{ value }}</td>
                    </tr>
                  </template>
                  </tbody>
                </v-table>
              </v-expansion-panel-text>
            </v-expansion-panel>

          </v-expansion-panels>

        </div>
      </v-expand-transition>

      <v-expand-transition>
        <div v-show="extra_classification_panel" class="text-center">

          <v-chip-group class="text-center" v-if="event_data && event_data.documents" column>
            <template v-for="d in event_data.documents">
              <v-chip label v-if="d.type == 'result'" :href="d.url" target="_blank">
                <v-icon start size="small" icon="fa-solid fa-file"></v-icon>
                {{d.name}}
              </v-chip>
            </template>
          </v-chip-group>
        </div>
      </v-expand-transition>

    </v-sheet>

    <v-progress-linear indeterminate :height="12" v-if="loading_classification"></v-progress-linear>


    <v-expand-transition>
      <v-container max-width="960px" v-if="show_reader_configurations">
        <v-table density="compact">
          <tbody>
          <tr v-for="(int, idx) in modality_data.intermediates" :set="rc = reader_configurations[int._id.$oid]">
            {{ void(exists_rc = int._id.$oid in reader_configurations) }}
            {{ void(rc = exists_rc && reader_configurations[int._id.$oid]) }}
            {{ void(live = exists_rc && rc.reader.heartbeat && $root.is_reader_alive(rc.reader.heartbeat.$date, 2)) }}
            {{ void(less = exists_rc && rc.reader.heartbeat && $root.is_reader_alive(rc.reader.heartbeat.$date, 7)) }}
            {{ void(is_active = exists_rc && rc.datetime_start && rc.datetime_end && $root.is_reader_active(rc.datetime_start.$date, rc.datetime_end.$date)) }}
            <td>{{int.location.custom_name}}</td>
            <td>
            <span v-if="exists_rc && rc.datetime_start && rc.datetime_end">
              {{moment.utc(rc.datetime_start.$date).format('DD/MM HH:mm')}} - {{moment.utc(rc.datetime_end.$date).format('DD/MM HH:mm')}}
              <v-icon icon="fa-solid fa-lock-open" size="x-small" class="ml-1" color="success" v-if="is_active"></v-icon>
              <v-icon icon="fa-solid fa-lock" size="x-small" class="ml-1" color="error" v-else></v-icon>
            </span>
            </td>
            <td>
            <span v-if="exists_rc">
              <v-icon icon="fa-solid fa-circle fa-beat" size="x-small" color="success" v-if="live"></v-icon>
              <v-icon icon="fa-solid fa-circle" size="x-small" color="warning" v-else-if="less"></v-icon>
              <v-icon icon="fa-solid fa-circle" size="x-small" color="error" v-else></v-icon>
              <v-chip label size="small" class="ml-1" v-if="live">En directo</v-chip>
              <v-chip label size="small" class="ml-1" v-else-if="less">Hace rato</v-chip>
              <v-chip label size="small" class="ml-1" v-else>Apagado</v-chip>
            </span>
            </td>
            <td>
            <span v-if="exists_rc">
              <v-icon start size="x-small" icon="fa-solid fa-wifi"></v-icon>
              {{rc.reader.name}} <span class="text-caption">{{rc.reader.numeric_id}}</span>
            </span>
            </td>
            <td>
              <v-btn size="small" variant="flat" :to="{name: 'AdminReaderConfiguration', params: { intermediate_id: int._id.$oid }}">
                <v-icon start icon="fa-solid fa-cog"></v-icon>
                Configuracion
              </v-btn>
            </td>
          </tr>
          </tbody>
        </v-table>
      </v-container>
    </v-expand-transition>

    <v-tabs v-model="active_tab" align-tabs="center" center-active density="compact" v-if="!loading_modality"
            class="mt-4" stacked show-arrows :height="is_admin ? '125px' : ''">
      <v-tab selected-class="bg-white" value="start" @click="fetch_classification_data" max-width="125px">
        <!--      <span class="hidden-xs">Salida</span>-->
        <span class="">{{ $root.parse_distance(0, 0, 1) }}</span>
        <span v-if="is_admin">
        <v-chip label size="x-small" v-if="modality_stats && modality_stats.total && modality_stats.status && modality_stats.status.took_start"> {{ modality_stats.status.took_start }}/{{ modality_stats.total }}</v-chip>
      </span>
      </v-tab>
      <template v-for="i in modality_data.intermediates" v-if="modality_stats">
        <v-tab :disabled="!modality_stats.result_intermediate_results[i.order] > 0" selected-class="bg-white"
               max-width="125px"
               :value="i.order" @click="fetch_classification_data">
          <!--        <span class="hidden-xs">{{ i.location.custom_name }}</span>-->
          <span class="">{{ $root.parse_distance(i.distance_from_start, 1, 1) }}</span>
          <template v-if="is_admin">
            {{ void(exists_rc = i._id.$oid in reader_configurations) }}
            {{ void(rc = exists_rc && reader_configurations[i._id.$oid]) }}
            {{ void(live = exists_rc && rc.reader.heartbeat && $root.is_reader_alive(rc.reader.heartbeat.$date, 2)) }}
            {{ void(less = exists_rc && rc.reader.heartbeat && $root.is_reader_alive(rc.reader.heartbeat.$date, 7)) }}
            {{ void(is_active = exists_rc && rc.datetime_start && rc.datetime_end && $root.is_reader_active(rc.datetime_start.$date, rc.datetime_end.$date)) }}
            <span v-if="exists_rc" class="text-caption">
            <v-icon icon="fa-solid fa-circle fa-beat" size="x-small" color="success" v-if="live"></v-icon>
            <v-icon icon="fa-solid fa-circle" size="x-small" color="warning" v-else-if="less"></v-icon>
            <v-icon icon="fa-solid fa-circle" size="x-small" color="error" v-else></v-icon>
            <v-icon icon="fa-solid fa-lock-open" size="x-small" class="ml-1" color="success" v-if="is_active"></v-icon>
            <v-icon icon="fa-solid fa-lock" size="x-small" class="ml-1" color="error" v-else></v-icon>
          </span>
            <span v-if="is_admin">
            <v-chip label size="x-small" v-if="modality_stats && modality_stats.status && modality_stats.status.took_start && modality_stats.result_intermediate_results && modality_stats.result_intermediate_results[i.order]">
              {{ modality_stats.result_intermediate_results[i.order] }}/{{ modality_stats.status.took_start }}
            </v-chip>
          </span>
          </template>
        </v-tab>
      </template>
      <v-tab selected-class="bg-white" value="finish" @click="fetch_classification_data"
             max-width="125px"
             v-if="modality_stats" :disabled="!('finished' in modality_stats['status'])">
      <span>
        <v-icon size="x-small" icon="fa-solid fa-flag-checkered"></v-icon>
        <span class="hidden-xs">Meta</span>
      </span>
        <span v-if="modality_data.distance">{{ $root.parse_distance(modality_data.distance, 1, 1) }}</span>
        <span v-if="is_admin">
          <v-chip label size="x-small" v-if="modality_stats && modality_stats.status && modality_stats.status.took_start && modality_stats.status.finished">
            {{ modality_stats.status.finished }}/{{ modality_stats.status.took_start }}
          </v-chip>
        </span>
      </v-tab>
    </v-tabs>

    <v-progress-linear indeterminate height="10"
                       v-if="loading_classification || loading_classification_stats"></v-progress-linear>


    <v-sheet class="pa-2 pb-0 text-center">
      <v-chip class="mr-1" variant="outlined" size="x-small" label
              v-if="!loading_classification_stats & !loading_modality_stats">
        mostrando {{ classification_stats.total }}/{{ modality_stats.total }}
      </v-chip>
      <span class="text-caption" v-if="!this.loading_modality">
      Punto de control:
      <span v-if="this.active_tab === 'start'">SALIDA ({{$root.parse_distance(0, 0, 1)}})</span>
      <span v-else-if="this.active_tab === 'finish'">META ({{$root.parse_distance(modality_data.distance, 1, 1)}})</span>
      <span v-else>Intermedio {{ this.modality_data.intermediates[this.active_tab].order }}
        <span>{{ this.modality_data.intermediates[this.active_tab].name }}</span>
        ({{ $root.parse_distance(modality_data.intermediates[this.active_tab].distance_from_start, 1, 1) }})
      </span>
    </span>

      <v-btn size="x-small" :active="show_intermediates_on_finish_line" variant="elevated" class="ml-2"
             v-if="active_tab == 'finish' && modality_data && modality_data.intermediates.length > 0"
             @click="show_intermediates_on_finish_line = !show_intermediates_on_finish_line">
        <v-icon size="x-small" icon="fa-solid fa-route"></v-icon>
        <v-tooltip location="bottom" activator="parent">
          Mostrar tiempos intermedios
        </v-tooltip>
      </v-btn>
    </v-sheet>


    <v-sheet class="pa-2 text-center">

      <v-row align-content="center" justify="center" v-if="modality_data">
        <v-col cols="4" sm="2">
          <v-btn block variant="elevated" @click="dialog_search = true">
            <v-icon size="x-small" start icon="fa-solid fa-magnifying-glass"></v-icon>
            <span>Buscar</span>
          </v-btn>
        </v-col>
        <v-col cols="4" sm="2" v-if="this.active_tab == 'finish'">
          <v-select density="compact" label="Estado" :items="result_status_filters" v-model="result_status_filter"
                    @update:modelValue="fetch_classification_data" variant="outlined">
          </v-select>
        </v-col>
        <v-col cols="4" sm="2" >
          <v-select density="compact" label="Género" :items="result_gender_filters" v-model="result_gender_filter"
                    @update:modelValue="fetch_classification_data" variant="outlined">
          </v-select>
        </v-col>
      </v-row>

      <template v-for="(f, idx) in classification_filters">
        <v-chip class="ml-2" variant="elevated" size="small" label v-if="classification_filters[idx] !== null"
                closable @click:close="remove_filters(idx)">
          <b>{{ idx }}</b>: {{ f }}
        </v-chip>
      </template>
    </v-sheet>

    <v-container style="max-width: 1000px" class="pa-0" >
      {{ void(is_federated = event_data && event_data.organisms.filter(o => o.type =='federation').length > 0) }}
      <v-table density="compact" v-if="!loading_classification">
        <thead>
        <tr class="text-uppercase text-caption">

          <th v-if="is_admin"></th>

          <th class="text-center" v-if="this.active_tab != 'start'">Pos</th>

          <th class="text-center pl-0">dorsal</th>

          <!--      <th class="text-center hidden-xs pl-0"></th>-->
          <th class="pl-0" v-if="is_federated"></th>

          <th class="pl-0 sticky sticky-hard">nombre</th>

          <th class="text-center pl-0" v-if="this.active_tab != 'start'">
            Tiempo<span v-if="show_intermediates_on_finish_line"> meta</span>
          </th>

          <th class="text-center pl-0" v-if="this.active_tab != 'start'">
            Ritmo
          </th>

          <template v-if="show_intermediates_on_finish_line">
            <th v-for="intermediate in modality_data.intermediates">
              KM {{ (intermediate.distance_from_start / 1000).toFixed(1) }}
            </th>
          </template>

          <th class="pl-0">
            cat
            <template v-if="this.active_tab != 'start'">
              <v-icon icon="fa-solid fa-circle-info" size="x-small"></v-icon>
              <v-tooltip activator="parent" location="bottom start">
                <b>Posición categoría</b>
                <br>
                Pulsa en una categoría para filtrar
              </v-tooltip>
            </template>
          </th>

          <th class="pl-0">
            Club
            <template v-if="this.active_tab != 'start'">
              <v-icon icon="fa-solid fa-circle-info" size="x-small"></v-icon>
              <v-tooltip activator="parent" location="bottom start">
                <b>Club</b>
                <br>
                Pulsa en una club para filtrar
              </v-tooltip>
            </template>
          </th>

          <template v-if="modality_data.circuit_modality">
            <th>circuito</th>
            <th>circuito cat</th>
          </template>

          <!--      <th class="pl-0" v-if="active_tab == 'start' || active_tab == 'finish'">Estado</th>-->

        </tr>
        </thead>

        <tbody>
        <tr v-for="c in classification_data" :key="c.bib_number" class="text-caption">

          <td v-if="is_admin" width="1%">
        <span v-if="c.result.intermediate_results.filter(r => r.total_time > 0).length != c.result.intermediate_results.length">
          <v-icon size="small" icon="fa-solid fa-warning" color="error"></v-icon>
          <v-tooltip location="bottom" activator="parent">
            Participante sin todos los puntos de control anteriores
          </v-tooltip>
        </span>
          </td>

          <td class="text-center text-sm-body-2" v-if="this.active_tab != 'start'">
            <template v-if="this.active_tab == 'finish'">
              <template v-if="c.result.status == 'disqualified'">
                <span class="font-weight-bold text-error">DSQ</span>
              </template>
              <template v-else>
                <v-chip v-if="c.result.pos_gender <= 3" variant="elevated" size="x-small"
                        :color="['gold', 'silver', 'bronze'].at(c.result.pos_gender-1)">
                  <v-icon start size="small" icon="fa-solid fa-trophy"></v-icon>
                  <b>{{ c.result.pos_gender }}</b>
                </v-chip>
                <span v-else>{{ c.result.pos_gender }}</span>
                <span v-if="!c.result.pos_gender && c.classify">
              <v-icon size="x-small" icon="fa-solid fa-spinner fa-spin"></v-icon>
              <v-tooltip activator="parent" location="bottom">Asignando posición</v-tooltip>
            </span>
              </template>
            </template>
            <template v-else>
          <span v-if="c.result && c.result.intermediate_results[this.active_tab].pos_gender">
            {{ c.result.intermediate_results[this.active_tab].pos_gender }}
          </span>
              <span v-if="!c.result.intermediate_results[this.active_tab].pos_gender && c.classify">
            <v-icon size="x-small" icon="fa-solid fa-spinner fa-spin"></v-icon>
            <v-tooltip activator="parent" location="bottom">Asignando posición</v-tooltip>
          </span>
            </template>
          </td>

          <td class="text-center pl-0">
            <v-chip size="x-small" label variant="outlined" :color="modality_data.bib_color || ''">
              <span class="text-black">{{ c.bib_number }}</span>
            </v-chip>
          </td>

          <!--      <td class="text-center hidden-xs pl-0">-->
          <!--        <v-avatar size="small" v-if="c.avatar"-->
          <!--                  :image="c.avatar"></v-avatar>-->
          <!--      </td>-->

          <td class="pl-0 text-sm-body-2 text-center" v-if="is_federated">
            <v-chip v-if="c.federation" label size="x-small">
              {{ c.federation }}
            </v-chip>
          </td>


          <td class="pl-1 text-sm-body-1 sticky sticky-hard">
            <router-link :to="{name: 'IndividualResult', params: {result_id: c._id.$oid}}" style="color: #1a222d">
              <span><span class="text-capitalize">{{ c.name }}</span> <b class="text-uppercase text-no-wrap">{{ c.surname }}</b></span>
            </router-link>
          </td>

          <td class="text-center pl-0 text-sm-body-2" v-if="this.active_tab != 'start'">
            <b v-if="this.active_tab == 'finish'" :class="c.result.penalizations.length > 0 ? 'text-error' : ''">
          <span :class="c.result.status == 'disqualified' ? 'text-decoration-line-through' : ''">
            {{ this.$root.parse_time_from_seconds(c.result.total_time) }}
          </span>
              <v-icon v-if="c.result.penalizations.length > 0" size="x-small" icon="fa-solid fa-circle-exclamation" color="error"></v-icon>
              <v-tooltip activator="parent" location="bottom" v-if="c.result.penalizations.length > 0">
                <span>Penalizaciones:</span>
                <span v-for="(p, idx) in c.result.penalizations">
              <br><b v-if="p.time">+{{$root.parse_time_from_seconds(p.time)}}: </b>{{p.reason}}
            </span>
              </v-tooltip>
            </b>
            <b v-else>{{ this.$root.parse_time_from_seconds(c.result.intermediate_results[this.active_tab].total_time) }}</b>
          </td>

          <td class="text-center pl-0 text-sm-body-2" v-if="this.active_tab != 'start'">
            <template v-if="modality_data.sport">
          <span v-if="this.active_tab == 'finish' && modality_data.distance" class="text-caption">
            <span :class="c.result.status == 'disqualified' ? 'text-decoration-line-through' : ''">
              {{ this.$root.get_speed_and_units(modality_data.sport, modality_data.distance, c.result.total_time) }}
            </span>
          </span>
              <span v-if="modality_data.intermediates && modality_data.intermediates[active_tab] && modality_data.intermediates[active_tab].distance_from_start">
            {{ this.$root.get_speed_and_units(modality_data.sport, modality_data.intermediates[active_tab].distance_from_start, c.result.intermediate_results[this.active_tab].total_time) }}
          </span>
            </template>
          </td>

          <template v-if="show_intermediates_on_finish_line">
            <th v-for="(intermediate, idx) in modality_data.intermediates">
          <span v-if="c.result.intermediate_results[idx] && c.result.intermediate_results[idx].total_time">
            {{ moment.unix(parseInt(c.result.intermediate_results[idx].total_time)).utc().format("h:mm:ss") }}
          </span>
            </th>
          </template>

          <td class="text-no-wrap pl-0 text-sm-body-2">
        <span v-if="c.category">
          <template v-if="this.active_tab != 'start'">
            <template v-if="this.active_tab == 'finish'">
              <v-chip v-if="c.result.pos_category <= 3" variant="elevated" size="x-small"
                      :color="['gold', 'silver', 'bronze'].at(c.result.pos_category-1)">
                <v-icon start size="small" icon="fa-solid fa-trophy"></v-icon>
                <b>{{ c.result.pos_category }}</b>
              </v-chip>
              <span v-else>{{ c.result.pos_category }}</span>
            </template>
            <template v-else>
              <span>{{ c.result.intermediate_results[this.active_tab].pos_category }}</span>
            </template>
          </template>
          <v-chip @click="filter_category(c.category)" class="ml-1" label size="x-small">
            {{ c.category }}
            <v-tooltip activator="parent" location="bottom start">
            Filtrar por categoría = {{ c.category }}
          </v-tooltip>
          </v-chip>
        </span>
          </td>

          <td class="pl-0 text-sm-body-2">
            <v-chip @click="filter_club(c.club)" v-if="c.club" label size="x-small">
              {{ c.club }}
              <v-tooltip activator="parent" location="bottom start">
                Filtrar por club = {{ c.club }}
              </v-tooltip>
            </v-chip>
          </td>

          <template v-if="modality_data.circuit_modality">
            <td class="text-center">
          <span v-if="circuit_scores[c._id.$oid] && circuit_scores[c._id.$oid].race_results[modality_data._id.$oid] && circuit_scores[c._id.$oid].race_results[modality_data._id.$oid].score">
            {{ circuit_scores[c._id.$oid].race_results[modality_data._id.$oid].score }} ({{ circuit_scores[c._id.$oid].race_results[modality_data._id.$oid].pos_gender }})
          </span>
            </td>
            <td class="text-center">
          <span v-if="circuit_scores[c._id.$oid] && circuit_scores[c._id.$oid].race_results[modality_data._id.$oid] && circuit_scores[c._id.$oid].race_results[modality_data._id.$oid].score_cat">
            {{ circuit_scores[c._id.$oid].race_results[modality_data._id.$oid].score_cat }} ({{ circuit_scores[c._id.$oid].race_results[modality_data._id.$oid].pos_category }})
          </span>
            </td>
          </template>

          <!--      <td class="pl-0 text-sm-body-2" v-if="active_tab == 'start' || active_tab == 'finish'">-->
          <!--        <v-chip label size="x-small" class="text-uppercase" v-if="c.result && c.result.status">-->
          <!--          <v-icon v-if="c.result.status == 'finished'" start size="x-small" icon="fa-solid fa-flag-checkered"></v-icon>-->
          <!--          {{ $t(`result_status.${c.result.status}`) }}-->
          <!--        </v-chip>-->
          <!--      </td>-->

        </tr>
        </tbody>
      </v-table>


      <v-pagination
          density="compact"
          v-if="classification_stats"
          v-model="page"
          :length="1 + parseInt(classification_stats.total/page_size)">
      </v-pagination>

    </v-container>

    <v-dialog v-model="dialog_search" fullscreen scrim
              transition="dialog-bottom-transition" scrollable
              width="auto">
      <v-card>
        <v-toolbar color="primary" title="Búsqueda participantes">
          <v-toolbar-items>
            <v-btn @click="dialog_search = false" icon>
              <v-icon icon="fa-solid fa-times"></v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>

          <v-row>
            <v-col>
              <v-text-field v-model="free_text_search" density="compact" clearable append-inner-icon="fa-solid fa-magnifying-glass"
                            @click:append-inner="search_registration" @click:clear="search_registration" @keydown.enter="search_registration"
                            hint="Busque por dorsal, nombre, apellidos o club" persistent-hint autofocus
              ></v-text-field>
            </v-col>
          </v-row>

          <v-progress-linear indeterminate v-if="loading_search_result"></v-progress-linear>

          <v-table>

            <tbody>
            <tr v-for="r in search_result">
              <td>
                <span class="text-caption">{{r.bib_number}}</span>
              </td>
              <td class="text-caption">
                <router-link :to="{name: 'IndividualResult', params: {result_id: r._id.$oid}}" style="color: #1a222d">
                  <span><span class="text-capitalize">{{ r.name }}</span> <b class="text-uppercase text-no-wrap">{{ r.surname }}</b></span>
                  <br>
                  <span class="text-caption">{{r.club}}</span>
                </router-link>
              </td>
              <td>
              <span class="text-caption" v-if="r.result && r.result.status">
                <v-chip label size="x-small" class="text-uppercase">
                  <v-icon v-if="r.result.status == 'finished'" start size="x-small" icon="fa-solid fa-flag-checkered"></v-icon>
                  {{ $t(`result_status.${r.result.status}`) }}
                </v-chip>
              </span>
                <span v-else>
                <v-chip label size="x-small" class="text-uppercase">
                  Esperando
                </v-chip>
              </span>
              </td>
            </tr>
            </tbody>
          </v-table>

        </v-card-text>
      </v-card>
    </v-dialog>


  </template>

</template>

<style scoped>
.sticky {
  position: sticky;
  left: 0px;
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 83%, rgba(255, 255, 255, 0) 100%);
}

.sticky-hard {
  z-index: 3;
}
</style>

<script>
import {defineComponent} from 'vue';
import MarqueeText from 'vue-marquee-text-component'
import axios from "axios";
import MychipMap from "@/components/MychipMap.vue";

export default defineComponent({
  name: 'ModalityView',
  components: {
    MychipMap,
    MarqueeText
  },
  data: () => ({
    now: null,
    loading_modality: true,
    loading_event: true,
    loading_winners: true,
    loading_classification: true,
    loading_classification_stats: true,
    loading_modality_stats: true,
    page: 1,
    page_size: 15,
    show_filters: false,
    classification_filters: {
      club: null,
      category: null,
    },
    modality_id: null,
    modality_data: null,
    event_data: null,
    winners_data: null,
    classification_data: null,
    classification_stats: null,
    modality_stats: null,
    modality_info_panel: false,
    extra_classification_panel: false,
    show_intermediates_on_finish_line: false,
    intermediate_info_panel: false,
    active_tab: "start",
    result_status_filters: ["finished", "disqualified", "retired"],
    result_status_filter: "finished",
    result_gender_filters: ["all", "male", "female"],
    result_gender_filter: "all",
    dialog_search: false,
    free_text_search: "",
    search_result: null,
    loading_search_result: false,
    reload_timer: null,
    new_data: false,
    reader_configurations: {},
    show_reader_configurations: false,
    autoload_options: [0, 30, 60, 90],
    autoload: 0,
    autoload_remaining: 0,
    show_autoload_details: false,
    interval_autoload: null,
    circuit_scores: {},
    loading_circuit_scores: false,
  }),
  beforeUnmount: function(){
    if ( this.interval_autoload != null ) {
      clearInterval(this.interval_autoload)
    }
  },
  created() {
    this.now = this.moment().utc(true)
    setInterval(() => {
      this.now = this.moment().utc(true)
    }, 1000)
    document.title = this.$root.app_name
    this.modality_id = this.$route.params.modality_id
    this.reload_all()
  },
  methods: {
    change_autoload_option(change=true, new_value=null) {
      if ( change ) {
        if ( new_value === null ) {
          let idx = this.autoload_options.indexOf(this.autoload)
          idx = idx + 1
          if (idx >= this.autoload_options.length) {
            idx = 0
          }
          this.autoload = this.autoload_options[idx] || 0
        } else {
          this.autoload = new_value
        }
        this.show_autoload_details = true
        this.autoload_remaining = this.autoload
        this.$store.commit('changeAutoload', {new_autoload: this.autoload})
        setTimeout(() => {
          this.show_autoload_details = false
        }, 1000)
      } else {
        if ( this.interval_autoload != null ) {
          clearInterval(this.interval_autoload)
        }
        this.autoload = this.saved_autoload
        this.interval_autoload = setInterval(() => {
          if ( this.autoload > 0 ) {
            this.autoload_remaining = this.autoload_remaining - 1
            if ( this.autoload_remaining <= 0 ) {
              this.reload_all()
              this.autoload_remaining = this.autoload
            }
          }
        }, 1000)
      }
    },
    reload_all() {
      this.fetch_modality_data()
      this.fetch_modality_stats()
      // this.fetch_winners_data()
    },
    fetch_modality_data() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/modalities`,
          {
            id: this.modality_id
          }
      ).then((response) => {
        this.modality_data = response.data[0]
        this.loading_modality = false
      }).catch((error) => {
        console.error(error)
      }).finally(() => {
        this.fetch_event_data()
        this.fetch_modality_reader_configurations()
      })
    },
    fetch_event_data() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/events`,
          {"slug": this.modality_data.event.slug}
      ).then((response) => {
        this.event_data = response.data[0]
        this.loading_event = false
        document.title = `${this.event_data.name_short || this.event_data.name} - ${this.modality_data.short_name || this.modality_data.name}`
      }).catch((error) => {
        console.error(error)
      })
    },
    fetch_modality_stats() {
      this.loading_classification_stats = true
      let url = `${process.env.VUE_APP_ROOT_API}/registrations/stats`
      this.axios.post(url, {"modality": this.modality_id}).then((response) => {
        this.modality_stats = response.data
        this.loading_modality_stats = false
        if (this.modality_stats["status"]["finished"] > 0) {
          this.active_tab = "finish"
        } else if (this.modality_stats["status"]["started"] > 0) {
          if ("result_intermediate_results" in this.modality_stats) {
            for( var idx = Object.values(this.modality_stats.result_intermediate_results).length - 1 ; idx >= 0 ; idx-- ) {
              if ( this.modality_stats.result_intermediate_results[idx] > 0 ) {
                this.active_tab = idx
                break
              }
            }
          }
        }
        this.fetch_classification_data()
      }).catch((error) => {
        console.error(error)
      })
    },
    fetch_classification_data() {
      this.loading_classification = true
      this.loading_classification_stats = true
      let url = `${process.env.VUE_APP_ROOT_API}/registrations`
      this.axios.post(url, this.parse_filters()).then((response) => {
        this.classification_data = response.data
        this.loading_classification = false
        if ( this.modality_data.circuit_modality != null ) {
          this.fetch_circuit_scores()
        }
        this.fetch_classification_stats()
      }).catch((error) => {
        console.error(error)
      })
    },
    fetch_classification_stats() {
      this.loading_classification_stats = true
      let url = `${process.env.VUE_APP_ROOT_API}/registrations/stats`
      this.axios.post(url, this.parse_filters()).then((response) => {
        this.classification_stats = response.data
        this.loading_classification_stats = false
        if ( this.modality_data.status == 'live' ) {
          this.change_autoload_option(false)
        }
      }).catch((error) => {
        console.error(error)
      })
    },
    fetch_modality_reader_configurations() {
      if ( this.is_admin ) {
        let ints = []
        this.modality_data.intermediates.forEach(int => {
          ints.push(int._id.$oid)
        })
        this.axios.post(
            `${process.env.VUE_APP_ROOT_API}/reader-configurations`,
            {
              "intermediate": ints,
              "include_readers": true
            }
        ).then((response) => {
          let reader_configurations = response.data
          reader_configurations.forEach(rc => {
            this.reader_configurations[rc.intermediate.$oid] = rc
          })
        }).catch((error) => {
          console.error(error)
        })
      }
    },
    fetch_circuit_scores() {
      let ids = []
      this.loading_circuit_scores = true
      this.classification_data.forEach(r => {
        ids.push(r._id.$oid)
      })
      this.axios.post(`${process.env.VUE_APP_ROOT_API}/registrations`, {
        "circuit_race": this.modality_data._id.$oid,
        "circuit_ids": ids,
        "circuit_registrations": true,
        "include_modality": false,
        "limit": ids.length + 2,
      }).then((response) => {
        let scores = response.data
        scores.forEach(s => {
          scores[s.race_registrations[this.modality_data._id.$oid].$oid] = s
        })
        this.circuit_scores = scores
        this.loading_circuit_scores = false
      }).catch((error) => {
        console.error(error)
      })
    },
    fetch_winners_data() {
      let filters = this.parse_filters()
      filters['winners'] = true
      this.axios.post(`${process.env.VUE_APP_ROOT_API}/registrations`, filters).then((response) => {
        this.winners_data = response.data
        this.loading_winners = false
      }).catch((error) => {
        console.error(error)
      })
    },
    parse_filters() {
      let filters = {
        "modality": this.modality_id,
        "offset": this.page_size * (this.page - 1),
        "limit": this.page_size,
      }

      let point = this.active_tab
      if (point === "start") {
        filters["order"] = ["bib_number"]
        // filters["status"] = ["started", "finished", "retired", "disqualified", "out_of_time"]
      } else if (point === "finish") {
        filters["order"] = ["result__total_time", "result__shown_order"]
        filters["status"] = [this.result_status_filter]
      } else {
        filters["order"] = [`result__intermediate_results__${this.active_tab}__total_time`, `result__intermediate_results__${this.active_tab}__shown_order`]
        filters["exist"] = [`result__intermediate_results__${this.active_tab}__total_time`]
        filters["status"] = ['finished', 'started']
      }

      if ( this.result_gender_filter !== 'all' ) {
        console.log("filter by", this.result_gender_filter)
        filters["gender"] = [this.result_gender_filter]
      }

      if (this.classification_filters['category'] !== null) {
        filters['category'] = [this.classification_filters['category']]
      }

      if (this.classification_filters['club'] !== null) {
        filters['club'] = [this.classification_filters['club']]
      }

      return filters
    },
    filter_category(c) {
      this.classification_filters['category'] = c
      this.page = 1
      this.fetch_classification_data()
    },
    remove_filters(idx) {
      this.classification_filters[idx] = null
      this.page = 1
      this.fetch_classification_data()
    },
    filter_club(c) {
      this.classification_filters['club'] = c
      this.page = 1
      this.fetch_classification_data()
    },
    search_registration() {
      this.search_result = null
      console.log(this.free_text_search)
      if ( this.free_text_search !== null ) {
        this.loading_search_result = true
        let url = `${process.env.VUE_APP_ROOT_API}/registrations`
        this.axios.post(
            url,
            {
              "modality": this.modality_id,
              "free_text_search": this.free_text_search,
              "limit": 20
            }
        ).then((response) => {
          this.search_result = response.data
          this.loading_search_result = false
        }).catch((error) => {
          console.error(error)
        })
      }
    }
  },
  watch: {
    page: {
      handler() {
        this.fetch_classification_data()
      }
    },
    active_tab: {
      handler() {
        this.page = 1
        this.result_status_filter = "finished"
        this.show_intermediates_on_finish_line = false
      }
    },
  },
  computed: {
    loading: {
      get: function () {
        return this.loading_modality || this.loading_event
      }
    },
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    },
    is_admin: {
      get: function () {
        return this.$root.is_admin
      }
    },
    saved_autoload: {
      get: function () {
        return this.$root.autoload
      }
    },
  }
});
</script>