import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'



export default createStore({
  state: {
    language: 'en',
    units: 'metric',
    name: null,
    token: null,
    roles: null,
    autoload: 0,
    reports: {}
  },
  getters: {
    is_admin (state) {
      return ( state.roles === null ) ? false : state.roles.includes('admin')
    },
    is_cm (state) {
      return ( state.roles === null ) ? false : state.roles.includes('community_manager')
    },
    is_timer (state) {
      return ( state.roles === null ) ? false : state.roles.includes('timer')
    },
    is_authenticated (state) {
      return state.token !== null
    },
    name (state) {
      return state.name
    },
    roles (state) {
      return state.roles
    },
    token (state) {
      return state.token
    },
    units (state) {
      return state.units
    },
    language (state) {
      return state.language
    },
    autoload (state) {
      return state.autoload
    },
    reports (state) {
      return state.reports
    }
  },
  mutations: {
    changeLang(state, {new_lang}) {
      this.state.language = new_lang
    },
    changeUnits(state, {new_units}) {
      this.state.units = new_units
    },
    changeToken(state, {new_token}) {
      this.state.token = new_token
    },
    changeRole(state, {new_role}) {
      this.state.roles = new_role
    },
    changeName(state, {new_name}) {
      this.state.name = new_name
    },
    changeAutoload(state, {new_autoload}) {
      console.log("Changing autoload to", new_autoload)
      this.state.autoload = new_autoload
    },
    changeReports(state, {new_reports}) {
      this.state.reports = new_reports
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
