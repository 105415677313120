<template>

  <v-table density="compact" v-if="!loading_registrations">
    <thead>
    <tr class="text-uppercase text-caption">
      <th></th>
      <th class="text-center">Pos</th>
      <th class="pl-1 sticky-hard sticky">Nombre</th>
      <th class="text-center">Puntos</th>
      <th v-if="type=='individual' && show_cat" class="text-center">Puntos cat</th>
      <th v-if="type=='individual' && show_cat">
        cat
<!--        <v-icon icon="fa-solid fa-circle-info" size="x-small"></v-icon>-->
<!--        <v-tooltip activator="parent" location="bottom start">-->
<!--          <b>Posición categoría</b>-->
<!--          <br>-->
<!--          Pulsa en una categoría para filtrar-->
<!--        </v-tooltip>-->
      </th>
      <th class="text-center">
        #
        <v-tooltip activator="parent" location="top">
          Total de participaciones
        </v-tooltip>
      </th>
      <th v-if="race_details" v-for="(race, idx, idxx) in races">
        {{ idxx + 1 }}
        <v-tooltip activator="parent" location="top">
          {{ moment(race.date_start.$date).format('DD/MM') }} - {{ race.name }}
        </v-tooltip>
      </th>
    </tr>
    </thead>

    <tbody>
    <tr v-for="r in registrations">
      <td class="pl-0 pr-0">

        <CircuitCtm2024Detail v-if="circuit.punctuation_type == 'ctm_2023'"
                              :participants="participants" :r="r" :circuit="circuit"
                              :gender="gender" :show_cat="show_cat"
                              :races_modalities="races_modalities" :registrations_stats="registrations_stats"
                              :races="races" :type="type"></CircuitCtm2024Detail>

        <CircuitFemecv2024Detail v-if="circuit.punctuation_type == 'femecv_2024_lliga'"
                                 :participants="participants" :r="r" :circuit="circuit"
                                 :gender="gender" :show_cat="show_cat"
                                 :races_modalities="races_modalities" :registrations_stats="registrations_stats"
                                 :races="races" :type="type"></CircuitFemecv2024Detail>

        <VallUixo5kDetail v-if="circuit.punctuation_type == 'vall_uixo_5k'"
                                 :participants="participants" :r="r" :circuit="circuit"
                                 :gender="gender" :show_cat="show_cat"
                                 :races_modalities="races_modalities" :registrations_stats="registrations_stats"
                                 :races="races" :type="type"></VallUixo5kDetail>

      </td>
      <td class="text-center">{{ r.result.pos_gender }}</td>
      <td class="pl-1 sticky sticky-hard">
        <span>{{ r.name }}</span> <b class="text-uppercase text-no-wrap">{{ r.surname }}</b>
      </td>
      <td class="text-center">{{ r.result.best_score }}</td>
      <td v-if="type=='individual' && show_cat" class="text-center">{{ r.result.best_score_cat }}</td>
      <td v-if="type=='individual' && show_cat">
        <span v-if="r.category" class="text-no-wrap">
          {{ r.result.pos_category }}
          <v-chip class="ml-1" label size="x-small">
            {{ r.category }}
          </v-chip>
        </span>
      </td>
      <td class="text-center">
        <span>{{ r.total_races }}</span>
        <span class="text-no-wrap ml-1" v-if="type == 'individual'">
          <template v-if="Object.keys(races_modalities).length > 0">
            <span v-for="(race, idx) in races_modalities">
              <template v-if="statuses.live_statuses.includes(race.status)">
                <v-icon size="x-small" color="success"
                        v-if="r.race_results && race._id.$oid in r.race_results"
                        icon="fa-solid fa-circle-check fa-2xs"></v-icon>
                <v-icon size="x-small" color="error" v-else icon="fa-solid fa-circle-xmark fa-2xs"></v-icon>
              </template>
              <template v-else>
                <v-icon size="x-small" color="grey-lighten-1" icon="fa-regular fa-circle fa-2xs"></v-icon>
              </template>
              <v-tooltip activator="parent" location="bottom">{{ moment(races[idx].date_start.$date).format('DD/MM') }} - {{ races[idx].name }}</v-tooltip>
            </span>
          </template>
          <template v-else>
            <v-icon size="x-small" icon="fa-solid fa-circle-notch fa-spin"></v-icon>
          </template>
        </span>
      </td>
      <td v-if="race_details" v-for="(race, idx, idxx) in races" class="text-caption">
        <template v-if="r.race_results && r.race_results[idx]">
          <template v-if="type == 'individual'">
            <span class="text-no-wrap">{{ r.race_results[idx].score }} ({{ r.race_results[idx].pos_gender }})</span>
            <template v-if="show_cat && r.category">
              <br>
              <span class="text-no-wrap">{{ r.race_results[idx].score_cat }} ({{
                  r.race_results[idx].pos_category
                }})</span>
            </template>
          </template>
          <template v-if="type == 'teams'">
            <span class="text-no-wrap">
              <v-icon icon="fa-solid fa-circle-check" size="x-small" color="success" v-if="r.race_scores[idx]"></v-icon>
              <v-icon icon="fa-solid fa-circle-xmark" size="x-small" color="error" v-else></v-icon>
              {{ r.race_results[idx].pos_gender }} ({{ r.race_results[idx].score }})
            </span>
          </template>
        </template>
      </td>
    </tr>
    </tbody>
  </v-table>

</template>

<style scoped>
.sticky {
  position: sticky;
  left: 0px;
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 83%, rgba(255, 255, 255, 0) 100%);
}

.sticky-hard {
  z-index: 3;
}
</style>

<script>
import moment from "moment/moment";
import {SUPPORTED_LANGUAGES, SUPPORTED_UNITS} from "@/locales/constants";
import axios from "axios";

import CircuitCtm2024Detail from '@/components/circuit_views/Ctm2024Detail.vue'
import CircuitFemecv2024Detail from '@/components/circuit_views/Femecv2024Detail.vue'
import VallUixo5kDetail from '@/components/circuit_views/VallUixo5kDetail.vue'

export default {
  name: "Base",
  components: {CircuitCtm2024Detail, CircuitFemecv2024Detail, VallUixo5kDetail},
  props: [
    "circuit",
    "registrations",
    "registrations_stats",
    "events",
    "races",
    "races_modalities",
    "participants",
    "race_details",
    "type",
    "loading_registrations",
    "gender",
    "show_cat",
  ],
  data: () => ({
  }),
  methods: {
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    is_admin: {
      get: function () {
        return this.$root.is_admin
      }
    },
    is_authenticated: {
      get: function () {
        return this.$root.is_authenticated
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    },
    statuses: {
      get: function () {
        return this.$root.$data.statuses
      }
    }
  }
}
</script>