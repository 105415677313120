// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import '@fortawesome/fontawesome-free/css/all.css'

// Vuetify
import { createVuetify } from 'vuetify'
import { aliases, fa } from 'vuetify/iconsets/fa'

const mychip_light_theme = {
    dark: false,
    colors: {
        background: '#FFFFFF',
        surface: '#FFFFFF',
        primary: '#187fa2',
        secondary: '#4ec2c5',
        tertiary: '#1a222d',
        error: '#B00020',
        info: '#b39c80',
        success: '#4CAF50',
        warning: '#FB8C00',
        gold: '#ffd700',
        silver: '#C0C0C0',
        bronze: '#CD7F32',
    }
}
export default createVuetify({
    theme: {
        defaultTheme: 'mychip_light_theme',
        themes: {
            mychip_light_theme,
        }
    },
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa,
        },
    },
})
