<template>

  <v-container style="max-width: 960px;">

    <v-row>
      <v-col><h1>Lectores</h1></v-col>
      <v-col class="text-right"><v-btn class="mb-2" color="primary" :to="{name: 'AdminReader', params: { reader_id: 0 }}">Nuevo lector</v-btn></v-col>
    </v-row>

    <v-progress-linear v-if="loading_readers" class="mt-4" indeterminate :height="12">Lectores</v-progress-linear>

    <template v-if="!loading_readers">
      <template v-if="readers">
        <v-table density="compact">
          <thead>
          <tr>
            <th>Lector</th>
            <th>Id</th>
            <th>Último evento</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="r in readers">
            {{ void(live = r.heartbeat && $root.is_reader_alive(r.heartbeat.$date, 2)) }}
            {{ void(less = r.heartbeat && $root.is_reader_alive(r.heartbeat.$date, 7)) }}
            <td>
              <router-link :to="{name: 'AdminReader', params: { reader_id: r._id.$oid }}" class="text-uppercase">{{ r.name }}</router-link>
            </td>
            <td>
              <v-chip label size="small">{{r.numeric_id}}</v-chip>
            </td>
            <td>
              <span v-if="r.last_event_received">
                {{ moment.utc(r.last_event_received.$date).format('DD/MM HH:mm:ss') }}
                <span class="text-caption">
                  hace {{ moment.duration(now.utc(true).diff(moment.utc(r.last_event_received.$date))).humanize() }}
                </span>
              </span>
            </td>
            <td>
              <v-icon icon="fa-solid fa-circle fa-beat" size="small" color="success" v-if="live"></v-icon>
              <v-icon icon="fa-solid fa-circle" color="warning" size="small" v-else-if="less"></v-icon>
              <v-icon icon="fa-solid fa-circle" color="error" size="small" v-else></v-icon>
              <v-chip label class="ml-1" size="small" v-if="live">En directo</v-chip>
              <v-chip label class="ml-1" size="small" v-else-if="less">Hace rato</v-chip>
              <v-chip label class="ml-1" size="small" v-else>Apagado</v-chip>
            </td>
          </tr>
          </tbody>
        </v-table>

      </template>
      <v-alert v-else type="info">No readers were found</v-alert>
    </template>

  </v-container>

</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'AdminEventsList',
  components: {
  },
  data: () => ({
    now: null,
    loading_readers: true,
    readers: null,
    page: 1,
    page_size: 20,
  }),
  created() {
    this.now = this.moment()
    setInterval(() => {
      this.now = this.moment()
    }, 1000)
    document.title = "Admin Readers"
    this.get_readers()
  },
  methods: {
    parse_filters() {
      let filters = {
        "offset": this.page_size * (this.page - 1),
        "limit": this.page_size,
      }
      return filters
    },
    get_readers() {
      this.loading_readers = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/readers`,
          this.parse_filters()
      ).then((response) => {
        this.readers = response.data
        this.loading_readers = false
      }).catch((error) => {
        console.error(error)
      })
    },
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    }
  },
  watch: {
  }
});
</script>
