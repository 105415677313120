<template>

  <v-container style="max-width: 960px;">

    <h1>Hola, {{ this.$root.name }}.</h1>


    <v-alert type="warning" v-if="alarms && alarms.map(x => x.objects.length).filter(x => x > 0).length > 0">
      <v-alert-title>Alarmas detectadas</v-alert-title>
      Algunos eventos o modalidades necesitan de su atención

      <v-table density="compact">
        <thead>
        <tr>
          <th>Tipo de alarma</th>
          <th>Elemento</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="alarm_group in alarms">
          <tr v-for="object in alarm_group.objects">
            <td>{{ alarm_group.type }}</td>
            <td>
              <router-link v-if="alarm_group.object_type == 'event'" :to="{name: 'AdminEvent', params: { event_id: object.slug }}" class="text-uppercase">{{ object.name_short || object.name }}</router-link>
            </td>
          </tr>
        </template>
        </tbody>
      </v-table>
    </v-alert>

    <v-progress-linear class="mt-4" indeterminate :height="12" v-if="loading_stats">Stats</v-progress-linear>
    <template v-if="stats">
      <h2 class="mt-8">Estadísticas generales</h2>
      <v-row v-if="stats">
        <v-col>
          <v-card>
            <v-card-text class="text-center">
              <h2>
                <number :from="0" :to="stats.events" :duration="1" easing="Power1.easeOut"/>
              </h2>
              eventos
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-text class="text-center">
              <h2>
                <number :from="0" :to="stats.events_open" :duration="1" easing="Power1.easeOut"/>
              </h2>
              abiertos
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-text class="text-center">
              <h2>
                <number :from="0" :to="stats.circuits" :duration="1" easing="Power1.easeOut"/>
              </h2>
              circuitos
            </v-card-text>
          </v-card>
        </v-col>

        <v-col>
          <v-hover v-model="hovering_users">
            <template v-slot:default="{ isHovering, props }">
              <v-card v-bind="props" :color="isHovering ? 'primary' : ''">
                <v-card-text class="text-center">
                  <h2>
                    <number :from="0" :to="stats.users" :duration="1" easing="Power1.easeOut"/>
                  </h2>
                  usuarios
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-col>


        <v-col>
          <v-hover v-model="hovering_registrations">
            <template v-slot:default="{ isHovering, props }">
              <v-card v-bind="props" :color="isHovering ? 'primary' : ''">
                <v-card-text class="text-center">
                  <h2>
                    <number :from="0" :to="stats.registrations" :duration="stats.registrations/100000/3" easing="Power1.easeOut"/>
                  </h2>
                  registros
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <v-col>
          <v-card>
            <v-card-text class="text-center">
              <h2>
                <number :from="0" :to="stats.finished" :duration="stats.finished/100000/3" easing="Power1.easeOut"/>
              </h2>
              finishers
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-expand-transition>
        <template v-if="hovering_registrations">
          <v-card class="mt-2" color="primary">
            <v-card-title>Estadisticas de usuario</v-card-title>
            <v-card-text>
              <template v-for="(total, rs) in stats.registrations_source">
                {{100*total/stats.registrations}}% = {{rs}}
                <v-progress-linear height="10" class="mb-2" :model-value="100*total/stats.registrations">{{100*total/stats.registrations}}% = {{rs}}</v-progress-linear>
              </template>
            </v-card-text>
          </v-card>
        </template>
      </v-expand-transition>

      <v-expand-transition>
        <template v-if="hovering_users">
          <v-card class="mt-2" color="primary">
            <v-card-title>Estadisticas de usuario</v-card-title>
            <v-card-text>
              <v-row >
                <v-col>
                  <h4>Usuarios por genero</h4>
                  <v-progress-circular indeterminate v-if="loading_users_stats"></v-progress-circular>
                  <template v-else>
                    <template v-for="(v, c) in user_stats.gender">
                      <b>{{c}}</b> {{v}}
                      <v-progress-linear height="10"  :model-value="v"  :max="Object.values(user_stats.gender).reduce((a, b) => a + b, 0)"></v-progress-linear>
                    </template>
                  </template>
                </v-col>
                <v-col>
                  <h4>Usuarios por pais de origen</h4>
                  <v-progress-circular indeterminate v-if="loading_users_stats"></v-progress-circular>
                  <template v-else>
                    <template v-for="(v, c) in user_stats.country">
                      <b>{{c}}</b> {{v}}
                      <v-progress-linear height="10"  :model-value="v"  :max="Object.values(user_stats.country).reduce((a, b) => a + b, 0)"></v-progress-linear>
                    </template>
                  </template>
                </v-col>
                <v-col>
                  <h4>Usuarios por origen de los datos</h4>
                  <v-progress-circular indeterminate v-if="loading_users_stats"></v-progress-circular>
                  <template v-else>
                    <template v-for="(v, c) in user_stats.source">
                      <b>{{c}}</b> {{v}}
                      <v-progress-linear height="10"  :model-value="v"  :max="Object.values(user_stats.source).reduce((a, b) => a + b, 0)"></v-progress-linear>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

        </template>
      </v-expand-transition>
    </template>

    <v-progress-linear class="mt-4" indeterminate :height="12" v-if="loading_live_events">Live events
    </v-progress-linear>
    <template v-if="live_events && live_events.length > 0">
      <h2 class="mt-8">Eventos en directo</h2>
      <EventsTable :events="live_events"></EventsTable>
    </template>

    <v-progress-linear class="mt-4" indeterminate :height="12" v-if="loading_past_events">Past events
    </v-progress-linear>
    <template v-if="past_events && past_events.length > 0">
      <h2 class="mt-8">Últimos eventos</h2>
      <EventsTable :events="past_events"></EventsTable>
    </template>

    <v-progress-linear class="mt-4" indeterminate :height="12" v-if="loading_next_events">Next events
    </v-progress-linear>
    <template v-if="next_events && next_events.length > 0">
      <h2 class="mt-8">Próximos eventos</h2>
      <EventsTable :events="next_events" :registrations_stats="next_event_registrations_stats"></EventsTable>
    </template>


    <v-progress-linear class="mt-4" indeterminate :height="12" v-if="loading_registrations">Ultimos registros
    </v-progress-linear>
    <template class="mt-4" v-if="last_registrations && last_registrations.length > 0">
      <h2 class="mt-8">Últimos registros</h2>
      <RegistrationsTable :registrations="last_registrations" :show_race="true" :show_user="true"></RegistrationsTable>
    </template>

  </v-container>

</template>

<script>
import {defineComponent} from 'vue';
import MarqueeText from 'vue-marquee-text-component'
import Calendar from '../components/Calendar.vue'
import EventsTable from "@/components/EventsTable.vue";
import RegistrationsTable from "@/components/RegistrationsTable.vue";

export default defineComponent({
  name: 'Admin',
  components: {
    MarqueeText,
    Calendar,
    EventsTable,
    RegistrationsTable,
  },
  data: () => ({
    now: null,
    stats: null,
    loading_stats: true,
    next_events: null,
    loading_next_events: true,
    loading_next_events_registrations_stats: true,
    next_event_registrations_stats: {},
    live_events: null,
    loading_live_events: true,
    past_events: null,
    loading_past_events: true,
    last_registrations: null,
    loading_registrations: true,
    hovering_users: false,
    hovering_registrations: false,
    loading_users_stats: true,
    user_stats: null,
    alarms: null,
  }),
  created() {
    this.now = this.moment()
    document.title = "Admin Mychip"

    this.axios.get(`${process.env.VUE_APP_ROOT_API}/stats?cache=${this.moment().unix()}`).then((response) => {
      this.stats = response.data
      this.loading_stats = false
    }).catch((error) => {
      console.error(error)
    })

    this.axios.post(
        `${process.env.VUE_APP_ROOT_API}/events`,
        {
          "limit": 10,
          "status": ["registrations_not_opened", "registrations_opened", "registrations_full", "registrations_closed"],
        }
    ).then((response) => {
      this.next_events = response.data
      this.loading_next_events = false
      this.get_next_events_stats()
    }).catch((error) => {
      console.error(error)
    })

    this.axios.post(
        `${process.env.VUE_APP_ROOT_API}/events`,
        {
          "status": ["waiting_for_start", "live"],
        }
    ).then((response) => {
      this.live_events = response.data
      this.loading_live_events = false
    }).catch((error) => {
      console.error(error)
    })

    this.axios.post(
        `${process.env.VUE_APP_ROOT_API}/events`,
        {
          "status": ["finished_provisional", "finished_official"],
          "date_start": this.moment().subtract(7, 'days')
        }
    ).then((response) => {
      this.past_events = response.data
      this.loading_past_events = false
    }).catch((error) => {
      console.error(error)
    })

    this.axios.post(
        `${process.env.VUE_APP_ROOT_API}/registrations`,
        {
          "order": ["-creation"],
          "limit": 25
        }
    ).then((response) => {
      this.last_registrations = response.data
      this.loading_registrations = false
    }).catch((error) => {
      console.error(error)
    })

    this.axios.post(
        `${process.env.VUE_APP_ROOT_API}/users/stats`,
        {"all_stats": true}
    ).then((response) => {
      this.user_stats = response.data
      this.loading_users_stats = false
    }).catch((error) => {
      console.error(error)
    })

    this.axios.post(
        `${process.env.VUE_APP_ROOT_API}/alarms`,
    ).then((response) => {
      this.alarms = response.data
    }).catch((error) => {
      console.error(error)
    })

  },
  methods: {
    get_next_events_stats() {
      this.next_events.forEach(e => {
        this.axios.post(
            `${process.env.VUE_APP_ROOT_API}/registrations/stats`,
            {
              "event": e.slug,
            }
        ).then((response) => {
          this.next_event_registrations_stats[e._id.$oid] = response.data
          this.loading_next_events_registrations_stats = false
        }).catch((error) => {
          console.error(error)
        })
      })
    }
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    }
  }
});
</script>
