<template>
  <template v-if="loading_event">
    <v-progress-linear indeterminate :height="12" v-if="loading_event"></v-progress-linear>
  </template>
  <template v-else>

    <v-breadcrumbs color="" bg-color="primary" density="compact" v-if="event_data" >

      <template v-if="event_data.is_circuit">
        <v-breadcrumbs-item :to="{ name: 'Circuit', params: { circuit_id: event_data.slug }}"
                            class="d-inline-block text-truncate">
          {{ event_data.name_short || event_data.name }}
        </v-breadcrumbs-item>

        <v-breadcrumbs-divider></v-breadcrumbs-divider>
      </template>

      <template v-else>

        <v-breadcrumbs-item v-if="event_data && event_data.circuit"
                            :to="{ name: 'Circuit', params: { circuit_id: event_data.circuit.slug }}"
                            class="d-inline-block text-truncate">
          {{ event_data.circuit.name_short || event_data.circuit.name }}
        </v-breadcrumbs-item>

        <v-breadcrumbs-divider v-if="event_data.circuit"></v-breadcrumbs-divider>

        <v-breadcrumbs-item v-if="event_data" :to="{ name: 'Event', params: { event_id: event_data.slug }}"
                            class="d-inline-block text-truncate">
          {{ event_data.name_short || event_data.name }}
        </v-breadcrumbs-item>

        <v-breadcrumbs-divider v-if="event_data"></v-breadcrumbs-divider>

      </template>

      <v-breadcrumbs-item>
        Listado de inscritos
      </v-breadcrumbs-item>

    </v-breadcrumbs>

    <v-sheet>
      <v-img class="w-100" cover lazy-src="https://mychip-dev-images.s3.eu-central-1.amazonaws.com/posters/default.png"
             :src="event_data.poster"
             height="150px" gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,1)">
      </v-img>

        <h1 class="text-center text-uppercase text-no-wrap text-truncate">
          {{ event_data.name }}
        </h1>

    </v-sheet>

    <v-container style="max-width: 960px;">


      <h1>Listado de inscritos</h1>

      <v-row class="mb-2">
        <v-col v-if="event_data.modalities.length > 1 && event_data.show_registrations_by_modality">
          <v-select label="Modalidad" hide-details v-if="modalities && modalities.length > 1"
                    v-model="modality_selected"
                    density="compact" variant="outlined" item-title="name"
                    :items="modalities"></v-select>
        </v-col>
        <v-col>
          <v-text-field label="Buscar" clearable on-click:clear="get_registrations" density="compact" variant="outlined" v-model="text_search"
                        append-inner-icon="fa-solid fa-magnifying-glass"
                        @click:append-inner="get_registrations" @click:clear="get_registrations" @keydown.enter="get_registrations"
                        hint="Busque por dorsal, nombre, apellidos o club" persistent-hint></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="event_data.show_total_registrations">
        <v-col class="text-right mb-2">
          <v-chip label variant="outlined" size="small" v-if="registrations_stats">mostrando <span v-if="registrations_stats" class="ml-1">{{ registrations_stats.total }}</span><span
              v-if="registrations_stats_no_filter" class="ml-1">de {{ registrations_stats_no_filter.total }}</span></v-chip>
          <v-icon v-else size="small" icon="fa-solid fa-spinner fa-spin"></v-icon>
        </v-col>
      </v-row>

      <v-progress-linear indeterminate :height="6" v-if="loading_registrations"></v-progress-linear>

      <div class="text-center mb-2">
        <v-chip class="ml-2" variant="elevated" size="small" label v-if="category !== null"
                closable @click:close="filter_category(null)">
          <b>category</b>: {{ category }}
        </v-chip>
        <v-chip class="ml-2" variant="elevated" size="small" label v-if="club !== null"
                closable @click:close="filter_club(null)">
          <b>club</b>: {{ club }}
        </v-chip>
      </div>

      <v-table density="compact" v-if="!loading_registrations && registrations">
        <thead>
        <tr class="text-caption">
          <th class="text-center">
            Dorsal
            <v-icon @click="extra_sorting = 'bib_number'" size="x-small" icon="fa-solid fa-sort" v-if="extra_sorting == null"></v-icon>
            <v-icon @click="extra_sorting = '-bib_number'" size="x-small" icon="fa-solid fa-sort-up" v-if="extra_sorting == 'bib_number'"></v-icon>
            <v-icon @click="extra_sorting = null" size="x-small" icon="fa-solid fa-sort-down" v-if="extra_sorting == '-bib_number'"></v-icon>
          </th>
          <th class="">
            Nombre
            <v-icon @click="extra_sorting = 'surname'" size="x-small" icon="fa-solid fa-sort" v-if="extra_sorting == null"></v-icon>
            <v-icon @click="extra_sorting = '-surname'" size="x-small" icon="fa-solid fa-sort-up" v-if="extra_sorting == 'surname'"></v-icon>
            <v-icon @click="extra_sorting = null" size="x-small" icon="fa-solid fa-sort-down" v-if="extra_sorting == '-surname'"></v-icon>
          </th>
          <th class="">Categoria</th>
          <th class="">Club</th>
          <th class="" v-if="modality_selected == 'all'">Modalidad</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="c in registrations">

          <td class="text-center">
            <v-chip v-if="c.bib_number" size="x-small" label variant="outlined">{{ c.bib_number }}</v-chip>
          </td>

          <td class="text-sm-body-1">
            {{ c.name }} <b class="text-uppercase text-no-wrap">{{ c.surname }}</b>
          </td>

          <td class="text-no-wrap text-sm-body-2">
            <v-chip v-if="c.category" class="ml-1" label size="x-small" @click="filter_category(c.category)">
              {{ c.category }}
            </v-chip>
          </td>

          <td class="text-sm-body-2">
            <v-chip v-if="c.club" label size="x-small" @click="filter_club(c.club)">
              {{ c.club }}
            </v-chip>
          </td>

          <td class="text-caption" v-if="modality_selected == 'all'">{{ c.modality.short_name || c.modality.name }}</td>

        </tr>
        </tbody>
      </v-table>

      <v-pagination
          density="compact"
          v-if="registrations_stats"
          v-model="page"
          :length="1 + parseInt(registrations_stats.total/page_size)"
      ></v-pagination>

    </v-container>

  </template>
</template>

<script>
import {defineComponent} from 'vue';
import axios from "axios";

export default defineComponent({
  name: 'EventRegistrations',
  components: {},
  data: () => ({
    now: null,
    loading_event: true,
    loading_registrations: true,
    event_id: null,
    event_data: null,
    modality_id: null,
    modality_selected: "all",
    modalities: [],
    registrations: null,
    registrations_stats: null,
    registrations_stats_no_filter: null,
    page: 1,
    page_size: 25,
    text_search: null,
    extra_sorting: null,
    club: null,
    category: null,
  }),
  created() {
    this.event_id = this.$route.params.event_id
    this.now = this.moment()
    this.get_event_data()
  },
  methods: {
    get_event_data() {
      this.loading_event = true
      let url = `${process.env.VUE_APP_ROOT_API}/events`
      axios.post(url, {
        "slug": this.event_id,
        "search_circuits": true,
      }).then((response) => {
        this.event_data = response.data[0]
        document.title = `${this.event_data.name} - ${this.$i18n.t('page_titles.registrations')}`
        this.loading_event = false
        this.modality_id = this.event_data.modalities[0]._id.$oid
        this.modalities.push({"name": "all", "value": "all"})
        this.event_data.modalities.forEach(m => {
          this.modalities.push({"name": m.short_name || m.name, "value": m._id.$oid})
        })
        this.get_registrations_stats_no_filter()
        this.get_registrations()
      }).catch((error) => {
        console.error(error)
      }).finally(() => {

      })
    },
    parse_filters() {
      let filters = {
        "offset": this.page_size * (this.page - 1),
        "limit": this.page_size,
      }
      if ( this.modality_selected == 'all' ) {
        filters["event"] = this.event_data._id.$oid
      } else {
        filters["modality"] = this.modality_selected
      }
      if ( this.text_search != null && this.text_search.length > 0 ) {
        filters["free_text_search"] = this.text_search
      }
      if ( this.category != null && this.category.length > 0 ) {
        filters["category"] = [this.category]
      }
      if ( this.club != null && this.club.length > 0 ) {
        filters["club"] = [this.club]
      }

      filters["order"] = []
      if ( this.extra_sorting != null ) {
        filters["order"].push(this.extra_sorting)
      }
      filters["order"].push("-created_at")
      return filters
    },
    get_registrations() {
      this.loading_registrations = true
      axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations`,
          this.parse_filters()
      ).then((response) => {
        this.registrations = response.data
        this.loading_registrations = false
        this.get_registrations_stats()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_registrations_stats_no_filter() {
      axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations/stats`,
          {"event": this.event_data._id.$oid},
      ).then((response) => {
        this.registrations_stats_no_filter = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    get_registrations_stats() {
      axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations/stats`,
          this.parse_filters()
      ).then((response) => {
        this.registrations_stats = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    filter_club(c) {
      this.club = c
      this.page = 1
      this.get_registrations()
    },
    filter_category(c) {
      this.category = c
      this.page = 1
      this.get_registrations()
    },
  },
  watch: {
    page: {
      handler() {
        this.get_registrations()
      }
    },
    extra_sorting: {
      handler() {
        this.get_registrations()
      }
    },
    modality_selected: {
      handler() {
        this.get_registrations()
      }
    },
  },
  computed: {
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    },
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    is_admin: {
      get: function () {
        return this.$root.is_admin
      }
    },
  }
});
</script>
