import { createI18n } from 'vue-i18n'
import ca from './locales/ca.json'
import en from './locales/en.json'
import es from './locales/es.json'
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from './locales/constants'

export default createI18n({
  locale: DEFAULT_LANGUAGE || 'en',
  fallbackLocale: FALLBACK_LANGUAGE || 'en',
  messages: { ca, en, es }
})
