<template>

  <v-container style="max-width: 960px;">

    <h1>{{ $t(`page_titles.event_list.${type}`) }}</h1>

    <v-tabs align-tabs="center" bg-color="primary" v-model="type" class="mb-4" @click="show_filters = false; reset_filters(); get_events()">
      <v-tab value="past">
        <span class="hidden-xs mr-1">últimos</span>resultados
      </v-tab>
      <v-tab value="next">
        Próximos<span class="hidden-xs ml-1">eventos</span>
      </v-tab>
    </v-tabs>

    <v-text-field v-model="filters.name" density="compact" clearable append-inner-icon="fa-solid fa-magnifying-glass" append-icon="fa-solid fa-filter"
                  @click:append-inner="get_events" @click:clear="get_events" @keydown.enter="get_events"
                  @click:append="show_filters = !show_filters" autofocus hide-details class="mb-4"
                  :label="`buscar ${this.type == 'past' ? 'eventos pasados' : 'próximos eventos'}`" variant="outlined"
    ></v-text-field>


    <v-expand-transition>
    <v-card density="compact" class="pa-0 mb-4" v-if="show_filters">
        <div>
          <v-card-text>
            <v-row v-if="filters">
              <v-col cols="6" sm="4">
                <h3>{{ $t('_event_list.filters_name.sport') }}</h3>
                <v-select clearable v-if="constants" color="primary" v-model="filters.sports"
                          density="compact" multiple hide-details
                          :items="constants.sporttype"></v-select>
              </v-col>
<!--              <v-col cols="6" sm="4">-->
<!--                <h3>{{ $t('_event_list.filters_name.date') }}</h3>-->
<!--                <v-select-->
<!--                    disabled-->
<!--                    density="compact" v-model="filters.date"-->
<!--                    :items="['all', 'one_month', 'three_months', 'six_months', 'one_year']"-->
<!--                    :item-title="s => $t(`_event_list.filters_name.date_values.${s}`)"-->
<!--                ></v-select>-->
<!--              </v-col>-->
              <v-col cols="6" sm="4">
                <h3>{{ $t('_event_list.filters_name.distance') }}</h3>
                <span v-if="JSON.stringify(filters.distance) == JSON.stringify(filters_default.distance)">{{
                    $t('_event_list.filters_name.slider.all')
                  }}</span>
                <span v-else-if="filters.distance[0] == filters_default.distance[0]">{{
                    $t('_event_list.filters_name.slider.to')
                  }} {{ filters.distance[1] }}km</span>
                <span v-else-if="filters.distance[1] == filters_default.distance[1]">{{
                    $t('_event_list.filters_name.slider.more_than')
                  }} {{ filters.distance[0] }}km</span>
                <span v-else>{{ $t('_event_list.filters_name.slider.from') }} {{
                    filters.distance[0]
                  }}km {{ $t('_event_list.filters_name.slider.to') }} {{ filters.distance[1] }}km</span>
                <v-range-slider
                    hide-details
                    v-model="filters.distance"
                    step="5"
                    strict
                    min="0"
                    max="100"
                    show-ticks="always"
                    color="primary"
                    thumb-size="10"
                    track-size="3"
                ></v-range-slider>
              </v-col>
              <v-col cols="6" sm="4">
                <h3>{{ $t('_event_list.filters_name.slope') }}</h3>
                <span v-if="JSON.stringify(filters.slope) == JSON.stringify(filters_default.slope)">{{
                    $t('_event_list.filters_name.slider.all')
                  }}</span>
                <span v-else-if="filters.slope[0] == filters_default.slope[0]">{{
                    $t('_event_list.filters_name.slider.to')
                  }} {{ filters.slope[1] }}m+</span>
                <span v-else-if="filters.slope[1] == filters_default.slope[1]">{{
                    $t('_event_list.filters_name.slider.more_than')
                  }} {{ filters.slope[0] }}m+</span>
                <span v-else>{{ $t('_event_list.filters_name.slider.from') }} {{
                    filters.slope[0]
                  }}m+ {{ $t('_event_list.filters_name.slider.to') }} {{ filters.slope[1] }}m+</span>
                <v-range-slider
                    hide-details
                    v-model="filters.slope"
                    step="250"
                    strict
                    min="0"
                    max="3000"
                    show-ticks="always"
                    color="primary"
                    thumb-size="10"
                    track-size="3"
                ></v-range-slider>
              </v-col>
              <v-col cols="6" sm="4">
                <h3>{{ $t('_event_list.filters_name.nearby') }}
                  <v-btn variant="tonal" size="x-small" density="compact"
                         icon="fa-solid fa-location" :disabled="!can_location"
                         :color="location?'success':''"
                         @click="get_location"
                  ></v-btn>
                  <v-tooltip activator="parent" location="bottom" v-if="!can_location">
                    {{ $t('_event_list.filters_name.nearby_alert') }}
                  </v-tooltip>
                </h3>
                <span v-if="filters.distance_from_me == filters_default.distance_from_me">{{
                    $t('_event_list.filters_name.slider.all')
                  }}</span>
                <span v-else>{{ $t('_event_list.filters_name.slider.to') }} {{ filters.distance_from_me }}km</span>
                <v-slider
                    hide-details
                    :disabled="!location"
                    v-model="filters.distance_from_me"
                    thumb-size="10"
                    color="primary"
                    track-size="3"
                    show-ticks="always"
                    min="10"
                    max="100"
                    step="10">
                </v-slider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn variant="tonal" color="error" :disabled="!filtered" @click="reset_filters">Borrar filtros</v-btn>
            <v-btn variant="flat" color="primary" :disabled="!filtered" @click="get_events">Buscar</v-btn>
          </v-card-actions>
        </div>
    </v-card>
    </v-expand-transition>


    <v-progress-linear indeterminate :height="12" v-if="loading_events"></v-progress-linear>

    <template v-if="!loading_events">
      <template v-if="events">
        <v-row>
          <v-col cols="12" md="4" sm="6" v-for="e in events">
            <EventCardV2 :e="e"></EventCardV2>
          </v-col>
        </v-row>
        <v-pagination
            v-if="events_stats"
            v-model="page"
            :length="1 + parseInt(events_stats.total/page_size)"
            total-visible="4">
        </v-pagination>
      </template>
      <v-alert v-else type="info">No events were found</v-alert>
    </template>


  </v-container>

</template>

<script>
import {defineComponent} from 'vue';
import axios from "axios";
import EventCard from '../components/EventCard.vue'
import EventCardV2 from '../components/EventCardV2.vue'


export default defineComponent({
  name: 'EventList',
  components: {
    EventCard,
    EventCardV2,
  },
  data: () => ({
    loading_events: true,
    type: 'calendar',
    show_filters: false,
    events: null,
    events_stats: null,
    page: 1,
    page_size: 12,
    filters_default: {
      name: "",
      date: "all",
      sports: [],
      distance: [0, 100],
      slope: [0, 3000],
      distance_from_me: 100,
    },
    filters: null,
    location: null,
    can_location: false,
    next_events: false,
    past_events: false,
  }),
  created() {
    this.type = this.$route.params.type
    this.reset_filters()
    // this.type = this.$route.meta.type
    document.title = 'Eventos'
    this.get_events()
    navigator.permissions && navigator.permissions.query({name: 'geolocation'})
        .then(PermissionStatus => {
          console.log(PermissionStatus)
          if (PermissionStatus.state == 'granted') {
            this.get_location()
          } else if (PermissionStatus.state == 'prompt') {
            this.can_location = true
          } else {
            //denied
          }
        })
  },
  methods: {
    reset_filters() {
      this.filters = JSON.parse(JSON.stringify(this.filters_default))
      this.get_events()
    },
    parse_filters() {
      let filters = {
        "offset": this.page_size * (this.page - 1),
        "limit": this.page_size,
      }
      if (this.filtered) {
        if (this.filters.name.length > 0) {
          filters["free_text_search"] = this.filters.name
        }
        if (this.filters.sports.length > 0) {
          filters["sport_type"] = this.filters.sports
        }
        if (JSON.stringify(this.filters.distance) != JSON.stringify(this.filters_default.distance)) {
          let from = this.filters.distance[0]
          let to = this.filters.distance[1]
          filters["distance_from"] = from * 1000
          if (to !== this.filters_default.distance[1]) {
            filters["distance_to"] = to * 1000
          }
        }
        if (JSON.stringify(this.filters.slope) != JSON.stringify(this.filters_default.slope)) {
          let from = this.filters.slope[0]
          let to = this.filters.slope[1]
          filters["slope_from"] = from * 1000
          if (to !== this.filters_default.slope[1]) {
            filters["slope_to"] = to * 1000
          }
        }
        if (this.filters.distance_from_me != this.filters_default.distance_from_me) {
          filters["nearby_pos_latitude"] = this.location.coords.latitude
          filters["nearby_pos_longitude"] = this.location.coords.longitude
          filters["nearby_max_distance"] = this.filters.distance_from_me * 1000
        }
      }
      filters["order"] = []
      if (this.type == 'next') {
        console.log("next")
        filters["status"] = ["registrations_not_opened", "registrations_opened", "cancelled",
          "registrations_full", "registrations_closed", "waiting_for_start", "live"]
        filters["date_start"] = this.moment().startOf('day')
        filters["order"].push("date_start")
      }
      if (this.type == 'past') {
        console.log("past")
        filters["status"] = ["finished_official", "finished_provisional"]
        filters["order"].push("-date_start")
      }
      filters["order"].push("numeric_id")
      return filters
    },
    get_events() {
      this.loading_events = true
      let url = `${process.env.VUE_APP_ROOT_API}/events`
      axios.post(url, this.parse_filters()).then((response) => {
        this.events = response.data
        this.loading_events = false
      }).catch((error) => {
        console.error(error)
      }).finally(() => {
        this.get_events_stats()
      })
    },
    get_events_stats() {
      this.loading_events = true
      let url = `${process.env.VUE_APP_ROOT_API}/events/stats`
      axios.post(url, this.parse_filters()).then((response) => {
        this.events_stats = response.data
        this.loading_events = false
      }).catch((error) => {
        console.error(error)
      })
    },
    get_location() {
      navigator.geolocation.getCurrentPosition(
          position => {
            this.location = position
            this.can_location = true
          },
          error => {
            console.log(error.message);
          },
      )
    }
  },
  computed: {
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    },
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    is_admin: {
      get: function () {
        return this.$root.is_admin
      }
    },
    constants: {
      get: function () {
        return this.$root.constants
      }
    },
    filtered: {
      get: function () {
        return JSON.stringify(this.filters) != JSON.stringify(this.filters_default)
      }
    },
  },
  watch: {
    page: {
      handler() {
        this.get_events()
      }
    },
  }
});
</script>
