<template>
  <div style="height: 100%;width: 100%">
    <div v-if="show_map" :style="`background-color: whitesmoke; width: 100%; height: ${show_profile ? '70' : '100'}%`" id="map"></div>

    <div v-if="show_profile" id="profile" :style="`background-color: whitesmoke; height: ${show_map ? '30' : '100'}%; ${adjust_to_width ? '' : 'overflow-x: scroll'};overflow-y: hidden;white-space: nowrap`" class="pa-2">

      <div :style="`height: 100%; width: 100%; ${adjust_to_width ? '' : 'min-width: 750px'}`" id="profile_container">
        <svg width='100%' height='100%' :viewBox="viewbox"
             v-if="profile.loaded"
             style='background-color: whitesmoke'
             @mouseenter="profile.hovering = true"
             @mousemove="draw_current_position"
             @mouseleave="profile.hovering = false">

          <defs>
            <linearGradient id="elevGradient" x1="0" y1="0" x2="0" y2="0.9">
              <stop offset="0.4" stop-color="white"></stop>
              <!--              <stop offset="1" stop-color="#b39c80"></stop>-->
              <stop offset="1" stop-color="#187fa2" ></stop>
            </linearGradient>
            <linearGradient id="elevGradientParticipant" x1="0" y1="0" x2="0" y2="0.9">
              <stop offset="0.4" stop-color="white"></stop>
              <!--              <stop offset="1" stop-color="#b39c80"></stop>-->
              <stop offset="1" stop-color="green" ></stop>
            </linearGradient>
            <linearGradient id="lineGradient" x1="0" y1="0" x2="0" y2="0.9">
              <stop offset="0.2" stop-color="whitesmoke" stop-opacity="0.5"></stop>
              <stop offset="1" stop-color="#187fa2"></stop>
            </linearGradient>
            <filter x="0" y="0" width="1" height="1" id="solid">
              <feFlood flood-color="white"/>
              <feComposite in="SourceGraphic" />
            </filter>

            <linearGradient id="participantPath" x1="0" y1="0" x2="1" y2="0" >
              <stop offset="0" stop-color="white">
                <animate attributeName="stop-color" values="white;#4CAF50;#4CAF50;#4CAF50;#4CAF50;#4CAF50;#4CAF50" dur="2s" repeatCount="indefinite"  />
              </stop>
              <stop offset="0.25" stop-color="#4CAF50">
                <animate attributeName="stop-color" values="#4CAF50;white;#4CAF50;#4CAF50;#4CAF50;#4CAF50;#4CAF50" dur="2s" repeatCount="indefinite"  />
              </stop>
              <stop offset="0.5" stop-color="#4CAF50">
                <animate attributeName="stop-color" values="#4CAF50;#4CAF50;white;#4CAF50;#4CAF50;#4CAF50;#4CAF50" dur="2s" repeatCount="indefinite"  />
              </stop>
              <stop offset="0.75" stop-color="#4CAF50">
                <animate attributeName="stop-color" values="#4CAF50;#4CAF50;#4CAF50;white;#4CAF50;#4CAF50;#4CAF50" dur="2s" repeatCount="indefinite"  />
              </stop>
              <stop offset="1" stop-color="#4CAF50">
                <animate attributeName="stop-color" values="#4CAF50;#4CAF50;#4CAF50;#4CAF50;white;#4CAF50;#4CAF50" dur="2s" repeatCount="indefinite"  />
              </stop>
            </linearGradient>
          </defs>

          <polygon :points="profile.points" style="fill:url(#elevGradient);stroke:#1a222d;stroke-width:1" opacity=".7">
          </polygon>

          <template v-if="participant">
<!--            <polyline :points="profile.points_participant" fill="none" :stroke="`${(participant.result.status == 'finished') ? '#4CAF50' : (participant.result.status == 'retired') ? '#B00020' : 'url(#participantPath)' }`" stroke-width="2" opacity="1">-->
<!--            </polyline>-->
            <polygon :points="profile.points_participant" style="fill:url(#elevGradientParticipant);stroke:#1a222d;stroke-width:1" opacity=".7">
            </polygon>
          </template>

          {{ void(d = this.profile.limits.max_distance/1000) }}
          {{ void(step = (d <= 50) ? 5 : 10) }}
          <g v-for="(d, idx) in arrayRange(0, d, step)">
            <text :x="convert_distance_to_point(d)" :y="0" :text-anchor="idx == 0 ? 'start' : 'middle'" alignment-baseline="hanging" opacity="0.2" >{{d}}km</text>
            <line :x1="convert_distance_to_point(d)" :y1="20" :x2="convert_distance_to_point(d)" :y2="profile.limits.max_height"
                  stroke="black" opacity="0.1" fill="none" stroke-width="1px" stroke-dasharray="20 8"></line>
          </g>

          <template v-for="(h, idx) in arrayRange(Math.ceil(profile.limits.min_height/250)*250, Math.floor(profile.limits.max_height/250)*250, 250)">
            <g v-if="h/profile.limits.max_height < 0.8">
              <text x="0" :y="convert_height_to_point(h)-1" alignment-baseline="baseline" opacity="0.2">{{h}}m</text>
              <text :x="profile.bounding_box.width" :y="convert_height_to_point(h)-1" text-anchor="end" alignment-baseline="baseline" style="opacity: 0.2">{{h}}m</text>
              <line x1="0" :y1="convert_height_to_point(h)" :x2="profile.bounding_box.width" :y2="convert_height_to_point(h)"
                    stroke="black" opacity="0.1" fill="none" stroke-width="1px" stroke-dasharray="20 8"></line>
            </g>
          </template>

          <g>
          <line :x1="0"
                :y1="convert_height_to_point(profile.inverted_height[0])"
                :x2="0"
                :y2="profile.bounding_box.height"
                stroke="black" opacity="0.5" fill="none" stroke-width="4px" stroke-dasharray="10 5"></line>

            <circle :cx="0"
                    :cy="convert_height_to_point(profile.inverted_height[0])"
                    r="4"
                    stroke="black"
                    :fill="profile.last_position_participant >= 0 ? '#4CAF50' : '#1a222d'"
                    stroke-width="0.3"></circle>

            <text class="svg-icon"
                  alignment-baseline="baseline" text-anchor="start"
                  :x="5"
                  :y="profile.bounding_box.height - 8">
              &#xf144;
            </text>

            <text v-if="modality.is_timed"
                  class="svg-icon"
                  alignment-baseline="baseline" text-anchor="start"
                  :x="5"
                  fill="whitesmoke"
                  :y="profile.bounding_box.height - 8 - 20">
              &#xf2f2;
            </text>
          </g>

          <template v-for="intermediate in modality.intermediates">

            <line :x1="convert_distance_to_point(intermediate.distance_from_start/1000)"
                  :y1="convert_height_to_point(profile.inverted_height[parseInt(convert_distance_to_point(intermediate.distance_from_start/1000))])"
                  :x2="convert_distance_to_point(intermediate.distance_from_start/1000)"
                  :y2="profile.bounding_box.height"
                  stroke="black" opacity="0.5" fill="none" stroke-width="2px" stroke-dasharray="10 5"></line>

            <circle :cx="convert_distance_to_point(intermediate.distance_from_start/1000)"
                    :cy="convert_height_to_point(profile.inverted_height[parseInt(convert_distance_to_point(intermediate.distance_from_start/1000))])"
                    r="4"
                    stroke="black"
                    :fill="profile.last_position_participant >= intermediate.distance_from_start ? '#4CAF50' : '#1a222d'"
                    stroke-width="0.3"></circle>

            <template v-if="modality.gpx_elevation_profile_show_names">
              <text class="text-capitalize font-weight-bold"
                    alignment-baseline="baseline"
                    :transform="`translate(${convert_distance_to_point(intermediate.distance_from_start/1000)-25},${profile.bounding_box.height-5}) rotate(270)`"
                    opacity="1">
                {{intermediate.location.custom_name}}
              </text>
            </template>

            <text class="font-weight-light"
                  alignment-baseline="baseline"
                  :transform="`translate(${convert_distance_to_point(intermediate.distance_from_start/1000)-8},${profile.bounding_box.height-5}) rotate(270)`"
                  opacity="1">
              {{this.$root.parse_distance(intermediate.distance_from_start, 1, 1)}}
            </text>

            <text v-if="intermediate.timed"
                  class="svg-icon"
                  alignment-baseline="baseline" text-anchor="start"
                  :x="convert_distance_to_point(intermediate.distance_from_start/1000) + 5"
                  :y="profile.bounding_box.height - 8" fill="whitesmoke">
              &#xf2f2;
            </text>

            <template v-for="(service, idx) in intermediate.extra_services">
              <text class="svg-icon"
                    alignment-baseline="baseline" text-anchor="start"
                    :x="convert_distance_to_point(intermediate.distance_from_start/1000) + 5"
                    :y="profile.bounding_box.height - 8 - 20 * (idx + 1)">

                {{ String.fromCodePoint(this.$root.get_icon_from_extra_service(service, 1)) }}
              </text>
            </template>

          </template>

          <g>
            <line :x1="profile.bounding_box.width"
                  :y1="convert_height_to_point(profile.inverted_height[Object.values(profile.inverted_height).length - 1])"
                  :x2="profile.bounding_box.width"
                  :y2="profile.bounding_box.height"
                  stroke="black" opacity="0.5" fill="none" stroke-width="4px" stroke-dasharray="10 5"></line>

            <circle :cx="profile.bounding_box.width"
                    :cy="convert_height_to_point(profile.inverted_height[Object.values(profile.inverted_height).length - 1])"
                    r="4"
                    :fill="participant && participant.result && participant.result.status == 'finished' ? '#4CAF50' : '#1a222d'"
                    stroke="black" stroke-width="0.3"></circle>

            <text class="svg-icon"
                  alignment-baseline="baseline" text-anchor="end"
                  :x="profile.bounding_box.width - 5"
                  :y="profile.bounding_box.height - 8">
              &#xf11e;
            </text>

            <text v-if="modality.is_timed"
                  class="svg-icon"
                  alignment-baseline="baseline" text-anchor="end"
                  fill="whitesmoke"
                  :x="profile.bounding_box.width - 5"
                  :y="profile.bounding_box.height - 8 - 20">
              &#xf2f2;
            </text>
          </g>

          <template v-for="(service, idx) in modality.extra_services">
            <text class="svg-icon"
                  alignment-baseline="baseline" text-anchor="end"
                  :x="profile.bounding_box.width"
                  :y="profile.bounding_box.height - 8 - 20 * (idx + 2)">
              {{ String.fromCodePoint(this.$root.get_icon_from_extra_service(service, 1)) }}
            </text>
          </template>

          <g v-if="profile.hovering">
            <line :x1="profile.hovering_pos" :y1="0" :x2="profile.hovering_pos" :y2="profile.bounding_box.height"
                  stroke="#1a222d" fill="none" stroke-width="1px" stroke-opacity="0.7" ></line>
            <line :x1="0" :y1="convert_height_to_point(profile.inverted_height[profile.hovering_pos])" :x2="profile.bounding_box.width" :y2="convert_height_to_point(profile.inverted_height[this.profile.hovering_pos])"
                  stroke="#1a222d" fill="none" stroke-width="1px" stroke-opacity="0.7"></line>

            <text :x="profile.hovering_pos" :y="0" alignment-baseline="hanging" text-anchor="middle" filter="url(#solid)">
              {{profile.inverted_distance[profile.hovering_pos].toFixed(1)}}km
            </text>
            <text :x="profile.hovering_pos" :y="profile.bounding_box.height-5" alignment-baseline="baseline" text-anchor="middle" filter="url(#solid)">
              {{profile.inverted_distance[profile.hovering_pos].toFixed(1)}}km
            </text>
            <text :x="0" :y="convert_height_to_point(profile.inverted_height[profile.hovering_pos])" alignment-baseline="middle" text-anchor="start" filter="url(#solid)">
              {{parseInt(profile.inverted_height[profile.hovering_pos])}}m
            </text>
            <text :x="profile.bounding_box.width" :y="convert_height_to_point(profile.inverted_height[profile.hovering_pos])" alignment-baseline="middle" text-anchor="end" filter="url(#solid)">
              {{parseInt(profile.inverted_height[profile.hovering_pos])}}m
            </text>

            <circle :cx="profile.hovering_pos" :cy="convert_height_to_point(profile.inverted_height[profile.hovering_pos])" r="7"
                    stroke="black" stroke-width="0.3" fill="#1a222d" fill-opacity="0.5" class="blink_me"></circle>
          </g>


        </svg>
        <template v-else>
          <v-icon start icon="fa-solid fa-spinner fa-spin"></v-icon>
          Cargando perfil
        </template>
      </div>
    </div>
  </div>
</template>

<style>
.svg-icon {
  font-family: 'FontAwesome';
}
</style>

<script>
import 'ol/ol.css'
import Map from 'ol/Map';
import View from 'ol/View';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import {Vector as VectorLayer} from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature'
import {LineString, Point} from 'ol/geom';
import {transform, transformExtent} from 'ol/proj';
import {boundingExtent} from 'ol/extent'
import {Style, Stroke, Fill, Circle as CircleStyle, Text as TextStyle, RegularShape as RegularShapeStyle} from 'ol/style'
import {distance} from "ol/coordinate";

var LG = require('leaflet-gpx');

export default {
  name: "MychipMap",
  props: {
    "modality": {required: true},
    "show_map": {default: true},
    "show_profile": {default: true},
    "adjust_to_width": {default: false},
    "participant": {default: null},
    "show_intermediate_names": {default: true},
    "draw_positions": {default: null},
    "participants": {default: null},
  },
  components: {
  },
  data: () => ({
    zoom: 4,
    profile: {
      loaded: false,
      bounding_box: {
        width: 0,
        height: 0,
      },
      margins: {
        top: 25,
        bottom: 25,
        right: 0,
        left: 0,
      },
      limits: {
        min_height: 0,
        max_height: 0,
        min_distance: 0,
        max_distance: 0,
        elevation_diff: 0,
      },
      points: "",
      points_participant: "",
      last_position_participant: "",
      inverted_distance: {},
      inverted_height: {},
      inverted_coordinates: {},
      hovering: false,
      hovering_pos: 0,
    },
    map: null,
    map_current_position: null,
    map_track: null,
    map_zoom: {
      max: 16,
      min: 10,
    },
    map_km_divisions_layer: null,
    map_km_divisions_style: null,
    status: "starting",
    status_mapping: {
      starting: 0,
      creating_map: 5,
      downloading_track: 10,
      reading_gps_data: 20,
      drawing_profile: 30,
      adding_info_lines: 50,
      adding_intermediates: 60,
      drawing_participant_progress: 80,
    }
  }),
  mounted() {

    // console.log(this.draw_positions)
    // console.log(LG._dist2d())
    // console.log(L.latLng(1, 1).distanceTo(L.latLng(0,0)))

    this.status = this.status_mapping.starting
    this.status = this.status_mapping.creating_map

    const raster = new TileLayer({
      source: new OSM(),
    });

    this.map = new Map({
      layers: [raster],
      target: 'map',
      view: new View({
        center: [0, 0],
        maxZoom: this.map_zoom.max,
        minZoom: this.map_zoom.min,
      }),
    })

    this.map_current_position = new Feature({
      geometry: new Point(transform([0, 0], 'EPSG:4326', 'EPSG:3857')),
    })

    const vector_layer = new VectorLayer({
      source: new VectorSource({
        features: [this.map_current_position],
      }),
      style: new Style({
        image: new CircleStyle({
          radius: 7,
          stroke: new Stroke({color: "#1a222d"}),
          fill: new Fill({color: "rgba(78, 194, 197, 0.7)"}),
        })
      })
    })
    vector_layer.setZIndex(10)

    this.map.addLayer(vector_layer)

    this.status = this.status_mapping.downloading_track

    var track = new L.GPX(this.modality.gpx_track, {
      async: true,
      marker_options: {
        startIconUrl: null,
        endIconUrl: null,
        shadowUrl: null
      }
    }).on('loaded', (e) => {
      console.log('GPX loaded')
      this.draw_track(e.target)
    }).on('error', (e) => {
      console.log('Error loading GPX')
    })

    this.map.on('moveend', (e) => {
      if ( this.map.getView().getZoom() >= 14 ) {
        this.map_km_divisions_layer.setStyle(this.map_km_divisions_style)
      } else {
        this.map_km_divisions_layer.setStyle(null)
      }
    });
  },
  methods: {
    distance,
    arrayRange(start, stop, step) {
      return Array.from(
          { length: (stop - start) / step + 1 },
          (value, index) => start + index * step
      );
    },
    draw_current_position() {
      let evt = event || window.event
      let box = document.getElementById('profile_container')
      // let x_pos = evt.clientX - box.offsetLeft + box.scrollLeft
      let x_pos = evt.offsetX
      x_pos = x_pos > 0 ? x_pos : 0
      x_pos = x_pos > this.profile.bounding_box.width ? this.profile.bounding_box.width : x_pos

      if ( x_pos in this.profile.inverted_coordinates ) {
        this.profile.hovering_pos = x_pos
        let lat = this.profile.inverted_coordinates[this.profile.hovering_pos].lat
        let lon = this.profile.inverted_coordinates[this.profile.hovering_pos].lng
        this.map_current_position.setGeometry(
            new Point(transform([lon, lat], 'EPSG:4326', 'EPSG:3857')),
        )
      }
    },
    convert_distance_to_point(distance) {
      if ( this.modality.gpx_use_own_distance ) {
        return distance * 1000 * this.profile.bounding_box.width / this.profile.limits.max_distance
      } else {
        return distance * 1000 * this.profile.bounding_box.width / this.modality.distance
      }
    },
    convert_height_to_point(height) {
      return this.profile.bounding_box.height - ((height - this.profile.limits.min_height) * this.profile.bounding_box.height / (this.profile.limits.elevation_diff))
    },
    draw_track( gpx ) {
      this.status = this.status_mapping.drawing_profile
      // Get limit box
      let box = document.getElementById('profile_container')
      let bounding_box = box.getBoundingClientRect()
      this.profile.bounding_box.width = bounding_box.width
      this.profile.bounding_box.height = bounding_box.height

      // Get GPX limits
      this.profile.limits.max_distance = gpx.get_distance()
      this.profile.limits.min_height = gpx.get_elevation_min()
      this.profile.limits.max_height = gpx.get_elevation_max()
      this.profile.limits.elevation_diff = this.profile.limits.max_height - this.profile.limits.min_height

      let coordinates = gpx.getLayers().pop()
      if ( !('_latlngs' in coordinates) ) {
        coordinates = coordinates.getLayers()[0]
      }
      coordinates = coordinates._latlngs
      let all_coordinates = []
      let km_points = {}
      let all_points = `0,${this.profile.bounding_box.height}`

      let all_points_participant = `0,${this.profile.bounding_box.height}`
      let last_position_participant = 0
      if ( this.participant && this.participant.result ) {
        if ( this.participant.result.total_time ) {
          last_position_participant = this.modality.gpx_use_own_distance ? this.profile.limits.max_distance : this.modality.distance
        } else {
          if ( this.participant.result.intermediate_results ) {
            this.participant.result.intermediate_results.forEach(ir => {
              if ( ir.total_time ) {
                last_position_participant = this.modality.intermediates[ir.order].distance_from_start
              }
            })
          }
        }
      }
      this.profile.last_position_participant = last_position_participant

      let all_coordinates_participant = []
      let gps_closest_distance = Infinity
      gpx.get_elevation_data().forEach((point, idx) => {
        let distance = point[0]
        if ( !this.modality.gpx_use_own_distance ) {
          distance = distance * this.modality.distance / this.profile.limits.max_distance
        }
        let height = point[1]

        let coordinate = coordinates[idx]
        all_coordinates.push([coordinate.lng, coordinate.lat])

        if ( !(distance.toFixed(1) in km_points) ) {
          km_points[distance.toFixed(1)] = [coordinate.lng, coordinate.lat]
        }

        let distance_to_draw = this.convert_distance_to_point(distance)
        let height_to_draw = this.convert_height_to_point(height)

        let int_distance_to_draw = parseInt(distance_to_draw)
        let last_distance_drawn = parseInt(Object.keys(this.profile.inverted_distance)[Object.keys(this.profile.inverted_distance).length - 1]) || 0

        this.arrayRange(last_distance_drawn, int_distance_to_draw - 1, 1).forEach((d) => {
          this.profile.inverted_distance[d] = distance
          this.profile.inverted_height[d] = height
          this.profile.inverted_coordinates[d] = coordinate
        })

        this.profile.inverted_distance[int_distance_to_draw] = distance
        this.profile.inverted_height[int_distance_to_draw] = height
        this.profile.inverted_coordinates[int_distance_to_draw] = coordinate

        all_points = `${all_points} ${distance_to_draw},${height_to_draw}`
        if ( distance < last_position_participant/1000 ) {
          all_points_participant = `${all_points_participant} ${distance_to_draw},${height_to_draw}`
          all_coordinates_participant.push([coordinate.lng, coordinate.lat])
        }
      })
      all_points = `${all_points} ${this.profile.bounding_box.width},${this.profile.bounding_box.height}`
      all_points_participant = `${all_points_participant} ${this.convert_distance_to_point(last_position_participant/1000)},${this.profile.bounding_box.height}`
      all_points_participant = `${all_points_participant} 0,${this.profile.bounding_box.height}`
      this.profile.points = all_points
      this.profile.points_participant = all_points_participant

      console.log(all_coordinates.length)
      console.log(all_coordinates_participant.length)

      this.status = this.status_mapping.adding_info_lines

      let km_points_features = []
      let km_points_divisions_feature = []
      for ( let km in km_points) {
        let feature = new Feature({
          geometry: new Point(transform([km_points[km][0], km_points[km][1]], 'EPSG:4326', 'EPSG:3857')),
        })
        if ( km % 1 === 0 ) {
          feature.setStyle(new Style({
            text: new TextStyle({
              font: '10px sans-serif',
              text: parseInt(km).toString(),
              fill: new Fill({
                color: 'black'
              }),
            }),
            image: new RegularShapeStyle({
              radius: 10,
              points: 4,
              angle: Math.PI / 4,
              stroke: new Stroke({color: "#1a222d"}),
              fill: new Fill({color: "whitesmoke"}),
            })
          }))
          km_points_features.push(feature)
        } else {
          km_points_divisions_feature.push(feature)
        }
      }
      const vector_layer = new VectorLayer({
        source: new VectorSource({
          features: km_points_features,
        })
      })

      this.map_km_divisions_style = new Style({
        image: new CircleStyle({
          radius: 2,
          fill: new Fill({color: "whitesmoke"}),
        })
      })
      this.map_km_divisions_layer = new VectorLayer({
        source: new VectorSource({
          features: km_points_divisions_feature,
        }),
      })

      vector_layer.setZIndex(6)
      this.map_km_divisions_layer.setZIndex(6)

      this.map.addLayer(vector_layer)
      this.map.addLayer(this.map_km_divisions_layer)

      let polyline = new LineString(all_coordinates);
      polyline.transform('EPSG:4326', 'EPSG:3857');
      let feature = new Feature({
        geometry: polyline,
      });
      feature.setStyle(new Style({
        stroke: new Stroke({color: "#1a222d", width: 5})
      }))

      let polyline_participant = new LineString(all_coordinates_participant);
      polyline_participant.transform('EPSG:4326', 'EPSG:3857');
      let feature_participant = new Feature({
        geometry: polyline_participant,
      });
      feature_participant.setStyle(new Style({
        stroke: new Stroke({color: "#187fa2", width: 5})
      }))

      this.map.addLayer(new VectorLayer({
        source: new VectorSource({
          features: [feature_participant, feature],
        }),
      }))

      this.status = this.status_mapping.adding_intermediates
      let intermediate_features = []
      this.modality.intermediates.forEach(intermediate => {
        let d = (intermediate.distance_from_start / 1000).toFixed(1)
        let c = km_points[d]
        let feature = new Feature({
          geometry: new Point(transform([c[0], c[1]], 'EPSG:4326', 'EPSG:3857')),
        })
        feature.setStyle(new Style({
          image: new CircleStyle({
            radius: 9,
            stroke: new Stroke({color: "#1a222d"}),
            fill: new Fill({color: "rgba(24, 127, 162, 0.7)"}),
          })
        }))
        intermediate_features.push(feature)
      })
      const vector_layer_intermediates = new VectorLayer({
        source: new VectorSource({
          features: intermediate_features,
        })
      })
      vector_layer_intermediates.setZIndex(8)
      this.map.addLayer(vector_layer_intermediates)

      if ( this.participant || this.participants ){
        this.status = this.status_mapping.drawing_participant_progress
      }
      if ( this.draw_positions !== null ) {
        let positions_features = []
        for ( let p in this.draw_positions ) {
          let position = this.draw_positions[p].position
          let feature = new Feature({
            geometry: new Point(transform([position[1], position[0]], 'EPSG:4326', 'EPSG:3857')),
          })
          let text = null
          if ( this.participants !== null ) {
            console.log("drawing participants")
          } else if ( this.participant !== null ) {
            text = new TextStyle({
              font: '10px sans-serif',
              text: this.participant.bib_number.toString(),
              fill: new Fill({
                color: 'black'
              }),
            })
          }
          feature.setStyle(new Style({
            text: text,
            image: new RegularShapeStyle({
              radius: 12,
              points: 3,
              angle: Math.PI / 3,
              stroke: new Stroke({color: "#1a222d"}),
              fill: new Fill({color: "rgb(255,0,0)"}),
            })
          }))
          positions_features.push(feature)
        }
        console.log(positions_features)
        const vector_layer_positions = new VectorLayer({
          source: new VectorSource({
            features: positions_features
          })
        })
        vector_layer_positions.setZIndex(6)
        this.map.addLayer(vector_layer_positions)
      }

      let gpx_bounds = gpx.getBounds()
      let extent = boundingExtent([[gpx_bounds._northEast.lng, gpx_bounds._northEast.lat], [gpx_bounds._southWest.lng, gpx_bounds._southWest.lat]]);
      extent = transformExtent(extent, 'EPSG:4326', 'EPSG:3857');
      this.map.getView().fit(extent, this.map.getSize());
      this.map.getView().setZoom(Math.floor(this.map.getView().getZoom()))

      this.profile.loaded = true
    }
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    viewbox() {
      return `0 0 ${this.profile.bounding_box.width} ${this.profile.bounding_box.height}`
    }
  }
};
</script>