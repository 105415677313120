<template>

  <v-table density="compact">
    <thead>
    <tr>
      <td></td>
      <td v-if="show_user">Participante</td>
      <td v-if="show_event_date">Fecha</td>
      <td v-if="show_race">Evento</td>
      <td>Modalidad</td>
      <td>Price</td>
      <td>Created</td>
      <td>source</td>
    </tr>
    </thead>
    <tbody>
    <tr v-for="r in registrations" :class="r.cancelled ? 'text-decoration-line-through' : ''">
      <td>
        <span v-if="r.cancelled">
          <v-icon size="x-small" icon="fa-solid fa-ban" color="error"></v-icon>
        </span>
        <span v-else>
          <v-icon size="x-small" icon="fa-solid fa-check" color="success"></v-icon>
        </span>
      </td>
      <td v-if="show_user">
        <router-link :to="{name: 'AdminRegistration', params: { registration_id: r._id.$oid }}" >
          {{ r.name }} <b>{{ r.surname }}</b>
        </router-link>
      </td>
      <td v-if="show_event_date">
        <span v-if="r.modality.event.date_start">
          {{ moment.utc(r.modality.event.date_start.$date).format('LL')}}
        </span>
      </td>
      <td v-if="show_race">
        <router-link :to="{name: 'AdminEvent', params: { event_id: r.modality.event.slug }}" >
          {{ r.modality.event.name_short || r.modality.event.name }}
        </router-link>
      </td>
      <td>
        <router-link :to="{name: 'AdminEvent', params: { event_id: r.modality.event.slug, modality_selected: r.modality._id.$oid }}" >
          {{ r.modality.short_name || r.modality.name }}
        </router-link>
      </td>
      <td>
          <span v-if="r.price_paid">
            {{r.price_paid}}€
          </span>
      </td>
      <td class="text-caption">
            <span>
              <span v-if="r.creation">{{ moment(r.creation.$date).from(moment.now()) }}</span>
              <span v-else-if="r.created_at">{{ moment.utc(r.created_at.$date).from(moment.now()) }}</span>
              <v-tooltip activator="parent" location="bottom">
                <b>Inscripción:</b> <span v-if="r.creation">{{ moment(r.creation.$date).format('LLL') }}</span>
                <br>
                <b>Importación:</b> <span v-if="r.created_at">{{ moment.utc(r.created_at.$date).format('LLL') }}</span>
              </v-tooltip>
            </span>
      </td>
      <td>
        <v-chip size="x-small" label>{{ r.source }}</v-chip>
      </td>
    </tr>
    </tbody>
  </v-table>
</template>
<script>
export default {
  name: "RegistrationsTable",
  props: ["registrations", "show_user", "show_event_date", "show_race"],
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    }
  }
};
</script>