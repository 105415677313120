<template>
  <template v-if="data[pos][gender]" class="">
    <span class="">
      {{ data[pos][gender]["name"] }} <b>{{ data[pos][gender]["surname"] }}</b>
    </span>
    <span class="text-caption font-weight-light text-capitalize text-truncate text-no-wrap" v-if="data[pos][gender]['club']"> | {{data[pos][gender]['club']}}</span>
  </template>
</template>
<script>
export default {
  name: "Winner",
  props: ["data", "gender", "pos"],
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
  }
};
</script>