<template>

  <v-breadcrumbs bg-color="primary" density="compact" v-if="event">

    <v-breadcrumbs-item :to="{ name: 'AdminEvent', params: { event_id: event.slug }}"
                        class="d-inline-block text-truncate">
      {{ event.name_short || event.name }}
    </v-breadcrumbs-item>

    <v-breadcrumbs-divider></v-breadcrumbs-divider>

    <v-breadcrumbs-item class="d-inline-block text-truncate">
      Inscripciones
    </v-breadcrumbs-item>

  </v-breadcrumbs>

  <v-container style="max-width: 960px;">

    <v-row>
      <v-col><h1>Inscripciones</h1></v-col>
    </v-row>

    <v-text-field v-model="filter_name" density="compact" clearable append-inner-icon="fa-solid fa-magnifying-glass"
                  @click:append-inner="get_registrations" @click:clear="get_registrations" @keydown.enter="get_registrations"
    ></v-text-field>

    <v-row class="mb-2">
      <v-col>
        <v-btn size="small" variant="outlined" @click="download_registrations(false)" :loading="generating_excel_registrations">
          <v-icon start size="small" icon="fa-solid fa-download"></v-icon>
          <span class="hidden-xs mt-1">Descargar</span>
          <v-tooltip activator="parent" location="bottom">
            <b>Descarga por defecto</b>
            <br>
            <span>Modalidades separadas en pestañas</span>
            <br>
            <span>Exporta todas las opciones</span>
          </v-tooltip>
        </v-btn>
        <v-btn size="small" variant="outlined" @click="show_donwload_options = !show_donwload_options"><v-icon icon="fa-solid fa-cog"></v-icon></v-btn>
      </v-col>
      <v-col class="text-right">
        <v-chip label variant="outlined" size="small">mostrando <span v-if="stats">{{ stats.total }}</span>/<span
            v-if="stats_no_filter">{{ stats_no_filter.total }}</span></v-chip>
      </v-col>
    </v-row>

    <v-expand-transition>
    <v-card class="mb-4" v-if="show_donwload_options">
      <v-card-title>Opciones de descarga</v-card-title>
      <v-card-text class="mb-0 pb-0">
        <v-row no-gutters>
          <v-col cols="12" sm="4"><v-checkbox v-model="separate_modalities" hide-details label="Separar modalidades en pestañas"></v-checkbox></v-col>
          <v-col cols="12" sm="4"><v-checkbox v-model="all_uppercase" hide-details label="Datos principales en mayúsculas"></v-checkbox></v-col>
          <v-col cols="12" sm="4"><v-checkbox v-model="token_modality" hide-details label="Incluir token modalidad (MDG)"></v-checkbox></v-col>
          <v-col cols="12" sm="4"><v-checkbox v-model="include_qr" hide-details label="Incluir QR"></v-checkbox></v-col>
          <v-col cols="12" sm="4"><v-checkbox v-model="personalized_qr" :disabled="!include_qr" hide-details label="QR personalizado"></v-checkbox></v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-table density="compact" v-if="event && export_available && export_options">
          <tbody>
          <tr>
            <td></td>
            <td v-for="m in event.modalities" width="1%">
              <span class="text-caption text-uppercase">{{m.short_name || m.name}}</span>
            </td>
          </tr>
          <tr>
            <td>Exportar modalidad</td>
            <td v-for="m in event.modalities" width="1%">
              <v-checkbox v-model="export_modalities[m._id.$oid]" density="compact" hide-details></v-checkbox>
            </td>
          </tr>
          <tr>
            <td>Exportar todas las preguntas extra</td>
            <td v-for="(m, idx) in event.modalities" width="1%">
              <v-checkbox :disabled="!export_modalities[m._id.$oid]" v-model="export_all_eq_modalities[m._id.$oid]"
                          :indeterminate="!Object.values(export_options).flatMap(v => m._id.$oid in v ? v[m._id.$oid] : undefined).filter(v => v !== undefined).every((val, i, arr) => val === arr[0])"
                          @update:modelValue="all_extra_questions.forEach(eq => {
                            if ( export_available[m._id.$oid][eq] ) {
                              export_options[eq][m._id.$oid] = export_all_eq_modalities[m._id.$oid]
                            }
                          })"
                          density="compact" hide-details></v-checkbox>
            </td>
          </tr>
          <tr v-for="(eo, idx) in export_options">
            <td>
              <v-checkbox density="compact" v-model="export_options[idx]['all']" :label="idx" hide-details
                          :indeterminate="!Object.values(export_options[idx]).slice(1).every((val, i, arr) => val === arr[0])"
                          @update:modelValue="event.modalities.forEach(m => {
                            if ( export_available[m._id.$oid][idx] ) {
                              export_options[idx][m._id.$oid] = export_options[idx]['all']
                            }
                          })"
              ></v-checkbox>
            </td>
            <td v-for="m in event.modalities" width="1%">
              <v-checkbox :disabled="!export_available[m._id.$oid][idx] || !export_modalities[m._id.$oid]"
                          @change="change_individual_option(idx)"
                          v-model="export_options[idx][m._id.$oid]" density="compact"
                          hide-details></v-checkbox>
            </td>
          </tr>
          </tbody>
        </v-table>
      </v-card-text>

      <v-card-subtitle>
        <v-row>
          <v-col>Combinaciones</v-col>
          <v-col class="text-right">
            <v-btn variant="elevated" color="primary" size="x-small" @click="export_combinations.push(JSON.parse(JSON.stringify(export_combination)))">Nueva combinación</v-btn>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <template v-for="(c, idx) in export_combinations">
          <v-divider class="ma-4"></v-divider>
          <v-row no-gutters v-if="c.name.length > 3 && export_combinations[idx]['combs'].length > 0">
            <v-col cols="12" class="mb-2">
              <span class="text-caption mr-2">Combinación {{idx+1}}:</span>
              <b class="mr-2 text-uppercase">{{c.name}}</b>
              <template v-for="(f, idx2) in c.combs">
                <span v-if="all_extra_questions.includes(f)" class="font-italic">[{{f}}]</span>
                <span v-else class="font-italic">{{f}}</span>
              </template>
            </v-col>
          </v-row>
          <v-row class="mb-3" no-gutters>
            <v-col cols="3">
              <v-text-field variant="outlined" autofocus label="Nombre exportación" density="compact" v-model="export_combinations[idx]['name']" :rules="[v => !!v || 'Campo requerido', v => !all_extra_questions.includes(v) || 'Ya hay una columna con ese nombre, se sobreescribirá']"></v-text-field>
            </v-col>
            <v-col cols="9">
              <v-row no-gutters>
                <template v-for="(f, idx2) in c['combs']">
                  <v-col cols="3">
                    <v-select hide-details v-if="all_extra_questions.includes(f)" v-model="export_combinations[idx]['combs'][idx2]" variant="outlined" density="compact" :items="all_extra_questions"></v-select>
                    <v-text-field hide-details v-else variant="outlined" label="separador" v-model="export_combinations[idx]['combs'][idx2]" density="compact"></v-text-field>
                  </v-col>
                </template>
                <v-col cols="3">
                  <v-select  v-model="combination_selected"  variant="filled" hide-details
                             @update:modelValue="v => {export_combinations[idx]['combs'].push(v) ; export_combinations[idx]['combs'].push('') ; combination_selected = null}"
                             density="compact" v-if="export_combinations[idx]['name'].length > 3"
                             label="Añadir" :items="all_extra_questions"></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn color="primary" :loading="generating_excel_registrations" variant="elevated" size="small" @click="download_registrations(true)">
          <v-icon start icon="fa-solid fa-download"></v-icon>
          Descargar con opciones
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-expand-transition>

    <v-progress-linear v-if="loading_registrations" class="mt-4" indeterminate :height="12">Usuarios</v-progress-linear>

    <template v-if="!loading_registrations">
      <template v-if="registrations">
        <RegistrationsTable :registrations="registrations" :show_race="false" :show_user="true"></RegistrationsTable>
        <v-pagination
            v-if="!loading_registrations && stats"
            density="compact"
            v-model="page"
            :length="1 + parseInt(stats.total/page_size)"
        ></v-pagination>
      </template>
      <v-alert v-else type="info">No se han encontrado inscripciones</v-alert>
    </template>

  </v-container>

</template>

<script>
import {defineComponent} from 'vue';
import RegistrationsTable from "@/components/RegistrationsTable.vue";

export default defineComponent({
  name: 'AdminRegistrationsList',
  components: {
    RegistrationsTable
  },
  data: () => ({
    now: null,
    event_id: null,
    modality_id: null,
    event: null,
    loading_registrations: true,
    registrations: null,
    stats_no_filter: null,
    stats: null,
    page: 1,
    page_size: 15,
    filter_name: "",
    generating_excel_registrations: false,
    show_donwload_options: false,
    separate_modalities: true,
    token_modality: true,
    all_uppercase: true,
    include_qr: true,
    personalized_qr: true,
    export_options: null,
    export_available: null,
    export_modalities: {},
    export_all_eq_modalities: {},
    export_combinations: [],
    export_combination: {
      "name": "",
      "combs": []
    },
    all_extra_questions: [],
    combination_selected: null,
  }),
  created() {
    this.event_id = this.$route.params.event_id
    this.modality_id = this.$route.params.modality_id
    this.now = this.moment()
    document.title = "Inscripciones"
    this.get_event()
    this.get_registrations()
    this.get_registrations_stats_no_filter()
  },
  methods: {
    parse_filters() {
      let filters = {
        "offset": this.page_size * (this.page - 1),
        "limit": this.page_size,
        "order": ["-created_at"],
        "include_cancelled": true,
        "simple_stats": true,
      }
      filters["event"] = this.event_id
      if (this.filter_name !== null && this.filter_name.length > 0) {
        filters["free_text_search"] = this.filter_name
      }
      return filters
    },
    get_event() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/events`,
          {id: this.event_id}
      ).then((response) => {
        let event = response.data[0]
        let export_modalities = {}
        let export_all_eq_modalities = {}
        let export_options = {}
        let export_availability = {}
        let all_extra_questions = []
        event.modalities.forEach(m => {
          export_modalities[m._id.$oid] = true
          export_all_eq_modalities[m._id.$oid] = true
          export_availability[m._id.$oid] = {}
          m.extra_questions.forEach(eq => {
            if ( !(eq in export_options) ) {
              export_options[eq] = {
                "all": true
              }
            }
            if ( all_extra_questions.indexOf(eq) === -1 ) {
              all_extra_questions.push(eq)
            }
            export_availability[m._id.$oid][eq] = true
            export_options[eq][m._id.$oid] = true
          })
        })
        this.export_modalities = export_modalities
        this.export_all_eq_modalities = export_all_eq_modalities
        this.export_options = export_options
        this.export_available = export_availability
        this.all_extra_questions = all_extra_questions
        this.event = event
      }).catch((error) => {
        console.error(error)
      })
    },
    get_registrations() {
      this.loading_registrations = true
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations`,
          this.parse_filters()
      ).then((response) => {
        this.registrations = response.data
        this.loading_registrations = false
        this.get_registrations_stats()
      }).catch((error) => {
        console.error(error)
      })
    },
    get_registrations_stats() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations/stats`,
          this.parse_filters()
      ).then((response) => {
        this.stats = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    get_registrations_stats_no_filter() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations/stats`,
          {
            "event": this.event_id,
            "include_cancelled": true,
            "simple_stats": true,
          }
      ).then((response) => {
        this.stats_no_filter = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    download_registrations(configured=false) {
      this.generating_excel_registrations = true
      let payload = {
        "event": this.event.slug,
      }
      if ( configured ) {
        payload["token_modality"] = this.token_modality
        payload["separate_modalities"] = this.separate_modalities
        payload["all_uppercase"] = this.all_uppercase
        payload["export_modalities"] = this.export_modalities
        payload["export_options"] = this.export_options
        payload["export_combinations"] = this.export_combinations
        payload["include_qr"] = this.include_qr
        payload["personalized_qr"] = this.personalized_qr
      }
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/registrations/download`,
          payload
      ).then((response) => {
        console.log(response.data)
        window.open(response.data, '_blank');
      }).catch((error) => {
        console.error(error)
      }).finally(() => {
        this.generating_excel_registrations = false
      })
    },
    change_individual_option(idx) {
      let elements = Object.values(this.export_options[idx]).slice(1)
      console.log(elements)
      if ( elements.every((val, i, arr) => val === arr[0]) ) {
        this.export_options[idx]['all'] = elements[0]
      }
    },
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    }
  },
  watch: {
    page: {
      handler() {
        this.get_registrations()
      }
    },
  }
});
</script>
