<template>
  <v-card :color="e.is_circuit ? 'primary' : ''" :class="in_circuit ? 'mb-2' : ''">

    {{void(diff = $root.now.diff(first_start)/1000)}}
    <v-toolbar density="compact" color="primary" class="text-center" v-if="diff < 0 && Math.abs(diff) < 2 * 86400">
      <v-toolbar-title v-if="first_start">
        <v-icon size="x-small" start icon="fa-solid fa-circle-notch fa-spin"></v-icon>
        Faltan {{ this.$root.parse_time_from_seconds(Math.abs(diff))  }} para la salida
      </v-toolbar-title>
    </v-toolbar>

    <!--    <v-toolbar class="text-center" v-if="e.status == 'live'">-->
    <!--      <v-toolbar-title>-->
    <!--        <v-icon start :icon="$root.get_icon_from_status(e.status)" :color="$root.get_icon_color_from_status(e.status)" size="x-small" ></v-icon>-->
    <!--        <span class="text-uppercase">{{$t(`constants.event_status.${e.status}`)}}</span>-->
    <!--      </v-toolbar-title>-->
    <!--    </v-toolbar>-->

    <v-card-subtitle class="bg-primary" v-if="!in_circuit && e.circuit">{{ e.circuit.name }}</v-card-subtitle>

    <v-img
        :src="e.poster"
        lazy-src="https://mychip-dev-images.s3.eu-central-1.amazonaws.com/posters/default.png"
        class="align-end"
        gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,1)"
        :height="e.is_circuit || in_circuit ? '100px' : '200px'"
        cover>

      <div v-if="e.featured" class="ribbon ribbon-top-right featured">
        <span>
          <v-icon icon="fa-solid fa-triangle-exclamation fa-beat" size="x-small"></v-icon>
          Destacado
        </span>
      </div>

      <div v-if="e.external" class="ribbon ribbon-top-right external">
        <span>Externo</span>
      </div>

      <v-card-subtitle class="mb-0 pb-0">
        <v-chip-group column>
          <v-chip class="bg-white" size="x-small" variant="elevated" label v-if="e.modalities">
            <span class="text-uppercase">{{ e.modalities.length }} modalidades</span>
            <v-tooltip activator="parent" location="top start">
              <div v-for="m in e.modalities">
                <v-icon :icon="$root.get_icon_from_sport(m.sport)" size="x-small"></v-icon>
                {{ $root.parse_distance(m.distance, true, true) }}: {{ m.name }}
              </div>
            </v-tooltip>
          </v-chip>
        </v-chip-group>

        <v-chip-group class="mb-0 pb-0" column>
          <v-chip :class="`mr-1 ${o.type == 'federation' ? 'bg-green' : 'bg-grey'}`" pill size="x-small" v-for="o in e.organisms">
            <v-avatar start v-if="o.logo">
              <v-img :src="o.logo" cover></v-img>
            </v-avatar>
            {{ o.name }}
          </v-chip>
        </v-chip-group>

        <v-chip-group class="mt-0 pt-0">
          <v-chip size="x-small" class="bg-grey text-black" variant="flat" label v-for="t in e.tags">{{ t }}</v-chip>
        </v-chip-group>
      </v-card-subtitle>
      <v-card-title class="text-white mt-0 pt-0">
                  <span class="text-uppercase">
                    {{ e.name_short || e.name }}
                    <v-tooltip :text="e.name" activator="parent" location="bottom start" origin="auto"></v-tooltip>
                  </span>
      </v-card-title>
    </v-img>

    <v-card-subtitle class=" text-center bg-tertiary">{{ $t(`constants.event_status.${e.status}`) }}</v-card-subtitle>

    <v-card-text class="mb-0 pb-0">
      <v-row>
        <v-col class="">
          <h4 class="d-inline-block text-truncate" v-if="e.date_start">
            <v-icon size="x-small" icon="fa-solid fa-calendar" class="mr-1"></v-icon>
            {{ moment(e.date_start.$date).format('DD/MM/YY') }}
          </h4>
          <h4 class="d-inline-block text-truncate ml-1" v-if="e.is_circuit && e.date_end">
            - {{ moment(e.date_end.$date).format('DD/MM/YY') }}
          </h4>
        </v-col>
        <v-col class="text-end " v-if="!e.is_circuit && e.location">
          <h4 class="d-inline-block text-truncate">{{ e.location.city }}
            <v-icon class="ml-1" size="x-small" icon="fa-solid fa-location-pin"></v-icon>
          </h4>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-if="e.is_circuit" block variant="outlined" :to="{ name: 'Circuit', params: { circuit_id: e.slug }}">
        <v-icon size="small" class="fa-solid fa-circle-info"></v-icon>
        <span class="ml-1">Ver circuito</span>
      </v-btn>
      <v-btn v-else block variant="outlined" :to="{ name: 'Event', params: { event_id: e.slug }}">
        <template v-if="e.status == 'live'">
          <v-icon size="small" color="error" class="fa-solid fa-circle fa-beat"></v-icon>
          <span class="ml-1">Seguimiento</span>
        </template>
        <template v-else>
          <v-icon size="small" class="fa-solid fa-circle-info"></v-icon>
          <span class="ml-1">Más info</span>
        </template>
      </v-btn>

    </v-card-actions>

  </v-card>
</template>

<style scoped>
>>>.v-img__img--cover {
  filter: blur(3px)!important;
}
</style>

<style>
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  /*border: 5px solid #F9A825;*/
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 7px 0;
  box-shadow: 0 2px 1px rgba(0, 0, 0, .1);
  /*color: #fff;*/
  font: 500 16px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  text-transform: uppercase;
  text-align: center;
}

.featured span {
  background-color: #4ec2c5;
  color: #1a222d;
}


.external span {
  background-color: rgba(28, 19, 19, 0.45);
  color: #939393;
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 22px;
}

.ribbon-top-right::after {
  bottom: 22px;
  right: 0;
}

.ribbon-top-right span {
  left: -27px;
  top: 24px;
  transform: rotate(30deg);
}
</style>

<script>
export default {
  name: "EventCard",
  props: {
    e: null,
    in_circuit: false,
  },
  data: () => ({
    first_start: null,
  }),
  created() {
    let first_start = this.moment(0)
    if ( this.e.modalities ) {
      first_start = this.moment.utc(this.e.modalities[0].datetime_start.$date)
      this.e.modalities.forEach(m => {
        if ( m.datetime_start ) {
          let start = this.moment.utc(m.datetime_start.$date).utc(true)
          if ( start < first_start ) {
            first_start = start
          }
        }
      })
      this.first_start = first_start
    }
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    }
  }
};
</script>