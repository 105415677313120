export default {
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["benvingut"])},
  "event": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["cap esdeveniment"]), _normalize(["esdeveniment"]), _normalize(["esdeveniments"])])},
  "slogan": {
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sumant"])},
    "together": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["junts"])}
  },
  "constants": {
    "event_status": {
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel.lat"])},
      "registrations_not_opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inscripcions no obertes"])},
      "registrations_opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inscripcions obertes"])},
      "registrations_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inscripcions esgotades"])},
      "registrations_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inscripcions tancades"])},
      "waiting_for_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esperant l'inici"])},
      "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en directe"])},
      "finished_provisional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finalitzat"])},
      "finished_official": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finalitzat"])}
    },
    "sport": {
      "walking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caminar"])},
      "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["còrrer"])},
      "trail_running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["còrrer per muntanya (trail)"])},
      "hiking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["senderisme"])},
      "time_trial_climb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cronoescalada"])},
      "cycling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ciclisme"])},
      "btt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bicicleta per muntanya (BTT)"])},
      "stages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per etapes"])},
      "biathlon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["biatló"])},
      "triathlon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["triatló"])},
      "swimming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["natació"])},
      "open_waters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aigües obertes"])},
      "relays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relleus"])},
      "climbing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["escalada"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["altres"])}
    },
    "chronotype": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tots"])}
    },
    "gender": {
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["masculí"])},
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["femení"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["altre"])}
    },
    "extra_services": {
      "description": {
        "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "coffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "classification_type": {
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["individual"])},
      "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["equips"])}
    }
  },
  "_event": {
    "circuit_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquest esdeveniment forma part del circuit"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inscriu-te"])},
    "registration_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["llistat d'inscrits"])},
    "edit_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editar inscripció"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data"])},
    "federation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["federació"])},
    "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organització"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacte"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["documents"])},
    "modality": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["a"]), _normalize(["modalitat"]), _normalize(["modalitats"])])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editar"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["informació"])},
    "error_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aquesta opció ja no està disponible"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lloc"])},
    "datetime_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a les"])}
  },
  "result_status": {
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finalitzat"])},
    "out_of_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fora de temp"])},
    "retired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["retirat"])},
    "disqualified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desqualificat"])},
    "not_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no comença"])},
    "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["començat"])}
  },
  "_modality": {
    
  },
  "language_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valencià"])},
  "page_titles": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendari d'esdeveniments"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultats"])},
    "registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llistat d'inscrits"])},
    "event_list": {
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendari d'esdeveniments"])},
      "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultats"])}
    }
  },
  "_event_list": {
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar"])},
    "filter_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtres aplicats"])},
    "show_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mostrar filtres"])},
    "hide_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ocultar filtres"])},
    "filters_name": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per nom"])},
      "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per esport"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per data"])},
      "date_values": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["totes"])},
        "one_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d'aquí a 1 mes"])},
        "three_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d'ara a 3 mesos"])},
        "six_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d'ara a mig any"])},
        "one_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d'ara a un any"])}
      },
      "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per distància"])},
      "slider": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["qualsevol"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fins a"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["des de"])},
        "more_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["més de"])}
      },
      "slope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desnivell positiu"])},
      "nearby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A prop de mi"])},
      "nearby_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa els servicis de localització per poder utilitzar aquest filtre"])}
    }
  },
  "units": {
    "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mètric"])},
    "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imperial"])},
    "distance": {
      "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quilòmetres"])},
      "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["milles"])}
    }
  }
}